.header {
    z-index: 998;
    position: relative;
    background-color: #fff;
    box-shadow: 0px 7px 14px rgba(159,173,181, .03), 0px 20px 36px rgba(159,173,181, .1);
    @media (max-width: 1023px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        transition: transform .5s;
        pointer-events: all;
        will-change: transform;
        &.scroll-hide {
            pointer-events: none;
            transform: translateY(-100%); }
        &.scroll-sticky {
            .headliner {
                display: none; } }
        &.scroll-clue {
            pointer-events: none !important;
            transform: none !important; } }

    @media (min-width: 1024px) {
        &.opendims {
            z-index: 990; }
        &.scroll-sticky {
            z-index: 999;
            .header__main {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                padding: 9px 0 10px;
                background: #FFFFFF;
                box-shadow: 0px 7px 14px rgba(159,173,181, .03), 0px 20px 36px rgba(159,173,181, .1);
                animation: scrollsticky 0.5s;

                .header__menu-btn {
                    display: flex; } }

            .header__logo {
                margin-right: 16px;
                a {
                    width: 125px; }
                p {
                    font-size: 9.2px;
                    line-height: 9px; } }
            .h-search {
                min-width: 0; }
            .h-cart-item {
                &>span:not(.icon) {
                    display: none; } }
            .h-location {
                margin-right: 10px; }
            .header__main {
                z-index: 100; }
            &.js-active-menu .header__main {
                max-width: calc(100% - 15px);
                box-shadow: none; } } }
    @media (min-width: 1440px) {
        .header__logo {
            margin-right: 24px; }
        .h-location {
            margin-right: 24px; }
        .h-search {
            min-width: 200px; } }
    .headliner {
        display: block;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none; }
        p {
            max-width: fit-content;
            line-height: 17px;
            padding: 0 10px;
            margin: 2px auto; } }
    .header__main {
        @include m(992px) {
            padding: 15px 0 14px; }

        .header__menu-btn {
            display: none;
            height: 44px;
            margin-right: 6px;
            margin-bottom: 0; } }

    .header__main-block {
        @include r(991px) {
            flex-wrap: wrap;
            margin-right: 0; } }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    &__top {
        border-bottom: 1px solid $lgray;
        @media (max-width: 1023px) {
            display: none; } }
    .h-location {
        position: relative;
        margin-right: 24px;
        @include r(991px) {
            order: 5;
            width: 100%;
            margin-top: 16px;
            margin-right: 0; }
        &__select {
            display: flex;
            align-items: center;
            cursor: pointer;
            min-width: 172px;
            .svg-image-location {
                @include m(992px) {
                    width: 20px; } }
            .current {
                margin: -1px 6px 0 8px;
                font-size: 14px;
                line-height: 18px;
                @include m(992px) {
                    font-size: 15px;
                    line-height: 19px; } } }
        &__dropdown {
            width: 308px;
            text-align: center;
            top: -12px;
            padding: 18px;
            cursor: default;
            @media (max-width: 767px) {
                display: none; }
            &::before {
                display: none; }
            p {
                font-size: 13px; }
            .current {
                font-family: $crpb;
                font-size: 15px;
                line-height: 1.5;
                letter-spacing: -0.003em;
                margin-bottom: 12px; }
            .btns {
                display: flex;
                margin: 12px 0;
                .btn {
                    width: calc(50% - 4px);
                    margin-right: 8px;
                    &:last-child {
                        margin: 0; } } }
            .descr {
                font-size: 12px;
                line-height: 1.5;
                color: $text2; }
            p {
                font-size: 13px; }
            .current {
                margin-bottom: 12px; } }
        .svg-image-location {
            @include m(992px) {
                width: 20px; } }
        .current {
            font-size: 14px;
            line-height: 18px;

            @include m(992px) {
                font-size: 15px;
                line-height: 19px; } } }
    &__top-nav {
        display: flex;
        @media (max-width: 1199px) {
            flex-grow: 1;
            justify-content: space-between;
            max-width: 476px;
            padding: 0 20px; }
        .link {
            display: block;
            font-size: 13px;
            line-height: 16px;
            color: $text2;
            padding: 12px 0;
            margin-right: 38px;
            @media (max-width: 1199px) {
                margin-right: 0; }
            &:last-child {
                margin: 0; }
            &:hover {
                color: $orange; }
            &-submenu {
                position: relative;
                cursor: default;
                .icon {
                    margin-left: 5px; }
                &__dropdown {
                    padding: 8px;
                    position: absolute;
                    top: 100%;
                    left: -20px;
                    transition: opacity .2s ease;
                    .link {
                        display: block;
                        min-width: 172px;
                        border-radius: 4px;
                        font-size: 13px;
                        line-height: 16px;
                        padding: 10px 12px;
                        margin: 0;
                        &:hover {
                            background-color: $lgray;
                            color: $blue; } } }
                @media (min-width: 1024px) {
                    &:hover {
                        .link-submenu__dropdown {
                            visibility: visible;
                            max-height: none;
                            opacity: 1; } } } } } }
    .h-phones {
        position: relative;
        display: flex;
        align-items: center;

        &__item {
            display: block;
            font-size: 16px;
            font-weight: 600;
            padding-top: 1px;
            margin-left: 35px;
            &.mr-10 {
                margin-right: 10px; }
            &--two {
                @media (max-width: 1024px) {
                    display: none; } }
            span {
                font-weight: 400; }

            &:hover {
                color: $orange; }
            span {
                display: block;
                font-size: 12px;
                color: $ibg; }
            &:first-child {
                margin-left: 16px; }
            @media (min-width: 1024px) and (max-width: 1200px) {
                display: none;
                &:first-child {
                    display: block;
                    margin-right: -4px; } } }
        &__info {
            flex-shrink: 0;
            cursor: pointer;
            .icon {
                display: block; } }

        .dd-phones {
            width: 360px;
            top: calc(100% + 16px);
            left: auto;
            right: 0;
            &:after {
                content: '';
                position: absolute;
                top: -30px;
                left: 0;
                width: 100%;
                height: 30px;
                background: transparent; }
            &.is-visible {
                padding: 30px 32px 22px; }
            &__items {
                margin-bottom: 20px; }
            &__item {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0; }
                a {
                    font-family: $crpm;
                    font-size: 20px;
                    &:hover {
                        color: $orange; } }
                span {
                    display: block;
                    color: $text2; } }
            .btn {
                width: 152px; }
            &__descr {
                color: $text2;
                padding: 16px 0; }
            &__messagers {
                display: flex;
                border-top: 1px solid $lgray;
                padding-top: 16px;
                a {
                    display: flex;
                    align-items: center;
                    font-family: $crpm;
                    font-size: 13px;
                    margin-right: 24px;
                    &:last-child {
                        margin: 0; }
                    .icon {
                        margin-right: 8px; } } }
            &__code {
                max-width: fit-content;
                width: 100%;
                display: flex;
                align-items: center;
                color: $text;
                margin: 0 0 24px;
                cursor: pointer;
                span {
                    display: inline-block;
                    font-family: $crpm;
                    margin-left: 4px; }
                .icon {
                    height: 20px;
                    margin-left: 4px; } } } }

    .h-callback {
        margin-left: auto;
        .btn {
            height: 26px;
            border: none;
            font-family: $crp;
            color: $orange;
            white-space: pre-wrap;
            text-align: center;
            padding: 0 11px;
            &:hover {
                background-color: transparent; } }
        @media (max-width: 1023px) {
            display: none; } }

    .h-question {
        display: none;
        align-items: center;
        .btn {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px 4px;
            background-color: transparent;
            border: none;
            font-size: 15px;
            color: #313339;
            padding: 0 11px 0 8px;

            span {
                font-size: 13px;
                font-weight: 400;
                color: $orange; }

            &:hover {
                background-color: transparent; } } }

    .h-ads {
        display: none;
        position: relative;
        min-width: 129px;

        @include r(767px) {
            margin-top: 15px;
            order: 6;
            width: 100%; }

        &__bg {
            position: absolute;
            inset: 0;
            background: #FD8D03;

            &::before {
                content: '';
                position: absolute;
                inset: 0;
                left: -16px;
                background: #FD8D03; }

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                right: -16px;
                background: #FD8D03; }

            @include m(768px) {
                overflow: hidden;
                border-radius: 6px;
                &::before {
                    background: url(../images/ads/ads-h.svg) no-repeat 100% 0; }
                &::after {
                    background: radial-gradient(165.65% 113.98% at 119.83% 126.70%, #FFB800 0%, rgba(252, 109, 5, 0.00) 100%), radial-gradient(163.32% 87.87% at -14.83% -19.37%, #FFB800 0%, rgba(252, 109, 5, 0.00) 100%), radial-gradient(79.69% 44.60% at 23.10% 23.56%, #FC6D05 0%, rgba(252, 109, 5, 0.00) 100%); } } }

        &__valid {
            cursor: pointer;
            position: relative;
            height: 44px;
            display: flex;
            align-items: center; }

        &__offer {
            flex-grow: 1;
            font-family: $crpb;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
            @include m(768px) {
                display: none; } }

        &__timer {
            position: relative;
            display: flex;
            align-items: center;
            font-family: $crpm;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            @include m(768px) {
                margin-left: 8px;
                font-size: 20px;
                line-height: 24px; }
            .icon {
                margin-right: 3px; }
            em {
                font-style: normal;
                min-width: 46px;
                @include m(768px) {
                    min-width: 52px; } } }

        &__icon {
            margin-left: 8px;
            @include m(768px) {
                display: none; }
            span {
                display: block; } } }

    &__logo {
        flex-shrink: 0;
        margin-top: -4px;
        margin-right: 17px;
        @include m(376px) {
            margin-right: 24px; }
        a {
            display: block;
            width: 100px;
            transition: none;
            @include m(375px) {
                width: 118px; }
            @include m(481px) {
                width: 126px; }
            @include m(1024px) {
                width: 152px; } }
        svg {
            width: 100%; }
        p {
            font-family: $crpm;
            font-size: 11px;
            line-height: 1;
            color: $blue;
            margin-top: 4px;
            white-space: nowrap;
            @media (max-width: 1023px) {
                font-size: 9.2px;
                line-height: 9px; }
            @media (max-width: 374px) {
                font-size: 7.3px;
                line-height: 7px; }
            @media (max-width: 480px) {
                display: none; } } }
    &__main {
        position: relative;
        padding: 14px 0 13px;
        @include m(992px) {
            padding: 15px 0 14px; }
        @media (max-width: 1023px) {
            padding: 14px 0 13px; }
        &-block {
            flex-grow: 1;
            display: flex;
            align-items: center;
            margin-right: 0;
            @include r(991px) {
                flex-wrap: wrap; }
            .hamburger {
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 15px -2px -5px;
                font-size: 0;
                line-height: 0;
                .icon {
                    display: block;
                    margin: 0 -1px; }
                @media (min-width: 1024px) {
                    display: none; } }
            .h-search {
                position: relative;
                flex-grow: 1;
                width: 100%;
                min-width: 200px;
                margin-right: 16px;
                @include r(1023px) {
                    display: none; }
                @media (max-width: 1023px) {
                    margin-right: 34px; }
                @media (max-width: 1023px) {
                    display: none; }
                @media (max-width: 480px) {
                    width: auto;
                    margin: 0;
                    position: absolute;
                    left: 16px;
                    right: 16px;
                    bottom: 10px; }
                .input {
                    flex-grow: 1;
                    border-radius: 44px;
                    input {
                        padding-left: 22px;
                        padding-right: 44px; } }
                &__icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 44px;
                    height: 44px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 3;
                    cursor: pointer;
                    .icon {
                        display: block; } } }

            .h-phones {
                @media (max-width: 1023px) {
                    flex-grow: 1;
                    justify-content: center; }
                @media (max-width: 767px) {
                    display: none; }
                &__item {
                    white-space: nowrap;
                    @media (max-width: 1023px) {
                        margin-right: 32px; }
                    &:last-child {
                        margin-right: 0; }
                    a {
                        font-size: 18px; } } } } }

    .btn-catalog {
        @include m(1024px) {
            height: 36px;
            margin-bottom: 11px;
            font-family: $crpm;
            font-size: 16px;
            line-height: 1;
            text-transform: initial;
            border-color: $lgray;
            background: $lgray;
            color: $text;
            transition: all 0.2s ease, color 0.1s ease;
            &.is-active {
                border-color: $dorange;
                background-color: #fff;
                svg {
                    fill: $dorange; }
                &:hover {
                    span:not(.icon) {
                        color: #FFFFFF; }
                    svg {
                        fill: #FFFFFF; } } }
            &:hover, &.is-active:hover {
                border-color: $dorange;
                background: $dorange;
                color: #FFFFFF;
                svg {
                    fill: currentColor; } }

            @media (max-width: 1399px) {
                font-family: $crp; }
            @media (max-width: 1199px) {
                font-size: 15px; } } }

    &__actions {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-left: auto;
        margin-right: -6px;
        @include m(1024px) {
            margin-left: 9px;
            margin-right: 0px; }

        &-item {
            position: relative;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 8px;
            cursor: pointer;
            @media (max-width: 1399px) {
                margin-right: 26px; }
            @media (max-width: 1023px) {
                margin-right: 0;
                margin-left: 6px; }
            @media (max-width: 480px) {
                margin: 0; } }

        .js-basket-mini-view {
            @media (min-width: 767px) {
                position: relative;
                &:hover {
                    .dropdown {
                        padding: 24px 32px;
                        overflow: visible;
                        visibility: visible;
                        max-height: none;
                        opacity: 1; } } } }

        &--cart {
            @include m(1200px) {
                margin-left: 10px; }
            .bar-item {
                padding: 7px 13px 1px;
                background: #EEF2F6;
                border-radius: 8px;

                .counter {
                    @media (max-width: 1399px) {
                        left: calc(50% - 4px); }
                    @media (max-width: 1023px) {
                        left: calc(50% + 5px); }
                    @media (max-width: 480px) {
                        left: calc(50% - 3px); }

                    &[data-cnt="0"] + .h-cart-item {
                        padding-right: 16px;
                        @media (max-width: 1023px) {
                            padding-right: 10px; }
                        @media (max-width: 480px) {
                            margin-left: 2px;
                            padding: 9px 7px 6px 7px; }
                        & > span:not(.icon) {
                            font-family: $crp;
                            color: $text2; } } } } }

        .header__action--compare {
            @media (min-width: 1024px) {
                display: none; } }

        .cart-dropdown {
            width: 372px;
            cursor: default;
            @media (max-width: 1023px) {
                right: -5px; }
            @media (max-width: 399px) {
                width: 320px;
                right: -16px;
                padding: 24px 16px; }
            &::after {
                @media (min-width: 1024px) {
                    z-index: -1;
                    content: '';
                    display: block;
                    position: absolute;
                    top: -16px;
                    right: 0;
                    width: 100%;
                    height: 16px; } }
            &::before {
                right: 17px;
                @media (max-width: 1399px) {
                    right: 11px; }
                @media (max-width: 767px) {
                    right: 22px; }
                @media (max-width: 399px) {
                    right: 27px; } }
            &__title {
                font-family: $crpm;
                font-size: 17px;
                line-height: 24px;
                padding-right: 30px;
                margin-bottom: 12px;
                span {
                    font-family: $crp;
                    color: $ibg; } }
            &__item {
                position: relative;
                display: flex;
                margin-bottom: 18px;
                &:last-child {
                    margin-bottom: 0; }
                &-img {
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 88px;
                    height: 64px;
                    margin-right: 20px;
                    img {
                        flex-shrink: 0; } }
                &-text {
                    margin-right: 28px;
                    a {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        max-height: 60px;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: left;
                        color: $text2;
                        margin-bottom: 10px;
                        &:hover {
                            color: $blue; } }
                    .price {
                        font-family: $crpm;
                        font-size: 16px;
                        line-height: 1; }

                    .price strong {
                        color: $text;
                        font-family: $crpm;
                        font-size: 16px;
                        line-height: 1;
                        font-weight: normal; }

                    .present {
                        position: relative;
                        display: flex;
                        align-items: flex-start;
                        border-radius: 6px;
                        background-color: $bbg;
                        padding: 7px 8px 8px;
                        margin: 8px 0 0;
                        &__icon {
                            flex-shrink: 0;
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $green;
                            border-radius: 100px;
                            margin-right: 8px; }
                        &__text,
                        &__text a {
                            font-size: 12px;
                            line-height: 100%;
                            color: $text;
                            text-align: left; }
                        &__text {
                            font-family: $crpm; }
                        &__text a {
                            font-family: $crp;
                            display: inline;
                            text-decoration: underline; } } }
                &-del {
                    padding: 3px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 3;
                    cursor: pointer;
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity .2s ease; }
                &:hover {
                    .cart-dropdown__item-del {
                        opacity: 1;
                        pointer-events: auto; } } }
            &__footer {
                display: flex;
                border-top: 1px solid $lgray;
                line-height: 24px;
                padding-top: 22px;
                margin-top: 28px;
                .total {
                    flex-shrink: 0;
                    font-size: 16px;
                    color: $text2;
                    margin-right: 25px;
                    span {
                        display: block;
                        font-family: $crpb;
                        font-size: 20px;
                        color: $text; } }
                .btn {
                    flex-grow: 1;
                    border-radius: 10px;
                    padding: 0;
                    font-size: 16px;
                    color: #FFFFFF;
                    @media (max-width: 1399px) {
                        display: flex; } } } } }

    &__action {
        &[data-cnt="0"] {
            display: none;
            @include m(1024px) {
                display: block;
                .bar-item__counter {
                    display: none; } } }
        svg {
            fill: $blue; }

        &.js-active {
            color: $orange;
            svg {
                fill: $orange; } }

        .bar-item {
            color: $text2;
            padding: 6px 10px;
            @include m(1024px) {
                padding: 7px 12px 6px; }
            @include m(1200px) {
                padding: 7px 12px 1px; }

            &__ttl {
                display: none;
                font-family: $crpm;
                font-size: 13px;
                line-height: 16px;
                @include m(1200px) {
                    display: block; } }

            &__counter {
                margin-left: 0;
                top: 0px; } }
        &--cart {
            @include m(1200px) {
                margin-left: 10px; }
            .bar-item {
                // padding: 7px 13px 6px
                // background: #EEF2F6
                // border-radius: 8px

                @include m(1200px) {
                    padding: 7px 12px 1px; } } }

        &--contacts, &--search {
            @include m(1024px) {
                display: none; } }
        &--fav, &--cart {
            display: none;
            @include m(1024px) {
                display: flex; } }
        &--compare {
            margin-left: 9px; }
        &--fav, &--compare {
            .bar-item {
                flex-direction: row;
                gap: 2px 6px;
                transition: none; }
            .bar-item__counter {
                left: 25px; }
            .bar-item__icon-cmp {
                margin-top: -2px; } } }
    &.scroll-sticky {
        .bar-item {
            padding: 6px 10px;
            @include m(1024px) {
                padding: 7px 12px 6px; }
            &--cart {
                padding: 7px 13px 6px; } }

        .bar-item__ttl {
            display: none; } }

    &__menu {
        position: relative;
        @media (max-width: 1023px) {
            display: none; }
        &-item {
            position: relative;
            display: block;
            font-family: $crpm;
            font-size: 16px;
            line-height: 1;
            padding: 10px 0 20px;
            transition: color .2s ease;
            cursor: pointer;
            @media (max-width: 1399px) {
                font-family: $crp; }
            @media (max-width: 1199px) {
                font-size: 15px; }
            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: transparent;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: background .2s ease; }
            &.is-active {
                &::after {
                    background-color: $orange; }
                .icon {
                    transform: translate(0, 0) rotate(180deg); } }

            &:hover {
                color: $orange;
                .icon {
                    @include svg-pos-image-select-arr-sm-hover; } }

            .icon {
                position: absolute;
                width: 10px;
                height: 7px;
                top: 16px;
                right: -15px;
                transform: translate(0, 0); } } }

    &--index {
        box-shadow: 0px 8px 24px rgba(187,187,187, .04); }

    &--cart {
        box-shadow: none;
        padding-top: 35px;
        @media (max-width: 767px) {
            padding: 0; }
        .container {
            display: block; }
        .header__inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 1023px) {
                padding: 0 48px; }
            @media (max-width: 767.8px) {
                flex-wrap: wrap;
                padding: 0 16px; } }
        .header__block {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                display: block; } }
        .header__logo {
            margin-top: 0;
            margin-right: 58px;
            @media (max-width: 767px) {
                margin: 0; }
            @media (max-width: 374px) {
                padding: 4px 0; } }
        .h-location {
            @media (max-width: 767px) {
                margin-top: 20px; }
            .dropdown {
                @media (max-width: 374px) {
                    width: 304px; } } }
        .h-phones {
            @media (max-width: 767px) {
                margin: 16px 0; }
            &__item {
                font-family: $crpm;
                @media (max-width: 374px) {
                    font-size: 14px; } }
            .dd-phones {
                @media (max-width: 374px) {
                    width: 304px; } } }
        .h-ads {
            @include m(768px) {
                display: none; } }
        &.has-ads {
            & + .header-filler {
                height: 144px; } } }

    &--checkout {
        padding: 8.47px 0 10.35px;
        .header__logo {
            margin-right: 0; } }

    &__back {
        display: flex;
        align-items: center;
        &-text {
            font-family: $crpm;
            font-size: 14px;
            line-height: 24px;
            color: $blue; }
        .icon {
            margin-right: 8px; } } }
.catalog-menu {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 98;

    @media (max-width: 1023px) {
        display: none !important; } }
.has-ads {
    .header__main {
        @include r(767px) {
            padding-bottom: 0; } }
    .h-ads {
        display: initial; }

    & + .header-filler {
        height: 172px; } }

.header-filler {
    display: none;
    height: 84px;
    @include m(992px) {
        height: 96px; }
    @media (max-width: 1023px) {
        display: block; } }


.header--cart + .header-filler {
    @media (max-width: 480px) {
        min-height: 88px; } }

.mob-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: calc(var(--vh, 1vh) * 100);
    transform: translateX(-100%);
    @media (min-width: 1280px) {
        display: none !important; } }

@media (max-width: 1399px) {
    .header__actions-item a.btn {
        display: inline-flex; } }
@media (max-width: 479px) {
    .header__logo p {
        display: none; }
    .header__actions-item.header__actions-item--search {
        display: none; } }
@media (max-width: 359px) {
    .header__main-block .hamburger {
        margin: 0 9px -2px -5px; }
    .header__main-block {
        margin-right: 5px; }
    .header__logo img {
        max-width: 90%; }
    .header__actions-item {
        margin-right: 12px; } }
@media (max-width: 768px) {
    .a-table-scroll {
        touch-action: auto!important;
        overflow-x: auto!important; } }
@media (min-width: 768px) {
    .header__actions-item.header__actions-item--phone {
        display: none; } }
.counter[data-cnt="0"] {
    display: none; }
.header__action--cart .h-cart-item>span:not(.icon) i {
    font-style: normal; }

.header-menu {
    position: fixed;
    z-index: 1003;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: left .3s ease; }
