.page--comparison .empty {
    padding-top: 119px;
    @media (max-width: 767px) {
        padding-top: 59px; } }
.page--cart div.empty {
    padding-top: 103px;
    @media (max-width: 767px) {
        padding-top: 53px; } }
div.empty {
    padding: 109px 16px 248px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto !important;
    height: auto !important;
    overflow: auto !important;
    background: #ffffff;

    @media (max-width: 767px) {
        padding: 59px 16px 198px; }

    &__title {
        margin-bottom: 10px;
        text-align: center; }

    &__body {
        margin-bottom: 31px;
        font-size: 16px;
        line-height: 28px;
        text-align: center; }

    &__btn {
        .btn {
            width: 272px;
            height: 52px;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            padding: 2px 18px 1px; } } }
