%svg-common {
	background: url("../images/sprites/main_1740549547060.svg") no-repeat;
}

.svg-image-360deg {
	@extend %svg-common;
	background-position: 23.317307692307693% 17.206982543640898%;
	background-size: (450/34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-360deg {
	background-position: 23.317307692307693% 17.206982543640898%;
}
@mixin svg-image-360deg-hover {
	.svg-image-360deg {
		@include svg-pos-image-360deg-hover
	}
}

.svg-image-360deg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (28/34)*100%;
}

.svg-image-360deg-dims {
	width: 34px;
	height: 28px;
}

.svg-image-360deg-color {
	@extend %svg-common;
	background-position: 79.09738717339667% 14.742014742014742%;
	background-size: (450/29)*100% auto;
	width: 29px;

}
@mixin svg-pos-image-360deg-color {
	background-position: 79.09738717339667% 14.742014742014742%;
}
@mixin svg-image-360deg-color-hover {
	.svg-image-360deg-color {
		@include svg-pos-image-360deg-color-hover
	}
}

.svg-image-360deg-color:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/29)*100%;
}

.svg-image-360deg-color-dims {
	width: 29px;
	height: 22px;
}

.svg-image-a-calendar {
	@extend %svg-common;
	background-position: 39.120370370370374% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-a-calendar {
	background-position: 39.120370370370374% 85.64476885644768%;
}
@mixin svg-image-a-calendar-hover {
	.svg-image-a-calendar {
		@include svg-pos-image-a-calendar-hover
	}
}

.svg-image-a-calendar:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-a-calendar-dims {
	width: 18px;
	height: 18px;
}

.svg-image-a-clock {
	@extend %svg-common;
	background-position: 43.28703703703704% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-a-clock {
	background-position: 43.28703703703704% 85.64476885644768%;
}
@mixin svg-image-a-clock-hover {
	.svg-image-a-clock {
		@include svg-pos-image-a-clock-hover
	}
}

.svg-image-a-clock:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-a-clock-dims {
	width: 18px;
	height: 18px;
}

.svg-image-a-view {
	@extend %svg-common;
	background-position: 95.34883720930233% 61.9277108433735%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-a-view {
	background-position: 95.34883720930233% 61.9277108433735%;
}
@mixin svg-image-a-view-hover {
	.svg-image-a-view {
		@include svg-pos-image-a-view-hover
	}
}

.svg-image-a-view:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/20)*100%;
}

.svg-image-a-view-dims {
	width: 20px;
	height: 14px;
}

.svg-image-about-24hours {
	@extend %svg-common;
	background-position: 27.59433962264151% 30.76923076923077%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-about-24hours {
	background-position: 27.59433962264151% 30.76923076923077%;
}
@mixin svg-image-about-24hours-hover {
	.svg-image-about-24hours {
		@include svg-pos-image-about-24hours-hover
	}
}

.svg-image-about-24hours:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-about-24hours-dims {
	width: 26px;
	height: 26px;
}

.svg-image-about-filtration {
	@extend %svg-common;
	background-position: 50.235849056603776% 63.52357320099256%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-about-filtration {
	background-position: 50.235849056603776% 63.52357320099256%;
}
@mixin svg-image-about-filtration-hover {
	.svg-image-about-filtration {
		@include svg-pos-image-about-filtration-hover
	}
}

.svg-image-about-filtration:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-about-filtration-dims {
	width: 26px;
	height: 26px;
}

.svg-image-about-price {
	@extend %svg-common;
	background-position: 56.367924528301884% 63.52357320099256%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-about-price {
	background-position: 56.367924528301884% 63.52357320099256%;
}
@mixin svg-image-about-price-hover {
	.svg-image-about-price {
		@include svg-pos-image-about-price-hover
	}
}

.svg-image-about-price:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-about-price-dims {
	width: 26px;
	height: 26px;
}

.svg-image-about-shield {
	@extend %svg-common;
	background-position: 62.5% 63.52357320099256%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-about-shield {
	background-position: 62.5% 63.52357320099256%;
}
@mixin svg-image-about-shield-hover {
	.svg-image-about-shield {
		@include svg-pos-image-about-shield-hover
	}
}

.svg-image-about-shield:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-about-shield-dims {
	width: 26px;
	height: 26px;
}

.svg-image-add {
	@extend %svg-common;
	background-position: 95.34883720930233% 66.25916870415648%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-add {
	background-position: 95.34883720930233% 66.25916870415648%;
}
@mixin svg-image-add-hover {
	.svg-image-add {
		@include svg-pos-image-add-hover
	}
}

.svg-image-add:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-add-dims {
	width: 20px;
	height: 20px;
}

.svg-image-advice {
	@extend %svg-common;
	background-position: 95.34883720930233% 71.14914425427872%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-advice {
	background-position: 95.34883720930233% 71.14914425427872%;
}
@mixin svg-image-advice-hover {
	.svg-image-advice {
		@include svg-pos-image-advice-hover
	}
}

.svg-image-advice:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-advice-dims {
	width: 20px;
	height: 20px;
}

.svg-image-apple-pay {
	@extend %svg-common;
	background-position: 56.79611650485437% 42.96116504854369%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-apple-pay {
	background-position: 56.79611650485437% 42.96116504854369%;
}
@mixin svg-image-apple-pay-hover {
	.svg-image-apple-pay {
		@include svg-pos-image-apple-pay-hover
	}
}

.svg-image-apple-pay:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/38)*100%;
}

.svg-image-apple-pay-dims {
	width: 38px;
	height: 17px;
}

.svg-image-apps-app-store {
	@extend %svg-common;
	background-position: 0 17.25%;
	background-size: (450/97)*100% auto;
	width: 97px;

}
@mixin svg-pos-image-apps-app-store {
	background-position: 0 17.25%;
}
@mixin svg-image-apps-app-store-hover {
	.svg-image-apps-app-store {
		@include svg-pos-image-apps-app-store-hover
	}
}

.svg-image-apps-app-store:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (29/97)*100%;
}

.svg-image-apps-app-store-dims {
	width: 97px;
	height: 29px;
}

.svg-image-apps-google-play {
	@extend %svg-common;
	background-position: 0 10%;
	background-size: (450/110)*100% auto;
	width: 110px;

}
@mixin svg-pos-image-apps-google-play {
	background-position: 0 10%;
}
@mixin svg-image-apps-google-play-hover {
	.svg-image-apps-google-play {
		@include svg-pos-image-apps-google-play-hover
	}
}

.svg-image-apps-google-play:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (29/110)*100%;
}

.svg-image-apps-google-play-dims {
	width: 110px;
	height: 29px;
}

.svg-image-arr-left-blue {
	@extend %svg-common;
	background-position: 71.3302752293578% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-arr-left-blue {
	background-position: 71.3302752293578% 95.42168674698796%;
}
@mixin svg-image-arr-left-blue-hover {
	.svg-image-arr-left-blue {
		@include svg-pos-image-arr-left-blue-hover
	}
}

.svg-image-arr-left-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-arr-left-blue-dims {
	width: 14px;
	height: 14px;
}

.svg-image-arr-right {
	@extend %svg-common;
	background-position: 25.986078886310906% 85.64476885644768%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-arr-right {
	background-position: 25.986078886310906% 85.64476885644768%;
}
@mixin svg-image-arr-right-hover {
	.svg-image-arr-right {
		@include svg-pos-image-arr-right-hover
	}
}

.svg-image-arr-right:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/19)*100%;
}

.svg-image-arr-right-dims {
	width: 19px;
	height: 18px;
}

.svg-image-arr-right-hover {
	@extend %svg-common;
	background-position: 30.394431554524363% 85.64476885644768%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-arr-right-hover {
	background-position: 30.394431554524363% 85.64476885644768%;
}
@mixin svg-image-arr-right-hover-hover {
	.svg-image-arr-right-hover {
		@include svg-pos-image-arr-right-hover-hover
	}
}

.svg-image-arr-right-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/19)*100%;
}

.svg-image-arr-right-hover-dims {
	width: 19px;
	height: 18px;
}

.svg-image-arr-right-long {
	@extend %svg-common;
	background-position: 79.09738717339667% 19.902912621359224%;
	background-size: (450/29)*100% auto;
	width: 29px;

}
@mixin svg-pos-image-arr-right-long {
	background-position: 79.09738717339667% 19.902912621359224%;
}
@mixin svg-image-arr-right-long-hover {
	.svg-image-arr-right-long {
		@include svg-pos-image-arr-right-long-hover
	}
}

.svg-image-arr-right-long:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/29)*100%;
}

.svg-image-arr-right-long-dims {
	width: 29px;
	height: 17px;
}

.svg-image-arrow-right-gray {
	@extend %svg-common;
	background-position: 95.34883720930233% 76.03911980440098%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-arrow-right-gray {
	background-position: 95.34883720930233% 76.03911980440098%;
}
@mixin svg-image-arrow-right-gray-hover {
	.svg-image-arrow-right-gray {
		@include svg-pos-image-arrow-right-gray-hover
	}
}

.svg-image-arrow-right-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-arrow-right-gray-dims {
	width: 20px;
	height: 20px;
}

.svg-image-at-green {
	@extend %svg-common;
	background-position: 68.63207547169812% 63.52357320099256%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-at-green {
	background-position: 68.63207547169812% 63.52357320099256%;
}
@mixin svg-image-at-green-hover {
	.svg-image-at-green {
		@include svg-pos-image-at-green-hover
	}
}

.svg-image-at-green:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-at-green-dims {
	width: 26px;
	height: 26px;
}

.svg-image-at-red {
	@extend %svg-common;
	background-position: 0 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-at-red {
	background-position: 0 80.89330024813896%;
}
@mixin svg-image-at-red-hover {
	.svg-image-at-red {
		@include svg-pos-image-at-red-hover
	}
}

.svg-image-at-red:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-at-red-dims {
	width: 26px;
	height: 26px;
}

.svg-image-at-yellow {
	@extend %svg-common;
	background-position: 6.132075471698113% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-at-yellow {
	background-position: 6.132075471698113% 80.89330024813896%;
}
@mixin svg-image-at-yellow-hover {
	.svg-image-at-yellow {
		@include svg-pos-image-at-yellow-hover
	}
}

.svg-image-at-yellow:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-at-yellow-dims {
	width: 26px;
	height: 26px;
}

.svg-image-back {
	@extend %svg-common;
	background-position: 88.76146788990826% 48.894348894348894%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-back {
	background-position: 88.76146788990826% 48.894348894348894%;
}
@mixin svg-image-back-hover {
	.svg-image-back {
		@include svg-pos-image-back-hover
	}
}

.svg-image-back:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/14)*100%;
}

.svg-image-back-dims {
	width: 14px;
	height: 22px;
}

.svg-image-backto {
	@extend %svg-common;
	background-position: 88.76146788990826% 54.2997542997543%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-backto {
	background-position: 88.76146788990826% 54.2997542997543%;
}
@mixin svg-image-backto-hover {
	.svg-image-backto {
		@include svg-pos-image-backto-hover
	}
}

.svg-image-backto:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/14)*100%;
}

.svg-image-backto-dims {
	width: 14px;
	height: 22px;
}

.svg-image-bank {
	@extend %svg-common;
	background-position: 85.01170960187353% 49.26108374384236%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-bank {
	background-position: 85.01170960187353% 49.26108374384236%;
}
@mixin svg-image-bank-hover {
	.svg-image-bank {
		@include svg-pos-image-bank-hover
	}
}

.svg-image-bank:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/23)*100%;
}

.svg-image-bank-dims {
	width: 23px;
	height: 23px;
}

.svg-image-barcode {
	@extend %svg-common;
	background-position: 95.34883720930233% 80.5352798053528%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-barcode {
	background-position: 95.34883720930233% 80.5352798053528%;
}
@mixin svg-image-barcode-hover {
	.svg-image-barcode {
		@include svg-pos-image-barcode-hover
	}
}

.svg-image-barcode:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/20)*100%;
}

.svg-image-barcode-dims {
	width: 20px;
	height: 18px;
}

.svg-image-camel {
	@extend %svg-common;
	background-position: 99.08256880733946% 60.38647342995169%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-camel {
	background-position: 99.08256880733946% 60.38647342995169%;
}
@mixin svg-image-camel-hover {
	.svg-image-camel {
		@include svg-pos-image-camel-hover
	}
}

.svg-image-camel:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-camel-dims {
	width: 14px;
	height: 15px;
}

.svg-image-camera {
	@extend %svg-common;
	background-position: 29.41176470588235% 24.078624078624077%;
	background-size: (450/25)*100% auto;
	width: 25px;

}
@mixin svg-pos-image-camera {
	background-position: 29.41176470588235% 24.078624078624077%;
}
@mixin svg-image-camera-hover {
	.svg-image-camera {
		@include svg-pos-image-camera-hover
	}
}

.svg-image-camera:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/25)*100%;
}

.svg-image-camera-dims {
	width: 25px;
	height: 22px;
}

.svg-image-cart {
	@extend %svg-common;
	background-position: 78.87323943661971% 80.29556650246306%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-cart {
	background-position: 78.87323943661971% 80.29556650246306%;
}
@mixin svg-image-cart-hover {
	.svg-image-cart {
		@include svg-pos-image-cart-hover
	}
}

.svg-image-cart:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/24)*100%;
}

.svg-image-cart-dims {
	width: 24px;
	height: 23px;
}

.svg-image-cart-o {
	@extend %svg-common;
	background-position: 79.11832946635731% 91.21951219512195%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-cart-o {
	background-position: 79.11832946635731% 91.21951219512195%;
}
@mixin svg-image-cart-o-hover {
	.svg-image-cart-o {
		@include svg-pos-image-cart-o-hover
	}
}

.svg-image-cart-o:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/19)*100%;
}

.svg-image-cart-o-dims {
	width: 19px;
	height: 19px;
}

.svg-image-cart-w {
	@extend %svg-common;
	background-position: 85.21126760563381% 0;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-cart-w {
	background-position: 85.21126760563381% 0;
}
@mixin svg-image-cart-w-hover {
	.svg-image-cart-w {
		@include svg-pos-image-cart-w-hover
	}
}

.svg-image-cart-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/24)*100%;
}

.svg-image-cart-w-dims {
	width: 24px;
	height: 23px;
}

.svg-image-catalog {
	@extend %svg-common;
	background-position: 99.53917050691244% 28.571428571428573%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-catalog {
	background-position: 99.53917050691244% 28.571428571428573%;
}
@mixin svg-image-catalog-hover {
	.svg-image-catalog {
		@include svg-pos-image-catalog-hover
	}
}

.svg-image-catalog:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/16)*100%;
}

.svg-image-catalog-dims {
	width: 16px;
	height: 16px;
}

.svg-image-catalog-o {
	@extend %svg-common;
	background-position: 99.53917050691244% 32.445520581113804%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-catalog-o {
	background-position: 99.53917050691244% 32.445520581113804%;
}
@mixin svg-image-catalog-o-hover {
	.svg-image-catalog-o {
		@include svg-pos-image-catalog-o-hover
	}
}

.svg-image-catalog-o:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/16)*100%;
}

.svg-image-catalog-o-dims {
	width: 16px;
	height: 16px;
}

.svg-image-chair {
	@extend %svg-common;
	background-position: 84.81308411214954% 54.926108374384235%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-chair {
	background-position: 84.81308411214954% 54.926108374384235%;
}
@mixin svg-image-chair-hover {
	.svg-image-chair {
		@include svg-pos-image-chair-hover
	}
}

.svg-image-chair:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/22)*100%;
}

.svg-image-chair-dims {
	width: 22px;
	height: 23px;
}

.svg-image-chat-circle {
	@extend %svg-common;
	background-position: 99.53917050691244% 36.31961259079903%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-chat-circle {
	background-position: 99.53917050691244% 36.31961259079903%;
}
@mixin svg-image-chat-circle-hover {
	.svg-image-chat-circle {
		@include svg-pos-image-chat-circle-hover
	}
}

.svg-image-chat-circle:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/16)*100%;
}

.svg-image-chat-circle-dims {
	width: 16px;
	height: 16px;
}

.svg-image-chat-circle-blue {
	@extend %svg-common;
	background-position: 99.53917050691244% 4.368932038834951%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-chat-circle-blue {
	background-position: 99.53917050691244% 4.368932038834951%;
}
@mixin svg-image-chat-circle-blue-hover {
	.svg-image-chat-circle-blue {
		@include svg-pos-image-chat-circle-blue-hover
	}
}

.svg-image-chat-circle-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/16)*100%;
}

.svg-image-chat-circle-blue-dims {
	width: 16px;
	height: 17px;
}

.svg-image-check-o {
	@extend %svg-common;
	background-position: 63.109048723897914% 42.7536231884058%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-check-o {
	background-position: 63.109048723897914% 42.7536231884058%;
}
@mixin svg-image-check-o-hover {
	.svg-image-check-o {
		@include svg-pos-image-check-o-hover
	}
}

.svg-image-check-o:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/19)*100%;
}

.svg-image-check-o-dims {
	width: 19px;
	height: 15px;
}

.svg-image-check-w {
	@extend %svg-common;
	background-position: 95.34883720930233% 84.50363196125907%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-check-w {
	background-position: 95.34883720930233% 84.50363196125907%;
}
@mixin svg-image-check-w-hover {
	.svg-image-check-w {
		@include svg-pos-image-check-w-hover
	}
}

.svg-image-check-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/20)*100%;
}

.svg-image-check-w-dims {
	width: 20px;
	height: 16px;
}

.svg-image-clip {
	@extend %svg-common;
	background-position: 90.42056074766356% 59.70515970515971%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-clip {
	background-position: 90.42056074766356% 59.70515970515971%;
}
@mixin svg-image-clip-hover {
	.svg-image-clip {
		@include svg-pos-image-clip-hover
	}
}

.svg-image-clip:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-clip-dims {
	width: 22px;
	height: 22px;
}

.svg-image-clip-w {
	@extend %svg-common;
	background-position: 90.42056074766356% 65.1105651105651%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-clip-w {
	background-position: 90.42056074766356% 65.1105651105651%;
}
@mixin svg-image-clip-w-hover {
	.svg-image-clip-w {
		@include svg-pos-image-clip-w-hover
	}
}

.svg-image-clip-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-clip-w-dims {
	width: 22px;
	height: 22px;
}

.svg-image-clock {
	@extend %svg-common;
	background-position: 47.4537037037037% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-clock {
	background-position: 47.4537037037037% 85.64476885644768%;
}
@mixin svg-image-clock-hover {
	.svg-image-clock {
		@include svg-pos-image-clock-hover
	}
}

.svg-image-clock:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-clock-dims {
	width: 18px;
	height: 18px;
}

.svg-image-clock-o {
	@extend %svg-common;
	background-position: 51.620370370370374% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-clock-o {
	background-position: 51.620370370370374% 85.64476885644768%;
}
@mixin svg-image-clock-o-hover {
	.svg-image-clock-o {
		@include svg-pos-image-clock-o-hover
	}
}

.svg-image-clock-o:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-clock-o-dims {
	width: 18px;
	height: 18px;
}

.svg-image-close {
	@extend %svg-common;
	background-position: 90.42056074766356% 70.51597051597052%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-close {
	background-position: 90.42056074766356% 70.51597051597052%;
}
@mixin svg-image-close-hover {
	.svg-image-close {
		@include svg-pos-image-close-hover
	}
}

.svg-image-close:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-close-dims {
	width: 22px;
	height: 22px;
}

.svg-image-close-gray {
	@extend %svg-common;
	background-position: 90.42056074766356% 75.92137592137593%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-close-gray {
	background-position: 90.42056074766356% 75.92137592137593%;
}
@mixin svg-image-close-gray-hover {
	.svg-image-close-gray {
		@include svg-pos-image-close-gray-hover
	}
}

.svg-image-close-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-close-gray-dims {
	width: 22px;
	height: 22px;
}

.svg-image-close-gray-24 {
	@extend %svg-common;
	background-position: 12.264150943396226% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-close-gray-24 {
	background-position: 12.264150943396226% 80.89330024813896%;
}
@mixin svg-image-close-gray-24-hover {
	.svg-image-close-gray-24 {
		@include svg-pos-image-close-gray-24-hover
	}
}

.svg-image-close-gray-24:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-close-gray-24-dims {
	width: 26px;
	height: 26px;
}

.svg-image-close-sm {
	@extend %svg-common;
	background-position: 99.3103448275862% 63.855421686746986%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-close-sm {
	background-position: 99.3103448275862% 63.855421686746986%;
}
@mixin svg-image-close-sm-hover {
	.svg-image-close-sm {
		@include svg-pos-image-close-sm-hover
	}
}

.svg-image-close-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/15)*100%;
}

.svg-image-close-sm-dims {
	width: 15px;
	height: 14px;
}

.svg-image-close-sm-dg {
	@extend %svg-common;
	background-position: 99.3103448275862% 67.2289156626506%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-close-sm-dg {
	background-position: 99.3103448275862% 67.2289156626506%;
}
@mixin svg-image-close-sm-dg-hover {
	.svg-image-close-sm-dg {
		@include svg-pos-image-close-sm-dg-hover
	}
}

.svg-image-close-sm-dg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/15)*100%;
}

.svg-image-close-sm-dg-dims {
	width: 15px;
	height: 14px;
}

.svg-image-close-sm-g {
	@extend %svg-common;
	background-position: 99.3103448275862% 70.60240963855422%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-close-sm-g {
	background-position: 99.3103448275862% 70.60240963855422%;
}
@mixin svg-image-close-sm-g-hover {
	.svg-image-close-sm-g {
		@include svg-pos-image-close-sm-g-hover
	}
}

.svg-image-close-sm-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/15)*100%;
}

.svg-image-close-sm-g-dims {
	width: 15px;
	height: 14px;
}

.svg-image-close-sm-hover {
	@extend %svg-common;
	background-position: 99.3103448275862% 73.97590361445783%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-close-sm-hover {
	background-position: 99.3103448275862% 73.97590361445783%;
}
@mixin svg-image-close-sm-hover-hover {
	.svg-image-close-sm-hover {
		@include svg-pos-image-close-sm-hover-hover
	}
}

.svg-image-close-sm-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/15)*100%;
}

.svg-image-close-sm-hover-dims {
	width: 15px;
	height: 14px;
}

.svg-image-close-sm-w {
	@extend %svg-common;
	background-position: 29.680365296803654% 12.949640287769784%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-close-sm-w {
	background-position: 29.680365296803654% 12.949640287769784%;
}
@mixin svg-image-close-sm-w-hover {
	.svg-image-close-sm-w {
		@include svg-pos-image-close-sm-w-hover
	}
}

.svg-image-close-sm-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/12)*100%;
}

.svg-image-close-sm-w-dims {
	width: 12px;
	height: 12px;
}

.svg-image-close-w {
	@extend %svg-common;
	background-position: 90.42056074766356% 81.32678132678133%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-close-w {
	background-position: 90.42056074766356% 81.32678132678133%;
}
@mixin svg-image-close-w-hover {
	.svg-image-close-w {
		@include svg-pos-image-close-w-hover
	}
}

.svg-image-close-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-close-w-dims {
	width: 22px;
	height: 22px;
}

.svg-image-compare {
	@extend %svg-common;
	background-position: 85.21126760563381% 5.665024630541872%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-compare {
	background-position: 85.21126760563381% 5.665024630541872%;
}
@mixin svg-image-compare-hover {
	.svg-image-compare {
		@include svg-pos-image-compare-hover
	}
}

.svg-image-compare:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/24)*100%;
}

.svg-image-compare-dims {
	width: 24px;
	height: 23px;
}

.svg-image-compare-g {
	@extend %svg-common;
	background-position: 99.7690531177829% 8.474576271186441%;
	background-size: (450/17)*100% auto;
	width: 17px;

}
@mixin svg-pos-image-compare-g {
	background-position: 99.7690531177829% 8.474576271186441%;
}
@mixin svg-image-compare-g-hover {
	.svg-image-compare-g {
		@include svg-pos-image-compare-g-hover
	}
}

.svg-image-compare-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/17)*100%;
}

.svg-image-compare-g-dims {
	width: 17px;
	height: 16px;
}

.svg-image-compare-g-hover {
	@extend %svg-common;
	background-position: 99.7690531177829% 12.34866828087167%;
	background-size: (450/17)*100% auto;
	width: 17px;

}
@mixin svg-pos-image-compare-g-hover {
	background-position: 99.7690531177829% 12.34866828087167%;
}
@mixin svg-image-compare-g-hover-hover {
	.svg-image-compare-g-hover {
		@include svg-pos-image-compare-g-hover-hover
	}
}

.svg-image-compare-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/17)*100%;
}

.svg-image-compare-g-hover-dims {
	width: 17px;
	height: 16px;
}

.svg-image-content-copy {
	@extend %svg-common;
	background-position: 95.34883720930233% 89.24205378973105%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-content-copy {
	background-position: 95.34883720930233% 89.24205378973105%;
}
@mixin svg-image-content-copy-hover {
	.svg-image-content-copy {
		@include svg-pos-image-content-copy-hover
	}
}

.svg-image-content-copy:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-content-copy-dims {
	width: 20px;
	height: 20px;
}

.svg-image-copy {
	@extend %svg-common;
	background-position: 55.78703703703704% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-copy {
	background-position: 55.78703703703704% 85.64476885644768%;
}
@mixin svg-image-copy-hover {
	.svg-image-copy {
		@include svg-pos-image-copy-hover
	}
}

.svg-image-copy:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-copy-dims {
	width: 18px;
	height: 18px;
}

.svg-image-copy-gray {
	@extend %svg-common;
	background-position: 59.9537037037037% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-copy-gray {
	background-position: 59.9537037037037% 85.64476885644768%;
}
@mixin svg-image-copy-gray-hover {
	.svg-image-copy-gray {
		@include svg-pos-image-copy-gray-hover
	}
}

.svg-image-copy-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-copy-gray-dims {
	width: 18px;
	height: 18px;
}

.svg-image-copy-hover {
	@extend %svg-common;
	background-position: 64.12037037037037% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-copy-hover {
	background-position: 64.12037037037037% 85.64476885644768%;
}
@mixin svg-image-copy-hover-hover {
	.svg-image-copy-hover {
		@include svg-pos-image-copy-hover-hover
	}
}

.svg-image-copy-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-copy-hover-dims {
	width: 18px;
	height: 18px;
}

.svg-image-cr-feedback {
	@extend %svg-common;
	background-position: 73.04147465437788% 62.8992628992629%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-cr-feedback {
	background-position: 73.04147465437788% 62.8992628992629%;
}
@mixin svg-image-cr-feedback-hover {
	.svg-image-cr-feedback {
		@include svg-pos-image-cr-feedback-hover
	}
}

.svg-image-cr-feedback:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/16)*100%;
}

.svg-image-cr-feedback-dims {
	width: 16px;
	height: 22px;
}

.svg-image-cr-video {
	@extend %svg-common;
	background-position: 0 91.89189189189189%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-cr-video {
	background-position: 0 91.89189189189189%;
}
@mixin svg-image-cr-video-hover {
	.svg-image-cr-video {
		@include svg-pos-image-cr-video-hover
	}
}

.svg-image-cr-video:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/16)*100%;
}

.svg-image-cr-video-dims {
	width: 16px;
	height: 22px;
}

.svg-image-darr {
	@extend %svg-common;
	background-position: 76.02739726027397% 75.29691211401425%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-darr {
	background-position: 76.02739726027397% 75.29691211401425%;
}
@mixin svg-image-darr-hover {
	.svg-image-darr {
		@include svg-pos-image-darr-hover
	}
}

.svg-image-darr:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (8/12)*100%;
}

.svg-image-darr-dims {
	width: 12px;
	height: 8px;
}

.svg-image-dash {
	@extend %svg-common;
	background-position: 68.28703703703704% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-dash {
	background-position: 68.28703703703704% 85.64476885644768%;
}
@mixin svg-image-dash-hover {
	.svg-image-dash {
		@include svg-pos-image-dash-hover
	}
}

.svg-image-dash:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-dash-dims {
	width: 18px;
	height: 18px;
}

.svg-image-delivery {
	@extend %svg-common;
	background-position: 85.01170960187353% 60.59113300492611%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-delivery {
	background-position: 85.01170960187353% 60.59113300492611%;
}
@mixin svg-image-delivery-hover {
	.svg-image-delivery {
		@include svg-pos-image-delivery-hover
	}
}

.svg-image-delivery:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/23)*100%;
}

.svg-image-delivery-dims {
	width: 23px;
	height: 23px;
}

.svg-image-diagonal-arr {
	@extend %svg-common;
	background-position: 99.3103448275862% 77.53623188405797%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-diagonal-arr {
	background-position: 99.3103448275862% 77.53623188405797%;
}
@mixin svg-image-diagonal-arr-hover {
	.svg-image-diagonal-arr {
		@include svg-pos-image-diagonal-arr-hover
	}
}

.svg-image-diagonal-arr:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/15)*100%;
}

.svg-image-diagonal-arr-dims {
	width: 15px;
	height: 15px;
}

.svg-image-discont-sms {
	@extend %svg-common;
	background-position: 71.32352941176471% 0;
	background-size: (450/42)*100% auto;
	width: 42px;

}
@mixin svg-pos-image-discont-sms {
	background-position: 71.32352941176471% 0;
}
@mixin svg-image-discont-sms-hover {
	.svg-image-discont-sms {
		@include svg-pos-image-discont-sms-hover
	}
}

.svg-image-discont-sms:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-discont-sms-dims {
	width: 42px;
	height: 42px;
}

.svg-image-discount {
	@extend %svg-common;
	background-position: 70.97560975609755% 14.07035175879397%;
	background-size: (450/40)*100% auto;
	width: 40px;

}
@mixin svg-pos-image-discount {
	background-position: 70.97560975609755% 14.07035175879397%;
}
@mixin svg-image-discount-hover {
	.svg-image-discount {
		@include svg-pos-image-discount-hover
	}
}

.svg-image-discount:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (31/40)*100%;
}

.svg-image-discount-dims {
	width: 40px;
	height: 31px;
}

.svg-image-dislike {
	@extend %svg-common;
	background-position: 18.39622641509434% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-dislike {
	background-position: 18.39622641509434% 80.89330024813896%;
}
@mixin svg-image-dislike-hover {
	.svg-image-dislike {
		@include svg-pos-image-dislike-hover
	}
}

.svg-image-dislike:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-dislike-dims {
	width: 26px;
	height: 26px;
}

.svg-image-dislike-b {
	@extend %svg-common;
	background-position: 3.7383177570093458% 91.89189189189189%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-dislike-b {
	background-position: 3.7383177570093458% 91.89189189189189%;
}
@mixin svg-image-dislike-b-hover {
	.svg-image-dislike-b {
		@include svg-pos-image-dislike-b-hover
	}
}

.svg-image-dislike-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-dislike-b-dims {
	width: 22px;
	height: 22px;
}

.svg-image-doc {
	@extend %svg-common;
	background-position: 84.22273781902553% 66.25615763546799%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-doc {
	background-position: 84.22273781902553% 66.25615763546799%;
}
@mixin svg-image-doc-hover {
	.svg-image-doc {
		@include svg-pos-image-doc-hover
	}
}

.svg-image-doc:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/19)*100%;
}

.svg-image-doc-dims {
	width: 19px;
	height: 23px;
}

.svg-image-dumbbell {
	@extend %svg-common;
	background-position: 74.54128440366972% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-dumbbell {
	background-position: 74.54128440366972% 95.42168674698796%;
}
@mixin svg-image-dumbbell-hover {
	.svg-image-dumbbell {
		@include svg-pos-image-dumbbell-hover
	}
}

.svg-image-dumbbell:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-dumbbell-dims {
	width: 14px;
	height: 14px;
}

.svg-image-emoji-fire {
	@extend %svg-common;
	background-position: 32.42009132420091% 12.949640287769784%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-emoji-fire {
	background-position: 32.42009132420091% 12.949640287769784%;
}
@mixin svg-image-emoji-fire-hover {
	.svg-image-emoji-fire {
		@include svg-pos-image-emoji-fire-hover
	}
}

.svg-image-emoji-fire:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/12)*100%;
}

.svg-image-emoji-fire-dims {
	width: 12px;
	height: 12px;
}

.svg-image-f-menu-icon-1 {
	@extend %svg-common;
	background-position: 39.18575063613232% 13.672922252010723%;
	background-size: (450/57)*100% auto;
	width: 57px;

}
@mixin svg-pos-image-f-menu-icon-1 {
	background-position: 39.18575063613232% 13.672922252010723%;
}
@mixin svg-image-f-menu-icon-1-hover {
	.svg-image-f-menu-icon-1 {
		@include svg-pos-image-f-menu-icon-1-hover
	}
}

.svg-image-f-menu-icon-1:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (56/57)*100%;
}

.svg-image-f-menu-icon-1-dims {
	width: 57px;
	height: 56px;
}

.svg-image-f-menu-icon-2 {
	@extend %svg-common;
	background-position: 0 40.21447721179624%;
	background-size: (450/57)*100% auto;
	width: 57px;

}
@mixin svg-pos-image-f-menu-icon-2 {
	background-position: 0 40.21447721179624%;
}
@mixin svg-image-f-menu-icon-2-hover {
	.svg-image-f-menu-icon-2 {
		@include svg-pos-image-f-menu-icon-2-hover
	}
}

.svg-image-f-menu-icon-2:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (56/57)*100%;
}

.svg-image-f-menu-icon-2-dims {
	width: 57px;
	height: 56px;
}

.svg-image-f-menu-icon-3 {
	@extend %svg-common;
	background-position: 14.50381679389313% 40.21447721179624%;
	background-size: (450/57)*100% auto;
	width: 57px;

}
@mixin svg-pos-image-f-menu-icon-3 {
	background-position: 14.50381679389313% 40.21447721179624%;
}
@mixin svg-image-f-menu-icon-3-hover {
	.svg-image-f-menu-icon-3 {
		@include svg-pos-image-f-menu-icon-3-hover
	}
}

.svg-image-f-menu-icon-3:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (56/57)*100%;
}

.svg-image-f-menu-icon-3-dims {
	width: 57px;
	height: 56px;
}

.svg-image-f-menu-icon-4 {
	@extend %svg-common;
	background-position: 29.00763358778626% 40.21447721179624%;
	background-size: (450/57)*100% auto;
	width: 57px;

}
@mixin svg-pos-image-f-menu-icon-4 {
	background-position: 29.00763358778626% 40.21447721179624%;
}
@mixin svg-image-f-menu-icon-4-hover {
	.svg-image-f-menu-icon-4 {
		@include svg-pos-image-f-menu-icon-4-hover
	}
}

.svg-image-f-menu-icon-4:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (56/57)*100%;
}

.svg-image-f-menu-icon-4-dims {
	width: 57px;
	height: 56px;
}

.svg-image-f-menu-icon-5 {
	@extend %svg-common;
	background-position: 43.51145038167939% 40.21447721179624%;
	background-size: (450/57)*100% auto;
	width: 57px;

}
@mixin svg-pos-image-f-menu-icon-5 {
	background-position: 43.51145038167939% 40.21447721179624%;
}
@mixin svg-image-f-menu-icon-5-hover {
	.svg-image-f-menu-icon-5 {
		@include svg-pos-image-f-menu-icon-5-hover
	}
}

.svg-image-f-menu-icon-5:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (56/57)*100%;
}

.svg-image-f-menu-icon-5-dims {
	width: 57px;
	height: 56px;
}

.svg-image-f-menu-icon-6 {
	@extend %svg-common;
	background-position: 59.541984732824424% 0;
	background-size: (450/57)*100% auto;
	width: 57px;

}
@mixin svg-pos-image-f-menu-icon-6 {
	background-position: 59.541984732824424% 0;
}
@mixin svg-image-f-menu-icon-6-hover {
	.svg-image-f-menu-icon-6 {
		@include svg-pos-image-f-menu-icon-6-hover
	}
}

.svg-image-f-menu-icon-6:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (56/57)*100%;
}

.svg-image-f-menu-icon-6-dims {
	width: 57px;
	height: 56px;
}

.svg-image-fb-b {
	@extend %svg-common;
	background-position: 18.72146118721461% 91.44254278728606%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-fb-b {
	background-position: 18.72146118721461% 91.44254278728606%;
}
@mixin svg-image-fb-b-hover {
	.svg-image-fb-b {
		@include svg-pos-image-fb-b-hover
	}
}

.svg-image-fb-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/12)*100%;
}

.svg-image-fb-b-dims {
	width: 12px;
	height: 20px;
}

.svg-image-fb-b-hover {
	@extend %svg-common;
	background-position: 21.461187214611872% 91.44254278728606%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-fb-b-hover {
	background-position: 21.461187214611872% 91.44254278728606%;
}
@mixin svg-image-fb-b-hover-hover {
	.svg-image-fb-b-hover {
		@include svg-pos-image-fb-b-hover-hover
	}
}

.svg-image-fb-b-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/12)*100%;
}

.svg-image-fb-b-hover-dims {
	width: 12px;
	height: 20px;
}

.svg-image-fb-g {
	@extend %svg-common;
	background-position: 24.200913242009133% 91.44254278728606%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-fb-g {
	background-position: 24.200913242009133% 91.44254278728606%;
}
@mixin svg-image-fb-g-hover {
	.svg-image-fb-g {
		@include svg-pos-image-fb-g-hover
	}
}

.svg-image-fb-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/12)*100%;
}

.svg-image-fb-g-dims {
	width: 12px;
	height: 20px;
}

.svg-image-fb-g-hover {
	@extend %svg-common;
	background-position: 26.940639269406393% 91.44254278728606%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-fb-g-hover {
	background-position: 26.940639269406393% 91.44254278728606%;
}
@mixin svg-image-fb-g-hover-hover {
	.svg-image-fb-g-hover {
		@include svg-pos-image-fb-g-hover-hover
	}
}

.svg-image-fb-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/12)*100%;
}

.svg-image-fb-g-hover-dims {
	width: 12px;
	height: 20px;
}

.svg-image-fb-rg {
	@extend %svg-common;
	background-position: 58.5% 28.759894459102902%;
	background-size: (450/50)*100% auto;
	width: 50px;

}
@mixin svg-pos-image-fb-rg {
	background-position: 58.5% 28.759894459102902%;
}
@mixin svg-image-fb-rg-hover {
	.svg-image-fb-rg {
		@include svg-pos-image-fb-rg-hover
	}
}

.svg-image-fb-rg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (50/50)*100%;
}

.svg-image-fb-rg-dims {
	width: 50px;
	height: 50px;
}

.svg-image-filter {
	@extend %svg-common;
	background-position: 90.42056074766356% 86.3080684596577%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-filter {
	background-position: 90.42056074766356% 86.3080684596577%;
}
@mixin svg-image-filter-hover {
	.svg-image-filter {
		@include svg-pos-image-filter-hover
	}
}

.svg-image-filter:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/22)*100%;
}

.svg-image-filter-dims {
	width: 22px;
	height: 20px;
}

.svg-image-filter-g {
	@extend %svg-common;
	background-position: 8.878504672897197% 91.89189189189189%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-filter-g {
	background-position: 8.878504672897197% 91.89189189189189%;
}
@mixin svg-image-filter-g-hover {
	.svg-image-filter-g {
		@include svg-pos-image-filter-g-hover
	}
}

.svg-image-filter-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-filter-g-dims {
	width: 22px;
	height: 22px;
}

.svg-image-filter-w {
	@extend %svg-common;
	background-position: 14.018691588785046% 91.44254278728606%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-filter-w {
	background-position: 14.018691588785046% 91.44254278728606%;
}
@mixin svg-image-filter-w-hover {
	.svg-image-filter-w {
		@include svg-pos-image-filter-w-hover
	}
}

.svg-image-filter-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/22)*100%;
}

.svg-image-filter-w-dims {
	width: 22px;
	height: 20px;
}

.svg-image-filters-arrow {
	@extend %svg-common;
	background-position: 72.45370370370371% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-filters-arrow {
	background-position: 72.45370370370371% 85.64476885644768%;
}
@mixin svg-image-filters-arrow-hover {
	.svg-image-filters-arrow {
		@include svg-pos-image-filters-arrow-hover
	}
}

.svg-image-filters-arrow:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-filters-arrow-dims {
	width: 18px;
	height: 18px;
}

.svg-image-filters-item-close {
	@extend %svg-common;
	background-position: 95.79439252336448% 0;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-filters-item-close {
	background-position: 95.79439252336448% 0;
}
@mixin svg-image-filters-item-close-hover {
	.svg-image-filters-item-close {
		@include svg-pos-image-filters-item-close-hover
	}
}

.svg-image-filters-item-close:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-filters-item-close-dims {
	width: 22px;
	height: 22px;
}

.svg-image-g-maps {
	@extend %svg-common;
	background-position: 24.528301886792452% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-g-maps {
	background-position: 24.528301886792452% 80.89330024813896%;
}
@mixin svg-image-g-maps-hover {
	.svg-image-g-maps {
		@include svg-pos-image-g-maps-hover
	}
}

.svg-image-g-maps:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-g-maps-dims {
	width: 26px;
	height: 26px;
}

.svg-image-gift {
	@extend %svg-common;
	background-position: 78.72340425531915% 48.13895781637717%;
	background-size: (450/27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-gift {
	background-position: 78.72340425531915% 48.13895781637717%;
}
@mixin svg-image-gift-hover {
	.svg-image-gift {
		@include svg-pos-image-gift-hover
	}
}

.svg-image-gift:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/27)*100%;
}

.svg-image-gift-dims {
	width: 27px;
	height: 26px;
}

.svg-image-gift-b {
	@extend %svg-common;
	background-position: 47.57281553398058% 27.365728900255753%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-gift-b {
	background-position: 47.57281553398058% 27.365728900255753%;
}
@mixin svg-image-gift-b-hover {
	.svg-image-gift-b {
		@include svg-pos-image-gift-b-hover
	}
}

.svg-image-gift-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (38/38)*100%;
}

.svg-image-gift-b-dims {
	width: 38px;
	height: 38px;
}

.svg-image-gift-circle {
	@extend %svg-common;
	background-position: 60.679611650485434% 52.68542199488491%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-gift-circle {
	background-position: 60.679611650485434% 52.68542199488491%;
}
@mixin svg-image-gift-circle-hover {
	.svg-image-gift-circle {
		@include svg-pos-image-gift-circle-hover
	}
}

.svg-image-gift-circle:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (38/38)*100%;
}

.svg-image-gift-circle-dims {
	width: 38px;
	height: 38px;
}

.svg-image-gift-circle-hover {
	@extend %svg-common;
	background-position: 0 65.47314578005115%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-gift-circle-hover {
	background-position: 0 65.47314578005115%;
}
@mixin svg-image-gift-circle-hover-hover {
	.svg-image-gift-circle-hover {
		@include svg-pos-image-gift-circle-hover-hover
	}
}

.svg-image-gift-circle-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (38/38)*100%;
}

.svg-image-gift-circle-hover-dims {
	width: 38px;
	height: 38px;
}

.svg-image-gift-hover {
	@extend %svg-common;
	background-position: 78.72340425531915% 54.59057071960298%;
	background-size: (450/27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-gift-hover {
	background-position: 78.72340425531915% 54.59057071960298%;
}
@mixin svg-image-gift-hover-hover {
	.svg-image-gift-hover {
		@include svg-pos-image-gift-hover-hover
	}
}

.svg-image-gift-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/27)*100%;
}

.svg-image-gift-hover-dims {
	width: 27px;
	height: 26px;
}

.svg-image-hamburger {
	@extend %svg-common;
	background-position: 22.61904761904762% 24.078624078624077%;
	background-size: (450/30)*100% auto;
	width: 30px;

}
@mixin svg-pos-image-hamburger {
	background-position: 22.61904761904762% 24.078624078624077%;
}
@mixin svg-image-hamburger-hover {
	.svg-image-hamburger {
		@include svg-pos-image-hamburger-hover
	}
}

.svg-image-hamburger:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/30)*100%;
}

.svg-image-hamburger-dims {
	width: 30px;
	height: 22px;
}

.svg-image-has-video {
	@extend %svg-common;
	background-position: 38.18615751789976% 73.86934673366834%;
	background-size: (450/31)*100% auto;
	width: 31px;

}
@mixin svg-pos-image-has-video {
	background-position: 38.18615751789976% 73.86934673366834%;
}
@mixin svg-image-has-video-hover {
	.svg-image-has-video {
		@include svg-pos-image-has-video-hover
	}
}

.svg-image-has-video:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (31/31)*100%;
}

.svg-image-has-video-dims {
	width: 31px;
	height: 31px;
}

.svg-image-head-phone {
	@extend %svg-common;
	background-position: 95.79439252336448% 5.405405405405405%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-head-phone {
	background-position: 95.79439252336448% 5.405405405405405%;
}
@mixin svg-image-head-phone-hover {
	.svg-image-head-phone {
		@include svg-pos-image-head-phone-hover
	}
}

.svg-image-head-phone:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-head-phone-dims {
	width: 22px;
	height: 22px;
}

.svg-image-heart {
	@extend %svg-common;
	background-position: 85.21126760563381% 11.330049261083744%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-heart {
	background-position: 85.21126760563381% 11.330049261083744%;
}
@mixin svg-image-heart-hover {
	.svg-image-heart {
		@include svg-pos-image-heart-hover
	}
}

.svg-image-heart:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/24)*100%;
}

.svg-image-heart-dims {
	width: 24px;
	height: 23px;
}

.svg-image-heart-g {
	@extend %svg-common;
	background-position: 30.232558139534884% 91.44254278728606%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-heart-g {
	background-position: 30.232558139534884% 91.44254278728606%;
}
@mixin svg-image-heart-g-hover {
	.svg-image-heart-g {
		@include svg-pos-image-heart-g-hover
	}
}

.svg-image-heart-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-heart-g-dims {
	width: 20px;
	height: 20px;
}

.svg-image-heart-g-hover {
	@extend %svg-common;
	background-position: 34.883720930232556% 91.44254278728606%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-heart-g-hover {
	background-position: 34.883720930232556% 91.44254278728606%;
}
@mixin svg-image-heart-g-hover-hover {
	.svg-image-heart-g-hover {
		@include svg-pos-image-heart-g-hover-hover
	}
}

.svg-image-heart-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-heart-g-hover-dims {
	width: 20px;
	height: 20px;
}

.svg-image-height {
	@extend %svg-common;
	background-position: 77.75229357798165% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-height {
	background-position: 77.75229357798165% 95.42168674698796%;
}
@mixin svg-image-height-hover {
	.svg-image-height {
		@include svg-pos-image-height-hover
	}
}

.svg-image-height:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-height-dims {
	width: 14px;
	height: 14px;
}

.svg-image-ico-pay-shares {
	@extend %svg-common;
	background-position: 78.5377358490566% 61.19402985074627%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-ico-pay-shares {
	background-position: 78.5377358490566% 61.19402985074627%;
}
@mixin svg-image-ico-pay-shares-hover {
	.svg-image-ico-pay-shares {
		@include svg-pos-image-ico-pay-shares-hover
	}
}

.svg-image-ico-pay-shares:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (27/26)*100%;
}

.svg-image-ico-pay-shares-dims {
	width: 26px;
	height: 27px;
}

.svg-image-ico-pay-ya {
	@extend %svg-common;
	background-position: 30.660377358490567% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-ico-pay-ya {
	background-position: 30.660377358490567% 80.89330024813896%;
}
@mixin svg-image-ico-pay-ya-hover {
	.svg-image-ico-pay-ya {
		@include svg-pos-image-ico-pay-ya-hover
	}
}

.svg-image-ico-pay-ya:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-ico-pay-ya-dims {
	width: 26px;
	height: 26px;
}

.svg-image-icon-payt-card_on_delivery {
	@extend %svg-common;
	background-position: 70.97560975609755% 21.91435768261965%;
	background-size: (450/40)*100% auto;
	width: 40px;

}
@mixin svg-pos-image-icon-payt-card_on_delivery {
	background-position: 70.97560975609755% 21.91435768261965%;
}
@mixin svg-image-icon-payt-card_on_delivery-hover {
	.svg-image-icon-payt-card_on_delivery {
		@include svg-pos-image-icon-payt-card_on_delivery-hover
	}
}

.svg-image-icon-payt-card_on_delivery:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/40)*100%;
}

.svg-image-icon-payt-card_on_delivery-dims {
	width: 40px;
	height: 32px;
}

.svg-image-icon-payt-cash {
	@extend %svg-common;
	background-position: 70.97560975609755% 29.974811083123427%;
	background-size: (450/40)*100% auto;
	width: 40px;

}
@mixin svg-pos-image-icon-payt-cash {
	background-position: 70.97560975609755% 29.974811083123427%;
}
@mixin svg-image-icon-payt-cash-hover {
	.svg-image-icon-payt-cash {
		@include svg-pos-image-icon-payt-cash-hover
	}
}

.svg-image-icon-payt-cash:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/40)*100%;
}

.svg-image-icon-payt-cash-dims {
	width: 40px;
	height: 32px;
}

.svg-image-icon-payt-dolyame {
	@extend %svg-common;
	background-position: 0 74.05541561712846%;
	background-size: (450/32)*100% auto;
	width: 32px;

}
@mixin svg-pos-image-icon-payt-dolyame {
	background-position: 0 74.05541561712846%;
}
@mixin svg-image-icon-payt-dolyame-hover {
	.svg-image-icon-payt-dolyame {
		@include svg-pos-image-icon-payt-dolyame-hover
	}
}

.svg-image-icon-payt-dolyame:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/32)*100%;
}

.svg-image-icon-payt-dolyame-dims {
	width: 32px;
	height: 32px;
}

.svg-image-icon-payt-online {
	@extend %svg-common;
	background-position: 70.97560975609755% 38.0352644836272%;
	background-size: (450/40)*100% auto;
	width: 40px;

}
@mixin svg-pos-image-icon-payt-online {
	background-position: 70.97560975609755% 38.0352644836272%;
}
@mixin svg-image-icon-payt-online-hover {
	.svg-image-icon-payt-online {
		@include svg-pos-image-icon-payt-online-hover
	}
}

.svg-image-icon-payt-online:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/40)*100%;
}

.svg-image-icon-payt-online-dims {
	width: 40px;
	height: 32px;
}

.svg-image-icon-payt-sfp {
	@extend %svg-common;
	background-position: 7.655502392344498% 74.05541561712846%;
	background-size: (450/32)*100% auto;
	width: 32px;

}
@mixin svg-pos-image-icon-payt-sfp {
	background-position: 7.655502392344498% 74.05541561712846%;
}
@mixin svg-image-icon-payt-sfp-hover {
	.svg-image-icon-payt-sfp {
		@include svg-pos-image-icon-payt-sfp-hover
	}
}

.svg-image-icon-payt-sfp:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/32)*100%;
}

.svg-image-icon-payt-sfp-dims {
	width: 32px;
	height: 32px;
}

.svg-image-icon-payt-split {
	@extend %svg-common;
	background-position: 40.74074074074074% 7.352941176470588%;
	background-size: (450/72)*100% auto;
	width: 72px;

}
@mixin svg-pos-image-icon-payt-split {
	background-position: 40.74074074074074% 7.352941176470588%;
}
@mixin svg-image-icon-payt-split-hover {
	.svg-image-icon-payt-split {
		@include svg-pos-image-icon-payt-split-hover
	}
}

.svg-image-icon-payt-split:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (21/72)*100%;
}

.svg-image-icon-payt-split-dims {
	width: 72px;
	height: 21px;
}

.svg-image-icon-payt-split-mob {
	@extend %svg-common;
	background-position: 36.53846153846154% 63.84039900249377%;
	background-size: (450/34)*100% auto;
	width: 34px;

}
@mixin svg-pos-image-icon-payt-split-mob {
	background-position: 36.53846153846154% 63.84039900249377%;
}
@mixin svg-image-icon-payt-split-mob-hover {
	.svg-image-icon-payt-split-mob {
		@include svg-pos-image-icon-payt-split-mob-hover
	}
}

.svg-image-icon-payt-split-mob:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (28/34)*100%;
}

.svg-image-icon-payt-split-mob-dims {
	width: 34px;
	height: 28px;
}

.svg-image-icon-payt-tinkoffcredit {
	@extend %svg-common;
	background-position: 15.311004784688995% 74.05541561712846%;
	background-size: (450/32)*100% auto;
	width: 32px;

}
@mixin svg-pos-image-icon-payt-tinkoffcredit {
	background-position: 15.311004784688995% 74.05541561712846%;
}
@mixin svg-image-icon-payt-tinkoffcredit-hover {
	.svg-image-icon-payt-tinkoffcredit {
		@include svg-pos-image-icon-payt-tinkoffcredit-hover
	}
}

.svg-image-icon-payt-tinkoffcredit:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/32)*100%;
}

.svg-image-icon-payt-tinkoffcredit-dims {
	width: 32px;
	height: 32px;
}

.svg-image-icon-payt-yandex {
	@extend %svg-common;
	background-position: 41.62162162162162% 0;
	background-size: (450/80)*100% auto;
	width: 80px;

}
@mixin svg-pos-image-icon-payt-yandex {
	background-position: 41.62162162162162% 0;
}
@mixin svg-image-icon-payt-yandex-hover {
	.svg-image-icon-payt-yandex {
		@include svg-pos-image-icon-payt-yandex-hover
	}
}

.svg-image-icon-payt-yandex:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/80)*100%;
}

.svg-image-icon-payt-yandex-dims {
	width: 80px;
	height: 30px;
}

.svg-image-info {
	@extend %svg-common;
	background-position: 34.80278422273782% 85.64476885644768%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-info {
	background-position: 34.80278422273782% 85.64476885644768%;
}
@mixin svg-image-info-hover {
	.svg-image-info {
		@include svg-pos-image-info-hover
	}
}

.svg-image-info:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/19)*100%;
}

.svg-image-info-dims {
	width: 19px;
	height: 18px;
}

.svg-image-info-blue {
	@extend %svg-common;
	background-position: 95.79439252336448% 10.81081081081081%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-info-blue {
	background-position: 95.79439252336448% 10.81081081081081%;
}
@mixin svg-image-info-blue-hover {
	.svg-image-info-blue {
		@include svg-pos-image-info-blue-hover
	}
}

.svg-image-info-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-info-blue-dims {
	width: 22px;
	height: 22px;
}

.svg-image-info-pay {
	@extend %svg-common;
	background-position: 78.72340425531915% 67.74193548387096%;
	background-size: (450/27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-info-pay {
	background-position: 78.72340425531915% 67.74193548387096%;
}
@mixin svg-image-info-pay-hover {
	.svg-image-info-pay {
		@include svg-pos-image-info-pay-hover
	}
}

.svg-image-info-pay:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/27)*100%;
}

.svg-image-info-pay-dims {
	width: 27px;
	height: 26px;
}

.svg-image-insta-b {
	@extend %svg-common;
	background-position: 76.62037037037037% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-insta-b {
	background-position: 76.62037037037037% 85.64476885644768%;
}
@mixin svg-image-insta-b-hover {
	.svg-image-insta-b {
		@include svg-pos-image-insta-b-hover
	}
}

.svg-image-insta-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-insta-b-dims {
	width: 18px;
	height: 18px;
}

.svg-image-insta-b-hover {
	@extend %svg-common;
	background-position: 80.78703703703704% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-insta-b-hover {
	background-position: 80.78703703703704% 85.64476885644768%;
}
@mixin svg-image-insta-b-hover-hover {
	.svg-image-insta-b-hover {
		@include svg-pos-image-insta-b-hover-hover
	}
}

.svg-image-insta-b-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-insta-b-hover-dims {
	width: 18px;
	height: 18px;
}

.svg-image-insta-g {
	@extend %svg-common;
	background-position: 84.95370370370371% 85.64476885644768%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-insta-g {
	background-position: 84.95370370370371% 85.64476885644768%;
}
@mixin svg-image-insta-g-hover {
	.svg-image-insta-g {
		@include svg-pos-image-insta-g-hover
	}
}

.svg-image-insta-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-insta-g-dims {
	width: 18px;
	height: 18px;
}

.svg-image-insta-g-hover {
	@extend %svg-common;
	background-position: 4.398148148148148% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-insta-g-hover {
	background-position: 4.398148148148148% 96.35036496350365%;
}
@mixin svg-image-insta-g-hover-hover {
	.svg-image-insta-g-hover {
		@include svg-pos-image-insta-g-hover-hover
	}
}

.svg-image-insta-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-insta-g-hover-dims {
	width: 18px;
	height: 18px;
}

.svg-image-insta-rg {
	@extend %svg-common;
	background-position: 0 54.35356200527705%;
	background-size: (450/50)*100% auto;
	width: 50px;

}
@mixin svg-pos-image-insta-rg {
	background-position: 0 54.35356200527705%;
}
@mixin svg-image-insta-rg-hover {
	.svg-image-insta-rg {
		@include svg-pos-image-insta-rg-hover
	}
}

.svg-image-insta-rg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (50/50)*100%;
}

.svg-image-insta-rg-dims {
	width: 50px;
	height: 50px;
}

.svg-image-invisible {
	@extend %svg-common;
	background-position: 36.79245283018868% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-invisible {
	background-position: 36.79245283018868% 80.89330024813896%;
}
@mixin svg-image-invisible-hover {
	.svg-image-invisible {
		@include svg-pos-image-invisible-hover
	}
}

.svg-image-invisible:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-invisible-dims {
	width: 26px;
	height: 26px;
}

.svg-image-invisible-hover {
	@extend %svg-common;
	background-position: 42.924528301886795% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-invisible-hover {
	background-position: 42.924528301886795% 80.89330024813896%;
}
@mixin svg-image-invisible-hover-hover {
	.svg-image-invisible-hover {
		@include svg-pos-image-invisible-hover-hover
	}
}

.svg-image-invisible-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-invisible-hover-dims {
	width: 26px;
	height: 26px;
}

.svg-image-ladder {
	@extend %svg-common;
	background-position: 95.79439252336448% 16.13691931540342%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-ladder {
	background-position: 95.79439252336448% 16.13691931540342%;
}
@mixin svg-image-ladder-hover {
	.svg-image-ladder {
		@include svg-pos-image-ladder-hover
	}
}

.svg-image-ladder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/22)*100%;
}

.svg-image-ladder-dims {
	width: 22px;
	height: 20px;
}

.svg-image-lightning {
	@extend %svg-common;
	background-position: 95.79439252336448% 21.13022113022113%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-lightning {
	background-position: 95.79439252336448% 21.13022113022113%;
}
@mixin svg-image-lightning-hover {
	.svg-image-lightning {
		@include svg-pos-image-lightning-hover
	}
}

.svg-image-lightning:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-lightning-dims {
	width: 22px;
	height: 22px;
}

.svg-image-like {
	@extend %svg-common;
	background-position: 49.056603773584904% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-like {
	background-position: 49.056603773584904% 80.89330024813896%;
}
@mixin svg-image-like-hover {
	.svg-image-like {
		@include svg-pos-image-like-hover
	}
}

.svg-image-like:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-like-dims {
	width: 26px;
	height: 26px;
}

.svg-image-like-b {
	@extend %svg-common;
	background-position: 95.79439252336448% 26.535626535626534%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-like-b {
	background-position: 95.79439252336448% 26.535626535626534%;
}
@mixin svg-image-like-b-hover {
	.svg-image-like-b {
		@include svg-pos-image-like-b-hover
	}
}

.svg-image-like-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-like-b-dims {
	width: 22px;
	height: 22px;
}

.svg-image-like-dislike {
	@extend %svg-common;
	background-position: 32.57403189066059% 29.807692307692307%;
	background-size: (450/11)*100% auto;
	width: 11px;

}
@mixin svg-pos-image-like-dislike {
	background-position: 32.57403189066059% 29.807692307692307%;
}
@mixin svg-image-like-dislike-hover {
	.svg-image-like-dislike {
		@include svg-pos-image-like-dislike-hover
	}
}

.svg-image-like-dislike:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (13/11)*100%;
}

.svg-image-like-dislike-dims {
	width: 11px;
	height: 13px;
}

.svg-image-like-dislike-new {
	@extend %svg-common;
	background-position: 84.61538461538461% 71.92118226600985%;
	background-size: (450/21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-like-dislike-new {
	background-position: 84.61538461538461% 71.92118226600985%;
}
@mixin svg-image-like-dislike-new-hover {
	.svg-image-like-dislike-new {
		@include svg-pos-image-like-dislike-new-hover
	}
}

.svg-image-like-dislike-new:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/21)*100%;
}

.svg-image-like-dislike-new-dims {
	width: 21px;
	height: 23px;
}

.svg-image-like-new {
	@extend %svg-common;
	background-position: 39.443155452436194% 91.44254278728606%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-like-new {
	background-position: 39.443155452436194% 91.44254278728606%;
}
@mixin svg-image-like-new-hover {
	.svg-image-like-new {
		@include svg-pos-image-like-new-hover
	}
}

.svg-image-like-new:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/19)*100%;
}

.svg-image-like-new-dims {
	width: 19px;
	height: 20px;
}

.svg-image-link {
	@extend %svg-common;
	background-position: 8.564814814814815% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-link {
	background-position: 8.564814814814815% 96.35036496350365%;
}
@mixin svg-image-link-hover {
	.svg-image-link {
		@include svg-pos-image-link-hover
	}
}

.svg-image-link:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-link-dims {
	width: 18px;
	height: 18px;
}

.svg-image-link-b {
	@extend %svg-common;
	background-position: 12.731481481481481% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-link-b {
	background-position: 12.731481481481481% 96.35036496350365%;
}
@mixin svg-image-link-b-hover {
	.svg-image-link-b {
		@include svg-pos-image-link-b-hover
	}
}

.svg-image-link-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-link-b-dims {
	width: 18px;
	height: 18px;
}

.svg-image-link-b-hover {
	@extend %svg-common;
	background-position: 16.89814814814815% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-link-b-hover {
	background-position: 16.89814814814815% 96.35036496350365%;
}
@mixin svg-image-link-b-hover-hover {
	.svg-image-link-b-hover {
		@include svg-pos-image-link-b-hover-hover
	}
}

.svg-image-link-b-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-link-b-hover-dims {
	width: 18px;
	height: 18px;
}

.svg-image-link-hover {
	@extend %svg-common;
	background-position: 21.064814814814813% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-link-hover {
	background-position: 21.064814814814813% 96.35036496350365%;
}
@mixin svg-image-link-hover-hover {
	.svg-image-link-hover {
		@include svg-pos-image-link-hover-hover
	}
}

.svg-image-link-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-link-hover-dims {
	width: 18px;
	height: 18px;
}

.svg-image-link-remove {
	@extend %svg-common;
	background-position: 57.077625570776256% 58.513189448441246%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-link-remove {
	background-position: 57.077625570776256% 58.513189448441246%;
}
@mixin svg-image-link-remove-hover {
	.svg-image-link-remove {
		@include svg-pos-image-link-remove-hover
	}
}

.svg-image-link-remove:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/12)*100%;
}

.svg-image-link-remove-dims {
	width: 12px;
	height: 12px;
}

.svg-image-link-search {
	@extend %svg-common;
	background-position: 43.95348837209303% 91.44254278728606%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-link-search {
	background-position: 43.95348837209303% 91.44254278728606%;
}
@mixin svg-image-link-search-hover {
	.svg-image-link-search {
		@include svg-pos-image-link-search-hover
	}
}

.svg-image-link-search:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-link-search-dims {
	width: 20px;
	height: 20px;
}

.svg-image-location {
	@extend %svg-common;
	background-position: 99.3103448275862% 81.15942028985508%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-location {
	background-position: 99.3103448275862% 81.15942028985508%;
}
@mixin svg-image-location-hover {
	.svg-image-location {
		@include svg-pos-image-location-hover
	}
}

.svg-image-location:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/15)*100%;
}

.svg-image-location-dims {
	width: 15px;
	height: 15px;
}

.svg-image-location-o {
	@extend %svg-common;
	background-position: 83.33333333333333% 91.21951219512195%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-location-o {
	background-position: 83.33333333333333% 91.21951219512195%;
}
@mixin svg-image-location-o-hover {
	.svg-image-location-o {
		@include svg-pos-image-location-o-hover
	}
}

.svg-image-location-o:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/18)*100%;
}

.svg-image-location-o-dims {
	width: 18px;
	height: 19px;
}

.svg-image-logo {
	@extend %svg-common;
	background-position: 0 0;
	background-size: (450/154)*100% auto;
	width: 154px;

}
@mixin svg-pos-image-logo {
	background-position: 0 0;
}
@mixin svg-image-logo-hover {
	.svg-image-logo {
		@include svg-pos-image-logo-hover
	}
}

.svg-image-logo:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (40/154)*100%;
}

.svg-image-logo-dims {
	width: 154px;
	height: 40px;
}

.svg-image-logout {
	@extend %svg-common;
	background-position: 21.3953488372093% 85.64476885644768%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-logout {
	background-position: 21.3953488372093% 85.64476885644768%;
}
@mixin svg-image-logout-hover {
	.svg-image-logout {
		@include svg-pos-image-logout-hover
	}
}

.svg-image-logout:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/20)*100%;
}

.svg-image-logout-dims {
	width: 20px;
	height: 18px;
}

.svg-image-marker {
	@extend %svg-common;
	background-position: 48.379629629629626% 91.44254278728606%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-marker {
	background-position: 48.379629629629626% 91.44254278728606%;
}
@mixin svg-image-marker-hover {
	.svg-image-marker {
		@include svg-pos-image-marker-hover
	}
}

.svg-image-marker:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/18)*100%;
}

.svg-image-marker-dims {
	width: 18px;
	height: 20px;
}

.svg-image-marker-o {
	@extend %svg-common;
	background-position: 52.5462962962963% 91.44254278728606%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-marker-o {
	background-position: 52.5462962962963% 91.44254278728606%;
}
@mixin svg-image-marker-o-hover {
	.svg-image-marker-o {
		@include svg-pos-image-marker-o-hover
	}
}

.svg-image-marker-o:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/18)*100%;
}

.svg-image-marker-o-dims {
	width: 18px;
	height: 20px;
}

.svg-image-mastercard {
	@extend %svg-common;
	background-position: 78.72340425531915% 72.74939172749392%;
	background-size: (450/27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-mastercard {
	background-position: 78.72340425531915% 72.74939172749392%;
}
@mixin svg-image-mastercard-hover {
	.svg-image-mastercard {
		@include svg-pos-image-mastercard-hover
	}
}

.svg-image-mastercard:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/27)*100%;
}

.svg-image-mastercard-dims {
	width: 27px;
	height: 18px;
}

.svg-image-minus {
	@extend %svg-common;
	background-position: 99.53917050691244% 39.05882352941177%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-minus {
	background-position: 99.53917050691244% 39.05882352941177%;
}
@mixin svg-image-minus-hover {
	.svg-image-minus {
		@include svg-pos-image-minus-hover
	}
}

.svg-image-minus:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (4/16)*100%;
}

.svg-image-minus-dims {
	width: 16px;
	height: 4px;
}

.svg-image-mir {
	@extend %svg-common;
	background-position: 71.14914425427872% 10.120481927710843%;
	background-size: (450/41)*100% auto;
	width: 41px;

}
@mixin svg-pos-image-mir {
	background-position: 71.14914425427872% 10.120481927710843%;
}
@mixin svg-image-mir-hover {
	.svg-image-mir {
		@include svg-pos-image-mir-hover
	}
}

.svg-image-mir:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/41)*100%;
}

.svg-image-mir-dims {
	width: 41px;
	height: 14px;
}

.svg-image-next {
	@extend %svg-common;
	background-position: 98.40546697038724% 16.262135922330096%;
	background-size: (450/11)*100% auto;
	width: 11px;

}
@mixin svg-pos-image-next {
	background-position: 98.40546697038724% 16.262135922330096%;
}
@mixin svg-image-next-hover {
	.svg-image-next {
		@include svg-pos-image-next-hover
	}
}

.svg-image-next:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/11)*100%;
}

.svg-image-next-dims {
	width: 11px;
	height: 17px;
}

.svg-image-next-lg {
	@extend %svg-common;
	background-position: 48.50574712643678% 12.623762376237623%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-next-lg {
	background-position: 48.50574712643678% 12.623762376237623%;
}
@mixin svg-image-next-lg-hover {
	.svg-image-next-lg {
		@include svg-pos-image-next-lg-hover
	}
}

.svg-image-next-lg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (25/15)*100%;
}

.svg-image-next-lg-dims {
	width: 15px;
	height: 25px;
}

.svg-image-next-sm {
	@extend %svg-common;
	background-position: 98.18181818181819% 84.78260869565217%;
	background-size: (450/10)*100% auto;
	width: 10px;

}
@mixin svg-pos-image-next-sm {
	background-position: 98.18181818181819% 84.78260869565217%;
}
@mixin svg-image-next-sm-hover {
	.svg-image-next-sm {
		@include svg-pos-image-next-sm-hover
	}
}

.svg-image-next-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/10)*100%;
}

.svg-image-next-sm-dims {
	width: 10px;
	height: 15px;
}

.svg-image-next-sm-hover {
	@extend %svg-common;
	background-position: 98.18181818181819% 88.40579710144928%;
	background-size: (450/10)*100% auto;
	width: 10px;

}
@mixin svg-pos-image-next-sm-hover {
	background-position: 98.18181818181819% 88.40579710144928%;
}
@mixin svg-image-next-sm-hover-hover {
	.svg-image-next-sm-hover {
		@include svg-pos-image-next-sm-hover-hover
	}
}

.svg-image-next-sm-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/10)*100%;
}

.svg-image-next-sm-hover-dims {
	width: 10px;
	height: 15px;
}

.svg-image-next-sm-w {
	@extend %svg-common;
	background-position: 90.9297052154195% 47.95180722891566%;
	background-size: (450/9)*100% auto;
	width: 9px;

}
@mixin svg-pos-image-next-sm-w {
	background-position: 90.9297052154195% 47.95180722891566%;
}
@mixin svg-image-next-sm-w-hover {
	.svg-image-next-sm-w {
		@include svg-pos-image-next-sm-w-hover
	}
}

.svg-image-next-sm-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/9)*100%;
}

.svg-image-next-sm-w-dims {
	width: 9px;
	height: 14px;
}

.svg-image-next-xs {
	@extend %svg-common;
	background-position: 100% 83.97129186602871%;
	background-size: (450/8)*100% auto;
	width: 8px;

}
@mixin svg-pos-image-next-xs {
	background-position: 100% 83.97129186602871%;
}
@mixin svg-image-next-xs-hover {
	.svg-image-next-xs {
		@include svg-pos-image-next-xs-hover
	}
}

.svg-image-next-xs:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (11/8)*100%;
}

.svg-image-next-xs-dims {
	width: 8px;
	height: 11px;
}

.svg-image-note-added {
	@extend %svg-common;
	background-position: 25.23148148148148% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-note-added {
	background-position: 25.23148148148148% 96.35036496350365%;
}
@mixin svg-image-note-added-hover {
	.svg-image-note-added {
		@include svg-pos-image-note-added-hover
	}
}

.svg-image-note-added:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-note-added-dims {
	width: 18px;
	height: 18px;
}

.svg-image-note-callback {
	@extend %svg-common;
	background-position: 60.23809523809524% 73.6842105263158%;
	background-size: (450/30)*100% auto;
	width: 30px;

}
@mixin svg-pos-image-note-callback {
	background-position: 60.23809523809524% 73.6842105263158%;
}
@mixin svg-image-note-callback-hover {
	.svg-image-note-callback {
		@include svg-pos-image-note-callback-hover
	}
}

.svg-image-note-callback:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-note-callback-dims {
	width: 30px;
	height: 30px;
}

.svg-image-note-copy {
	@extend %svg-common;
	background-position: 67.38095238095238% 73.6842105263158%;
	background-size: (450/30)*100% auto;
	width: 30px;

}
@mixin svg-pos-image-note-copy {
	background-position: 67.38095238095238% 73.6842105263158%;
}
@mixin svg-image-note-copy-hover {
	.svg-image-note-copy {
		@include svg-pos-image-note-copy-hover
	}
}

.svg-image-note-copy:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-note-copy-dims {
	width: 30px;
	height: 30px;
}

.svg-image-note-removed {
	@extend %svg-common;
	background-position: 90.57471264367815% 90.99756690997567%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-note-removed {
	background-position: 90.57471264367815% 90.99756690997567%;
}
@mixin svg-image-note-removed-hover {
	.svg-image-note-removed {
		@include svg-pos-image-note-removed-hover
	}
}

.svg-image-note-removed:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/15)*100%;
}

.svg-image-note-removed-dims {
	width: 15px;
	height: 18px;
}

.svg-image-note-subscribed {
	@extend %svg-common;
	background-position: 79.28571428571429% 0;
	background-size: (450/30)*100% auto;
	width: 30px;

}
@mixin svg-pos-image-note-subscribed {
	background-position: 79.28571428571429% 0;
}
@mixin svg-image-note-subscribed-hover {
	.svg-image-note-subscribed {
		@include svg-pos-image-note-subscribed-hover
	}
}

.svg-image-note-subscribed:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-note-subscribed-dims {
	width: 30px;
	height: 30px;
}

.svg-image-note-times {
	@extend %svg-common;
	background-position: 79.28571428571429% 7.518796992481203%;
	background-size: (450/30)*100% auto;
	width: 30px;

}
@mixin svg-pos-image-note-times {
	background-position: 79.28571428571429% 7.518796992481203%;
}
@mixin svg-image-note-times-hover {
	.svg-image-note-times {
		@include svg-pos-image-note-times-hover
	}
}

.svg-image-note-times:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-note-times-dims {
	width: 30px;
	height: 30px;
}

.svg-image-ok-b {
	@extend %svg-common;
	background-position: 85.01170960187353% 77.39557739557739%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-ok-b {
	background-position: 85.01170960187353% 77.39557739557739%;
}
@mixin svg-image-ok-b-hover {
	.svg-image-ok-b {
		@include svg-pos-image-ok-b-hover
	}
}

.svg-image-ok-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-ok-b-dims {
	width: 23px;
	height: 22px;
}

.svg-image-ok-b-hover {
	@extend %svg-common;
	background-position: 49.414519906323186% 18.673218673218674%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-ok-b-hover {
	background-position: 49.414519906323186% 18.673218673218674%;
}
@mixin svg-image-ok-b-hover-hover {
	.svg-image-ok-b-hover {
		@include svg-pos-image-ok-b-hover-hover
	}
}

.svg-image-ok-b-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-ok-b-hover-dims {
	width: 23px;
	height: 22px;
}

.svg-image-ok-g {
	@extend %svg-common;
	background-position: 30.679156908665107% 16.953316953316953%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-ok-g {
	background-position: 30.679156908665107% 16.953316953316953%;
}
@mixin svg-image-ok-g-hover {
	.svg-image-ok-g {
		@include svg-pos-image-ok-g-hover
	}
}

.svg-image-ok-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-ok-g-dims {
	width: 23px;
	height: 22px;
}

.svg-image-ok-g-hover {
	@extend %svg-common;
	background-position: 0 86.48648648648648%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-ok-g-hover {
	background-position: 0 86.48648648648648%;
}
@mixin svg-image-ok-g-hover-hover {
	.svg-image-ok-g-hover {
		@include svg-pos-image-ok-g-hover-hover
	}
}

.svg-image-ok-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-ok-g-hover-dims {
	width: 23px;
	height: 22px;
}

.svg-image-ok-rg {
	@extend %svg-common;
	background-position: 12.5% 54.35356200527705%;
	background-size: (450/50)*100% auto;
	width: 50px;

}
@mixin svg-pos-image-ok-rg {
	background-position: 12.5% 54.35356200527705%;
}
@mixin svg-image-ok-rg-hover {
	.svg-image-ok-rg {
		@include svg-pos-image-ok-rg-hover
	}
}

.svg-image-ok-rg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (50/50)*100%;
}

.svg-image-ok-rg-dims {
	width: 50px;
	height: 50px;
}

.svg-image-play {
	@extend %svg-common;
	background-position: 72.79069767441861% 72.41379310344827%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-play {
	background-position: 72.79069767441861% 72.41379310344827%;
}
@mixin svg-image-play-hover {
	.svg-image-play {
		@include svg-pos-image-play-hover
	}
}

.svg-image-play:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/20)*100%;
}

.svg-image-play-dims {
	width: 20px;
	height: 23px;
}

.svg-image-plus {
	@extend %svg-common;
	background-position: 99.53917050691244% 41.16222760290557%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-plus {
	background-position: 99.53917050691244% 41.16222760290557%;
}
@mixin svg-image-plus-hover {
	.svg-image-plus {
		@include svg-pos-image-plus-hover
	}
}

.svg-image-plus:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/16)*100%;
}

.svg-image-plus-dims {
	width: 16px;
	height: 16px;
}

.svg-image-plus-circle {
	@extend %svg-common;
	background-position: 9.223300970873787% 65.47314578005115%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-plus-circle {
	background-position: 9.223300970873787% 65.47314578005115%;
}
@mixin svg-image-plus-circle-hover {
	.svg-image-plus-circle {
		@include svg-pos-image-plus-circle-hover
	}
}

.svg-image-plus-circle:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (38/38)*100%;
}

.svg-image-plus-circle-dims {
	width: 38px;
	height: 38px;
}

.svg-image-polyester {
	@extend %svg-common;
	background-position: 99.08256880733946% 92.02898550724638%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-polyester {
	background-position: 99.08256880733946% 92.02898550724638%;
}
@mixin svg-image-polyester-hover {
	.svg-image-polyester {
		@include svg-pos-image-polyester-hover
	}
}

.svg-image-polyester:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-polyester-dims {
	width: 14px;
	height: 15px;
}

.svg-image-present {
	@extend %svg-common;
	background-position: 29.39814814814815% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-present {
	background-position: 29.39814814814815% 96.35036496350365%;
}
@mixin svg-image-present-hover {
	.svg-image-present {
		@include svg-pos-image-present-hover
	}
}

.svg-image-present:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-present-dims {
	width: 18px;
	height: 18px;
}

.svg-image-present-cart {
	@extend %svg-common;
	background-position: 33.56481481481482% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-present-cart {
	background-position: 33.56481481481482% 96.35036496350365%;
}
@mixin svg-image-present-cart-hover {
	.svg-image-present-cart {
		@include svg-pos-image-present-cart-hover
	}
}

.svg-image-present-cart:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-present-cart-dims {
	width: 18px;
	height: 18px;
}

.svg-image-product-cart-box {
	@extend %svg-common;
	background-position: 80.96330275229357% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-product-cart-box {
	background-position: 80.96330275229357% 95.42168674698796%;
}
@mixin svg-image-product-cart-box-hover {
	.svg-image-product-cart-box {
		@include svg-pos-image-product-cart-box-hover
	}
}

.svg-image-product-cart-box:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-product-cart-box-dims {
	width: 14px;
	height: 14px;
}

.svg-image-product-cart-case {
	@extend %svg-common;
	background-position: 84.1743119266055% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-product-cart-case {
	background-position: 84.1743119266055% 95.42168674698796%;
}
@mixin svg-image-product-cart-case-hover {
	.svg-image-product-cart-case {
		@include svg-pos-image-product-cart-case-hover
	}
}

.svg-image-product-cart-case:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-product-cart-case-dims {
	width: 14px;
	height: 14px;
}

.svg-image-product-cart-dumbbell {
	@extend %svg-common;
	background-position: 99.08256880733946% 95.65217391304348%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-product-cart-dumbbell {
	background-position: 99.08256880733946% 95.65217391304348%;
}
@mixin svg-image-product-cart-dumbbell-hover {
	.svg-image-product-cart-dumbbell {
		@include svg-pos-image-product-cart-dumbbell-hover
	}
}

.svg-image-product-cart-dumbbell:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-product-cart-dumbbell-dims {
	width: 14px;
	height: 15px;
}

.svg-image-product-cart-dumbbell-new {
	@extend %svg-common;
	background-position: 87.38532110091744% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-product-cart-dumbbell-new {
	background-position: 87.38532110091744% 95.42168674698796%;
}
@mixin svg-image-product-cart-dumbbell-new-hover {
	.svg-image-product-cart-dumbbell-new {
		@include svg-pos-image-product-cart-dumbbell-new-hover
	}
}

.svg-image-product-cart-dumbbell-new:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-product-cart-dumbbell-new-dims {
	width: 14px;
	height: 14px;
}

.svg-image-product-cart-mattress {
	@extend %svg-common;
	background-position: 90.59633027522936% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-product-cart-mattress {
	background-position: 90.59633027522936% 95.42168674698796%;
}
@mixin svg-image-product-cart-mattress-hover {
	.svg-image-product-cart-mattress {
		@include svg-pos-image-product-cart-mattress-hover
	}
}

.svg-image-product-cart-mattress:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-product-cart-mattress-dims {
	width: 14px;
	height: 14px;
}

.svg-image-product-cart-spring {
	@extend %svg-common;
	background-position: 48.62385321100918% 95.65217391304348%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-product-cart-spring {
	background-position: 48.62385321100918% 95.65217391304348%;
}
@mixin svg-image-product-cart-spring-hover {
	.svg-image-product-cart-spring {
		@include svg-pos-image-product-cart-spring-hover
	}
}

.svg-image-product-cart-spring:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-product-cart-spring-dims {
	width: 14px;
	height: 15px;
}

.svg-image-product-new-360 {
	@extend %svg-common;
	background-position: 95.79439252336448% 31.94103194103194%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-product-new-360 {
	background-position: 95.79439252336448% 31.94103194103194%;
}
@mixin svg-image-product-new-360-hover {
	.svg-image-product-new-360 {
		@include svg-pos-image-product-new-360-hover
	}
}

.svg-image-product-new-360:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-product-new-360-dims {
	width: 22px;
	height: 22px;
}

.svg-image-product-new-card-review {
	@extend %svg-common;
	background-position: 51.8348623853211% 95.65217391304348%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-product-new-card-review {
	background-position: 51.8348623853211% 95.65217391304348%;
}
@mixin svg-image-product-new-card-review-hover {
	.svg-image-product-new-card-review {
		@include svg-pos-image-product-new-card-review-hover
	}
}

.svg-image-product-new-card-review:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-product-new-card-review-dims {
	width: 14px;
	height: 15px;
}

.svg-image-product-new-card-star {
	@extend %svg-common;
	background-position: 99.53917050691244% 45.0363196125908%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-product-new-card-star {
	background-position: 99.53917050691244% 45.0363196125908%;
}
@mixin svg-image-product-new-card-star-hover {
	.svg-image-product-new-card-star {
		@include svg-pos-image-product-new-card-star-hover
	}
}

.svg-image-product-new-card-star:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/16)*100%;
}

.svg-image-product-new-card-star-dims {
	width: 16px;
	height: 16px;
}

.svg-image-product-new-card-star-gray {
	@extend %svg-common;
	background-position: 99.53917050691244% 48.91041162227603%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-product-new-card-star-gray {
	background-position: 99.53917050691244% 48.91041162227603%;
}
@mixin svg-image-product-new-card-star-gray-hover {
	.svg-image-product-new-card-star-gray {
		@include svg-pos-image-product-new-card-star-gray-hover
	}
}

.svg-image-product-new-card-star-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/16)*100%;
}

.svg-image-product-new-card-star-gray-dims {
	width: 16px;
	height: 16px;
}

.svg-image-product-new-delivery {
	@extend %svg-common;
	background-position: 5.386416861826698% 86.48648648648648%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-product-new-delivery {
	background-position: 5.386416861826698% 86.48648648648648%;
}
@mixin svg-image-product-new-delivery-hover {
	.svg-image-product-new-delivery {
		@include svg-pos-image-product-new-delivery-hover
	}
}

.svg-image-product-new-delivery:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-product-new-delivery-dims {
	width: 23px;
	height: 22px;
}

.svg-image-product-new-return {
	@extend %svg-common;
	background-position: 10.772833723653395% 86.48648648648648%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-product-new-return {
	background-position: 10.772833723653395% 86.48648648648648%;
}
@mixin svg-image-product-new-return-hover {
	.svg-image-product-new-return {
		@include svg-pos-image-product-new-return-hover
	}
}

.svg-image-product-new-return:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-product-new-return-dims {
	width: 23px;
	height: 22px;
}

.svg-image-profile {
	@extend %svg-common;
	background-position: 95.79439252336448% 37.34643734643735%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-profile {
	background-position: 95.79439252336448% 37.34643734643735%;
}
@mixin svg-image-profile-hover {
	.svg-image-profile {
		@include svg-pos-image-profile-hover
	}
}

.svg-image-profile:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-profile-dims {
	width: 22px;
	height: 22px;
}

.svg-image-promo-close {
	@extend %svg-common;
	background-position: 93.80733944954129% 95.42168674698796%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-promo-close {
	background-position: 93.80733944954129% 95.42168674698796%;
}
@mixin svg-image-promo-close-hover {
	.svg-image-promo-close {
		@include svg-pos-image-promo-close-hover
	}
}

.svg-image-promo-close:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-promo-close-dims {
	width: 14px;
	height: 14px;
}

.svg-image-promo-ok {
	@extend %svg-common;
	background-position: 16.159250585480095% 85.64476885644768%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-promo-ok {
	background-position: 16.159250585480095% 85.64476885644768%;
}
@mixin svg-image-promo-ok-hover {
	.svg-image-promo-ok {
		@include svg-pos-image-promo-ok-hover
	}
}

.svg-image-promo-ok:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/23)*100%;
}

.svg-image-promo-ok-dims {
	width: 23px;
	height: 18px;
}

.svg-image-promo-send {
	@extend %svg-common;
	background-position: 85.21126760563381% 16.78832116788321%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-promo-send {
	background-position: 85.21126760563381% 16.78832116788321%;
}
@mixin svg-image-promo-send-hover {
	.svg-image-promo-send {
		@include svg-pos-image-promo-send-hover
	}
}

.svg-image-promo-send:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/24)*100%;
}

.svg-image-promo-send-dims {
	width: 24px;
	height: 18px;
}

.svg-image-promocode {
	@extend %svg-common;
	background-position: 78.90995260663507% 37.89731051344743%;
	background-size: (450/28)*100% auto;
	width: 28px;

}
@mixin svg-pos-image-promocode {
	background-position: 78.90995260663507% 37.89731051344743%;
}
@mixin svg-image-promocode-hover {
	.svg-image-promocode {
		@include svg-pos-image-promocode-hover
	}
}

.svg-image-promocode:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/28)*100%;
}

.svg-image-promocode-dims {
	width: 28px;
	height: 20px;
}

.svg-image-protective {
	@extend %svg-common;
	background-position: 55.04587155963303% 95.65217391304348%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-protective {
	background-position: 55.04587155963303% 95.65217391304348%;
}
@mixin svg-image-protective-hover {
	.svg-image-protective {
		@include svg-pos-image-protective-hover
	}
}

.svg-image-protective:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-protective-dims {
	width: 14px;
	height: 15px;
}

.svg-image-pure-compare {
	@extend %svg-common;
	background-position: 37.29977116704806% 96.35036496350365%;
	background-size: (450/13)*100% auto;
	width: 13px;

}
@mixin svg-pos-image-pure-compare {
	background-position: 37.29977116704806% 96.35036496350365%;
}
@mixin svg-image-pure-compare-hover {
	.svg-image-pure-compare {
		@include svg-pos-image-pure-compare-hover
	}
}

.svg-image-pure-compare:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/13)*100%;
}

.svg-image-pure-compare-dims {
	width: 13px;
	height: 18px;
}

.svg-image-pure-fav {
	@extend %svg-common;
	background-position: 95.57109557109557% 53.04136253041362%;
	background-size: (450/21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-pure-fav {
	background-position: 95.57109557109557% 53.04136253041362%;
}
@mixin svg-image-pure-fav-hover {
	.svg-image-pure-fav {
		@include svg-pos-image-pure-fav-hover
	}
}

.svg-image-pure-fav:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/21)*100%;
}

.svg-image-pure-fav-dims {
	width: 21px;
	height: 18px;
}

.svg-image-question {
	@extend %svg-common;
	background-position: 95.57109557109557% 57.84313725490196%;
	background-size: (450/21)*100% auto;
	width: 21px;

}
@mixin svg-pos-image-question {
	background-position: 95.57109557109557% 57.84313725490196%;
}
@mixin svg-image-question-hover {
	.svg-image-question {
		@include svg-pos-image-question-hover
	}
}

.svg-image-question:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (21/21)*100%;
}

.svg-image-question-dims {
	width: 21px;
	height: 21px;
}

.svg-image-question-new {
	@extend %svg-common;
	background-position: 99.53917050691244% 52.78450363196126%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-question-new {
	background-position: 99.53917050691244% 52.78450363196126%;
}
@mixin svg-image-question-new-hover {
	.svg-image-question-new {
		@include svg-pos-image-question-new-hover
	}
}

.svg-image-question-new:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/16)*100%;
}

.svg-image-question-new-dims {
	width: 16px;
	height: 16px;
}

.svg-image-quote {
	@extend %svg-common;
	background-position: 59.24050632911393% 14.177215189873417%;
	background-size: (450/55)*100% auto;
	width: 55px;

}
@mixin svg-pos-image-quote {
	background-position: 59.24050632911393% 14.177215189873417%;
}
@mixin svg-image-quote-hover {
	.svg-image-quote {
		@include svg-pos-image-quote-hover
	}
}

.svg-image-quote:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (34/55)*100%;
}

.svg-image-quote-dims {
	width: 55px;
	height: 34px;
}

.svg-image-r-minus {
	@extend %svg-common;
	background-position: 22.966507177033492% 74.05541561712846%;
	background-size: (450/32)*100% auto;
	width: 32px;

}
@mixin svg-pos-image-r-minus {
	background-position: 22.966507177033492% 74.05541561712846%;
}
@mixin svg-image-r-minus-hover {
	.svg-image-r-minus {
		@include svg-pos-image-r-minus-hover
	}
}

.svg-image-r-minus:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/32)*100%;
}

.svg-image-r-minus-dims {
	width: 32px;
	height: 32px;
}

.svg-image-r-plus {
	@extend %svg-common;
	background-position: 30.62200956937799% 74.05541561712846%;
	background-size: (450/32)*100% auto;
	width: 32px;

}
@mixin svg-pos-image-r-plus {
	background-position: 30.62200956937799% 74.05541561712846%;
}
@mixin svg-image-r-plus-hover {
	.svg-image-r-plus {
		@include svg-pos-image-r-plus-hover
	}
}

.svg-image-r-plus:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/32)*100%;
}

.svg-image-r-plus-dims {
	width: 32px;
	height: 32px;
}

.svg-image-raise {
	@extend %svg-common;
	background-position: 90.63231850117096% 0;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-raise {
	background-position: 90.63231850117096% 0;
}
@mixin svg-image-raise-hover {
	.svg-image-raise {
		@include svg-pos-image-raise-hover
	}
}

.svg-image-raise:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/23)*100%;
}

.svg-image-raise-dims {
	width: 23px;
	height: 23px;
}

.svg-image-remove-g {
	@extend %svg-common;
	background-position: 56.71296296296296% 91.44254278728606%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-remove-g {
	background-position: 56.71296296296296% 91.44254278728606%;
}
@mixin svg-image-remove-g-hover {
	.svg-image-remove-g {
		@include svg-pos-image-remove-g-hover
	}
}

.svg-image-remove-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/18)*100%;
}

.svg-image-remove-g-dims {
	width: 18px;
	height: 20px;
}

.svg-image-remove-g-hover {
	@extend %svg-common;
	background-position: 60.879629629629626% 91.44254278728606%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-remove-g-hover {
	background-position: 60.879629629629626% 91.44254278728606%;
}
@mixin svg-image-remove-g-hover-hover {
	.svg-image-remove-g-hover {
		@include svg-pos-image-remove-g-hover-hover
	}
}

.svg-image-remove-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/18)*100%;
}

.svg-image-remove-g-hover-dims {
	width: 18px;
	height: 20px;
}

.svg-image-reset {
	@extend %svg-common;
	background-position: 87.09677419354838% 91.21951219512195%;
	background-size: (450/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-reset {
	background-position: 87.09677419354838% 91.21951219512195%;
}
@mixin svg-image-reset-hover {
	.svg-image-reset {
		@include svg-pos-image-reset-hover
	}
}

.svg-image-reset:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/16)*100%;
}

.svg-image-reset-dims {
	width: 16px;
	height: 19px;
}

.svg-image-rocket {
	@extend %svg-common;
	background-position: 65.34883720930233% 91.44254278728606%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-rocket {
	background-position: 65.34883720930233% 91.44254278728606%;
}
@mixin svg-image-rocket-hover {
	.svg-image-rocket {
		@include svg-pos-image-rocket-hover
	}
}

.svg-image-rocket:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-rocket-dims {
	width: 20px;
	height: 20px;
}

.svg-image-rouble {
	@extend %svg-common;
	background-position: 95.79439252336448% 42.75184275184275%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-rouble {
	background-position: 95.79439252336448% 42.75184275184275%;
}
@mixin svg-image-rouble-hover {
	.svg-image-rouble {
		@include svg-pos-image-rouble-hover
	}
}

.svg-image-rouble:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-rouble-dims {
	width: 22px;
	height: 22px;
}

.svg-image-rub {
	@extend %svg-common;
	background-position: 99.08256880733946% 20.388349514563107%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-rub {
	background-position: 99.08256880733946% 20.388349514563107%;
}
@mixin svg-image-rub-hover {
	.svg-image-rub {
		@include svg-pos-image-rub-hover
	}
}

.svg-image-rub:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/14)*100%;
}

.svg-image-rub-dims {
	width: 14px;
	height: 17px;
}

.svg-image-rub-b {
	@extend %svg-common;
	background-position: 99.08256880733946% 24.514563106796118%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-rub-b {
	background-position: 99.08256880733946% 24.514563106796118%;
}
@mixin svg-image-rub-b-hover {
	.svg-image-rub-b {
		@include svg-pos-image-rub-b-hover
	}
}

.svg-image-rub-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/14)*100%;
}

.svg-image-rub-b-dims {
	width: 14px;
	height: 17px;
}

.svg-image-search {
	@extend %svg-common;
	background-position: 70% 91.44254278728606%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-search {
	background-position: 70% 91.44254278728606%;
}
@mixin svg-image-search-hover {
	.svg-image-search {
		@include svg-pos-image-search-hover
	}
}

.svg-image-search:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-search-dims {
	width: 20px;
	height: 20px;
}

.svg-image-search-b {
	@extend %svg-common;
	background-position: 95.79439252336448% 48.157248157248155%;
	background-size: (450/22)*100% auto;
	width: 22px;

}
@mixin svg-pos-image-search-b {
	background-position: 95.79439252336448% 48.157248157248155%;
}
@mixin svg-image-search-b-hover {
	.svg-image-search-b {
		@include svg-pos-image-search-b-hover
	}
}

.svg-image-search-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/22)*100%;
}

.svg-image-search-b-dims {
	width: 22px;
	height: 22px;
}

.svg-image-search-o {
	@extend %svg-common;
	background-position: 74.65116279069767% 91.44254278728606%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-search-o {
	background-position: 74.65116279069767% 91.44254278728606%;
}
@mixin svg-image-search-o-hover {
	.svg-image-search-o {
		@include svg-pos-image-search-o-hover
	}
}

.svg-image-search-o:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (20/20)*100%;
}

.svg-image-search-o-dims {
	width: 20px;
	height: 20px;
}

.svg-image-select {
	@extend %svg-common;
	background-position: 78.76712328767124% 75.29691211401425%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-select {
	background-position: 78.76712328767124% 75.29691211401425%;
}
@mixin svg-image-select-hover {
	.svg-image-select {
		@include svg-pos-image-select-hover
	}
}

.svg-image-select:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (8/12)*100%;
}

.svg-image-select-dims {
	width: 12px;
	height: 8px;
}

.svg-image-select-arr {
	@extend %svg-common;
	background-position: 94.25287356321839% 91.8854415274463%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-select-arr {
	background-position: 94.25287356321839% 91.8854415274463%;
}
@mixin svg-image-select-arr-hover {
	.svg-image-select-arr {
		@include svg-pos-image-select-arr-hover
	}
}

.svg-image-select-arr:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (10/15)*100%;
}

.svg-image-select-arr-dims {
	width: 15px;
	height: 10px;
}

.svg-image-select-arr-hover {
	@extend %svg-common;
	background-position: 62.98850574712644% 46.30071599045346%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-select-arr-hover {
	background-position: 62.98850574712644% 46.30071599045346%;
}
@mixin svg-image-select-arr-hover-hover {
	.svg-image-select-arr-hover {
		@include svg-pos-image-select-arr-hover-hover
	}
}

.svg-image-select-arr-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (10/15)*100%;
}

.svg-image-select-arr-hover-dims {
	width: 15px;
	height: 10px;
}

.svg-image-select-arr-sm {
	@extend %svg-common;
	background-position: 48.17351598173516% 23.27790973871734%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-select-arr-sm {
	background-position: 48.17351598173516% 23.27790973871734%;
}
@mixin svg-image-select-arr-sm-hover {
	.svg-image-select-arr-sm {
		@include svg-pos-image-select-arr-sm-hover
	}
}

.svg-image-select-arr-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (8/12)*100%;
}

.svg-image-select-arr-sm-dims {
	width: 12px;
	height: 8px;
}

.svg-image-select-arr-sm-hover {
	@extend %svg-common;
	background-position: 59.817351598173516% 57.957244655581945%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-select-arr-sm-hover {
	background-position: 59.817351598173516% 57.957244655581945%;
}
@mixin svg-image-select-arr-sm-hover-hover {
	.svg-image-select-arr-sm-hover {
		@include svg-pos-image-select-arr-sm-hover-hover
	}
}

.svg-image-select-arr-sm-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (8/12)*100%;
}

.svg-image-select-arr-sm-hover-dims {
	width: 12px;
	height: 8px;
}

.svg-image-share {
	@extend %svg-common;
	background-position: 40.74074074074074% 96.35036496350365%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-share {
	background-position: 40.74074074074074% 96.35036496350365%;
}
@mixin svg-image-share-hover {
	.svg-image-share {
		@include svg-pos-image-share-hover
	}
}

.svg-image-share:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-share-dims {
	width: 18px;
	height: 18px;
}

.svg-image-smiling-face-star {
	@extend %svg-common;
	background-position: 3.1963470319634704% 99.75961538461539%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-smiling-face-star {
	background-position: 3.1963470319634704% 99.75961538461539%;
}
@mixin svg-image-smiling-face-star-hover {
	.svg-image-smiling-face-star {
		@include svg-pos-image-smiling-face-star-hover
	}
}

.svg-image-smiling-face-star:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (13/12)*100%;
}

.svg-image-smiling-face-star-dims {
	width: 12px;
	height: 13px;
}

.svg-image-sofa {
	@extend %svg-common;
	background-position: 37.745098039215684% 27.72020725388601%;
	background-size: (450/42)*100% auto;
	width: 42px;

}
@mixin svg-pos-image-sofa {
	background-position: 37.745098039215684% 27.72020725388601%;
}
@mixin svg-image-sofa-hover {
	.svg-image-sofa {
		@include svg-pos-image-sofa-hover
	}
}

.svg-image-sofa:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (43/42)*100%;
}

.svg-image-sofa-dims {
	width: 42px;
	height: 43px;
}

.svg-image-sort-arr {
	@extend %svg-common;
	background-position: 98.8558352402746% 56.65859564164649%;
	background-size: (450/13)*100% auto;
	width: 13px;

}
@mixin svg-pos-image-sort-arr {
	background-position: 98.8558352402746% 56.65859564164649%;
}
@mixin svg-image-sort-arr-hover {
	.svg-image-sort-arr {
		@include svg-pos-image-sort-arr-hover
	}
}

.svg-image-sort-arr:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/13)*100%;
}

.svg-image-sort-arr-dims {
	width: 13px;
	height: 16px;
}

.svg-image-split-logo {
	@extend %svg-common;
	background-position: 0 30.76923076923077%;
	background-size: (450/90)*100% auto;
	width: 90px;

}
@mixin svg-pos-image-split-logo {
	background-position: 0 30.76923076923077%;
}
@mixin svg-image-split-logo-hover {
	.svg-image-split-logo {
		@include svg-pos-image-split-logo-hover
	}
}

.svg-image-split-logo:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/90)*100%;
}

.svg-image-split-logo-dims {
	width: 90px;
	height: 26px;
}

.svg-image-split-male {
	@extend %svg-common;
	background-position: 5.936073059360731% 99.52038369304556%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-split-male {
	background-position: 5.936073059360731% 99.52038369304556%;
}
@mixin svg-image-split-male-hover {
	.svg-image-split-male {
		@include svg-pos-image-split-male-hover
	}
}

.svg-image-split-male:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/12)*100%;
}

.svg-image-split-male-dims {
	width: 12px;
	height: 12px;
}

.svg-image-spring {
	@extend %svg-common;
	background-position: 0 100%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-spring {
	background-position: 0 100%;
}
@mixin svg-image-spring-hover {
	.svg-image-spring {
		@include svg-pos-image-spring-hover
	}
}

.svg-image-spring:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-spring-dims {
	width: 14px;
	height: 14px;
}

.svg-image-star {
	@extend %svg-common;
	background-position: 58.39080459770115% 95.65217391304348%;
	background-size: (450/15)*100% auto;
	width: 15px;

}
@mixin svg-pos-image-star {
	background-position: 58.39080459770115% 95.65217391304348%;
}
@mixin svg-image-star-hover {
	.svg-image-star {
		@include svg-pos-image-star-hover
	}
}

.svg-image-star:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/15)*100%;
}

.svg-image-star-dims {
	width: 15px;
	height: 15px;
}

.svg-image-star-cc {
	@extend %svg-common;
	background-position: 0 96.58536585365853%;
	background-size: (450/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-star-cc {
	background-position: 0 96.58536585365853%;
}
@mixin svg-image-star-cc-hover {
	.svg-image-star-cc {
		@include svg-pos-image-star-cc-hover
	}
}

.svg-image-star-cc:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/19)*100%;
}

.svg-image-star-cc-dims {
	width: 19px;
	height: 19px;
}

.svg-image-star-gray {
	@extend %svg-common;
	background-position: 61.69724770642202% 95.65217391304348%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-star-gray {
	background-position: 61.69724770642202% 95.65217391304348%;
}
@mixin svg-image-star-gray-hover {
	.svg-image-star-gray {
		@include svg-pos-image-star-gray-hover
	}
}

.svg-image-star-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-star-gray-dims {
	width: 14px;
	height: 15px;
}

.svg-image-star-quote {
	@extend %svg-common;
	background-position: 70.97560975609755% 47.04370179948586%;
	background-size: (450/40)*100% auto;
	width: 40px;

}
@mixin svg-pos-image-star-quote {
	background-position: 70.97560975609755% 47.04370179948586%;
}
@mixin svg-image-star-quote-hover {
	.svg-image-star-quote {
		@include svg-pos-image-star-quote-hover
	}
}

.svg-image-star-quote:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (40/40)*100%;
}

.svg-image-star-quote-dims {
	width: 40px;
	height: 40px;
}

.svg-image-star-uslugi {
	@extend %svg-common;
	background-position: 44.907407407407405% 96.11650485436893%;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-star-uslugi {
	background-position: 44.907407407407405% 96.11650485436893%;
}
@mixin svg-image-star-uslugi-hover {
	.svg-image-star-uslugi {
		@include svg-pos-image-star-uslugi-hover
	}
}

.svg-image-star-uslugi:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/18)*100%;
}

.svg-image-star-uslugi-dims {
	width: 18px;
	height: 17px;
}

.svg-image-swan {
	@extend %svg-common;
	background-position: 64.90825688073394% 95.65217391304348%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-swan {
	background-position: 64.90825688073394% 95.65217391304348%;
}
@mixin svg-image-swan-hover {
	.svg-image-swan {
		@include svg-pos-image-swan-hover
	}
}

.svg-image-swan:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-swan-dims {
	width: 14px;
	height: 15px;
}

.svg-image-telegram {
	@extend %svg-common;
	background-position: 43.97163120567376% 63.681592039801%;
	background-size: (450/27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-telegram {
	background-position: 43.97163120567376% 63.681592039801%;
}
@mixin svg-image-telegram-hover {
	.svg-image-telegram {
		@include svg-pos-image-telegram-hover
	}
}

.svg-image-telegram:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (27/27)*100%;
}

.svg-image-telegram-dims {
	width: 27px;
	height: 27px;
}

.svg-image-telegram-round {
	@extend %svg-common;
	background-position: 18.446601941747574% 65.47314578005115%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-telegram-round {
	background-position: 18.446601941747574% 65.47314578005115%;
}
@mixin svg-image-telegram-round-hover {
	.svg-image-telegram-round {
		@include svg-pos-image-telegram-round-hover
	}
}

.svg-image-telegram-round:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (38/38)*100%;
}

.svg-image-telegram-round-dims {
	width: 38px;
	height: 38px;
}

.svg-image-tg-b {
	@extend %svg-common;
	background-position: 90.63231850117096% 5.651105651105651%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-tg-b {
	background-position: 90.63231850117096% 5.651105651105651%;
}
@mixin svg-image-tg-b-hover {
	.svg-image-tg-b {
		@include svg-pos-image-tg-b-hover
	}
}

.svg-image-tg-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-tg-b-dims {
	width: 23px;
	height: 22px;
}

.svg-image-tg-b-hover {
	@extend %svg-common;
	background-position: 90.63231850117096% 11.056511056511056%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-tg-b-hover {
	background-position: 90.63231850117096% 11.056511056511056%;
}
@mixin svg-image-tg-b-hover-hover {
	.svg-image-tg-b-hover {
		@include svg-pos-image-tg-b-hover-hover
	}
}

.svg-image-tg-b-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-tg-b-hover-dims {
	width: 23px;
	height: 22px;
}

.svg-image-tg-g {
	@extend %svg-common;
	background-position: 90.63231850117096% 16.461916461916463%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-tg-g {
	background-position: 90.63231850117096% 16.461916461916463%;
}
@mixin svg-image-tg-g-hover {
	.svg-image-tg-g {
		@include svg-pos-image-tg-g-hover
	}
}

.svg-image-tg-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-tg-g-dims {
	width: 23px;
	height: 22px;
}

.svg-image-tg-g-hover {
	@extend %svg-common;
	background-position: 90.63231850117096% 21.867321867321866%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-tg-g-hover {
	background-position: 90.63231850117096% 21.867321867321866%;
}
@mixin svg-image-tg-g-hover-hover {
	.svg-image-tg-g-hover {
		@include svg-pos-image-tg-g-hover-hover
	}
}

.svg-image-tg-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-tg-g-hover-dims {
	width: 23px;
	height: 22px;
}

.svg-image-tg-rg {
	@extend %svg-common;
	background-position: 25% 54.35356200527705%;
	background-size: (450/50)*100% auto;
	width: 50px;

}
@mixin svg-pos-image-tg-rg {
	background-position: 25% 54.35356200527705%;
}
@mixin svg-image-tg-rg-hover {
	.svg-image-tg-rg {
		@include svg-pos-image-tg-rg-hover
	}
}

.svg-image-tg-rg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (50/50)*100%;
}

.svg-image-tg-rg-dims {
	width: 50px;
	height: 50px;
}

.svg-image-tgram {
	@extend %svg-common;
	background-position: 45.58472553699284% 73.86934673366834%;
	background-size: (450/31)*100% auto;
	width: 31px;

}
@mixin svg-pos-image-tgram {
	background-position: 45.58472553699284% 73.86934673366834%;
}
@mixin svg-image-tgram-hover {
	.svg-image-tgram {
		@include svg-pos-image-tgram-hover
	}
}

.svg-image-tgram:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (31/31)*100%;
}

.svg-image-tgram-dims {
	width: 31px;
	height: 31px;
}

.svg-image-timer {
	@extend %svg-common;
	background-position: 73.2394366197183% 80.6930693069307%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-timer {
	background-position: 73.2394366197183% 80.6930693069307%;
}
@mixin svg-image-timer-hover {
	.svg-image-timer {
		@include svg-pos-image-timer-hover
	}
}

.svg-image-timer:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (25/24)*100%;
}

.svg-image-timer-dims {
	width: 24px;
	height: 25px;
}

.svg-image-times {
	@extend %svg-common;
	background-position: 85.21126760563381% 21.48148148148148%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-times {
	background-position: 85.21126760563381% 21.48148148148148%;
}
@mixin svg-image-times-hover {
	.svg-image-times {
		@include svg-pos-image-times-hover
	}
}

.svg-image-times:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (24/24)*100%;
}

.svg-image-times-dims {
	width: 24px;
	height: 24px;
}

.svg-image-upload {
	@extend %svg-common;
	background-position: 55.18867924528302% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-upload {
	background-position: 55.18867924528302% 80.89330024813896%;
}
@mixin svg-image-upload-hover {
	.svg-image-upload {
		@include svg-pos-image-upload-hover
	}
}

.svg-image-upload:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-upload-dims {
	width: 26px;
	height: 26px;
}

.svg-image-upload-foto {
	@extend %svg-common;
	background-position: 85.21126760563381% 27.272727272727273%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-upload-foto {
	background-position: 85.21126760563381% 27.272727272727273%;
}
@mixin svg-image-upload-foto-hover {
	.svg-image-upload-foto {
		@include svg-pos-image-upload-foto-hover
	}
}

.svg-image-upload-foto:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/24)*100%;
}

.svg-image-upload-foto-dims {
	width: 24px;
	height: 22px;
}

.svg-image-upload-foto-w {
	@extend %svg-common;
	background-position: 85.21126760563381% 32.67813267813268%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-upload-foto-w {
	background-position: 85.21126760563381% 32.67813267813268%;
}
@mixin svg-image-upload-foto-w-hover {
	.svg-image-upload-foto-w {
		@include svg-pos-image-upload-foto-w-hover
	}
}

.svg-image-upload-foto-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/24)*100%;
}

.svg-image-upload-foto-w-dims {
	width: 24px;
	height: 22px;
}

.svg-image-upload-w {
	@extend %svg-common;
	background-position: 61.320754716981135% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-upload-w {
	background-position: 61.320754716981135% 80.89330024813896%;
}
@mixin svg-image-upload-w-hover {
	.svg-image-upload-w {
		@include svg-pos-image-upload-w-hover
	}
}

.svg-image-upload-w:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-upload-w-dims {
	width: 26px;
	height: 26px;
}

.svg-image-user {
	@extend %svg-common;
	background-position: 85.21126760563381% 38.17733990147783%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-user {
	background-position: 85.21126760563381% 38.17733990147783%;
}
@mixin svg-image-user-hover {
	.svg-image-user {
		@include svg-pos-image-user-hover
	}
}

.svg-image-user:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (23/24)*100%;
}

.svg-image-user-dims {
	width: 24px;
	height: 23px;
}

.svg-image-virt {
	@extend %svg-common;
	background-position: 85.21126760563381% 43.73464373464373%;
	background-size: (450/24)*100% auto;
	width: 24px;

}
@mixin svg-pos-image-virt {
	background-position: 85.21126760563381% 43.73464373464373%;
}
@mixin svg-image-virt-hover {
	.svg-image-virt {
		@include svg-pos-image-virt-hover
	}
}

.svg-image-virt:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/24)*100%;
}

.svg-image-virt-dims {
	width: 24px;
	height: 22px;
}

.svg-image-visa {
	@extend %svg-common;
	background-position: 26.699029126213592% 9.63855421686747%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-visa {
	background-position: 26.699029126213592% 9.63855421686747%;
}
@mixin svg-image-visa-hover {
	.svg-image-visa {
		@include svg-pos-image-visa-hover
	}
}

.svg-image-visa:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/38)*100%;
}

.svg-image-visa-dims {
	width: 38px;
	height: 14px;
}

.svg-image-vk-b {
	@extend %svg-common;
	background-position: 84.4186046511628% 80.81534772182255%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-vk-b {
	background-position: 84.4186046511628% 80.81534772182255%;
}
@mixin svg-image-vk-b-hover {
	.svg-image-vk-b {
		@include svg-pos-image-vk-b-hover
	}
}

.svg-image-vk-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/20)*100%;
}

.svg-image-vk-b-dims {
	width: 20px;
	height: 12px;
}

.svg-image-vk-b-hover {
	@extend %svg-common;
	background-position: 54.41860465116279% 46.52278177458034%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-vk-b-hover {
	background-position: 54.41860465116279% 46.52278177458034%;
}
@mixin svg-image-vk-b-hover-hover {
	.svg-image-vk-b-hover {
		@include svg-pos-image-vk-b-hover-hover
	}
}

.svg-image-vk-b-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/20)*100%;
}

.svg-image-vk-b-hover-dims {
	width: 20px;
	height: 12px;
}

.svg-image-vk-g {
	@extend %svg-common;
	background-position: 59.06976744186046% 46.52278177458034%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-vk-g {
	background-position: 59.06976744186046% 46.52278177458034%;
}
@mixin svg-image-vk-g-hover {
	.svg-image-vk-g {
		@include svg-pos-image-vk-g-hover
	}
}

.svg-image-vk-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/20)*100%;
}

.svg-image-vk-g-dims {
	width: 20px;
	height: 12px;
}

.svg-image-vk-g-hover {
	@extend %svg-common;
	background-position: 25.58139534883721% 12.949640287769784%;
	background-size: (450/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-vk-g-hover {
	background-position: 25.58139534883721% 12.949640287769784%;
}
@mixin svg-image-vk-g-hover-hover {
	.svg-image-vk-g-hover {
		@include svg-pos-image-vk-g-hover-hover
	}
}

.svg-image-vk-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/20)*100%;
}

.svg-image-vk-g-hover-dims {
	width: 20px;
	height: 12px;
}

.svg-image-vk-rg {
	@extend %svg-common;
	background-position: 37.5% 54.35356200527705%;
	background-size: (450/50)*100% auto;
	width: 50px;

}
@mixin svg-pos-image-vk-rg {
	background-position: 37.5% 54.35356200527705%;
}
@mixin svg-image-vk-rg-hover {
	.svg-image-vk-rg {
		@include svg-pos-image-vk-rg-hover
	}
}

.svg-image-vk-rg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (50/50)*100%;
}

.svg-image-vk-rg-dims {
	width: 50px;
	height: 50px;
}

.svg-image-wapp {
	@extend %svg-common;
	background-position: 52.98329355608592% 73.86934673366834%;
	background-size: (450/31)*100% auto;
	width: 31px;

}
@mixin svg-pos-image-wapp {
	background-position: 52.98329355608592% 73.86934673366834%;
}
@mixin svg-image-wapp-hover {
	.svg-image-wapp {
		@include svg-pos-image-wapp-hover
	}
}

.svg-image-wapp:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (31/31)*100%;
}

.svg-image-wapp-dims {
	width: 31px;
	height: 31px;
}

.svg-image-waterproof {
	@extend %svg-common;
	background-position: 68.11926605504587% 95.65217391304348%;
	background-size: (450/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-waterproof {
	background-position: 68.11926605504587% 95.65217391304348%;
}
@mixin svg-image-waterproof-hover {
	.svg-image-waterproof {
		@include svg-pos-image-waterproof-hover
	}
}

.svg-image-waterproof:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (15/14)*100%;
}

.svg-image-waterproof-dims {
	width: 14px;
	height: 15px;
}

.svg-image-whatsapp {
	@extend %svg-common;
	background-position: 21.27659574468085% 30.76923076923077%;
	background-size: (450/27)*100% auto;
	width: 27px;

}
@mixin svg-pos-image-whatsapp {
	background-position: 21.27659574468085% 30.76923076923077%;
}
@mixin svg-image-whatsapp-hover {
	.svg-image-whatsapp {
		@include svg-pos-image-whatsapp-hover
	}
}

.svg-image-whatsapp:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/27)*100%;
}

.svg-image-whatsapp-dims {
	width: 27px;
	height: 26px;
}

.svg-image-whatsapp-round {
	@extend %svg-common;
	background-position: 27.66990291262136% 65.47314578005115%;
	background-size: (450/38)*100% auto;
	width: 38px;

}
@mixin svg-pos-image-whatsapp-round {
	background-position: 27.66990291262136% 65.47314578005115%;
}
@mixin svg-image-whatsapp-round-hover {
	.svg-image-whatsapp-round {
		@include svg-pos-image-whatsapp-round-hover
	}
}

.svg-image-whatsapp-round:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (38/38)*100%;
}

.svg-image-whatsapp-round-dims {
	width: 38px;
	height: 38px;
}

.svg-image-wl-arrow {
	@extend %svg-common;
	background-position: 78.90995260663507% 42.68292682926829%;
	background-size: (450/28)*100% auto;
	width: 28px;

}
@mixin svg-pos-image-wl-arrow {
	background-position: 78.90995260663507% 42.68292682926829%;
}
@mixin svg-image-wl-arrow-hover {
	.svg-image-wl-arrow {
		@include svg-pos-image-wl-arrow-hover
	}
}

.svg-image-wl-arrow:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/28)*100%;
}

.svg-image-wl-arrow-dims {
	width: 28px;
	height: 19px;
}

.svg-image-x-filters {
	@extend %svg-common;
	background-position: 100% 0;
	background-size: (450/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-x-filters {
	background-position: 100% 0;
}
@mixin svg-image-x-filters-hover {
	.svg-image-x-filters {
		@include svg-pos-image-x-filters-hover
	}
}

.svg-image-x-filters:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-x-filters-dims {
	width: 18px;
	height: 18px;
}

.svg-image-ya-card {
	@extend %svg-common;
	background-position: 79.09738717339667% 24.688279301745634%;
	background-size: (450/29)*100% auto;
	width: 29px;

}
@mixin svg-pos-image-ya-card {
	background-position: 79.09738717339667% 24.688279301745634%;
}
@mixin svg-image-ya-card-hover {
	.svg-image-ya-card {
		@include svg-pos-image-ya-card-hover
	}
}

.svg-image-ya-card:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (28/29)*100%;
}

.svg-image-ya-card-dims {
	width: 29px;
	height: 28px;
}

.svg-image-ya-icon-pay {
	@extend %svg-common;
	background-position: 58.064516129032256% 38.68613138686131%;
	background-size: (450/47)*100% auto;
	width: 47px;

}
@mixin svg-pos-image-ya-icon-pay {
	background-position: 58.064516129032256% 38.68613138686131%;
}
@mixin svg-image-ya-icon-pay-hover {
	.svg-image-ya-icon-pay {
		@include svg-pos-image-ya-icon-pay-hover
	}
}

.svg-image-ya-icon-pay:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/47)*100%;
}

.svg-image-ya-icon-pay-dims {
	width: 47px;
	height: 18px;
}

.svg-image-ya-logo-plus {
	@extend %svg-common;
	background-position: 0 24.317617866004962%;
	background-size: (450/95)*100% auto;
	width: 95px;

}
@mixin svg-pos-image-ya-logo-plus {
	background-position: 0 24.317617866004962%;
}
@mixin svg-image-ya-logo-plus-hover {
	.svg-image-ya-logo-plus {
		@include svg-pos-image-ya-logo-plus-hover
	}
}

.svg-image-ya-logo-plus:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/95)*100%;
}

.svg-image-ya-logo-plus-dims {
	width: 95px;
	height: 26px;
}

.svg-image-ya-maps {
	@extend %svg-common;
	background-position: 67.45283018867924% 80.89330024813896%;
	background-size: (450/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-ya-maps {
	background-position: 67.45283018867924% 80.89330024813896%;
}
@mixin svg-image-ya-maps-hover {
	.svg-image-ya-maps {
		@include svg-pos-image-ya-maps-hover
	}
}

.svg-image-ya-maps:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-ya-maps-dims {
	width: 26px;
	height: 26px;
}

.svg-image-ya-order {
	@extend %svg-common;
	background-position: 79.09738717339667% 31.67082294264339%;
	background-size: (450/29)*100% auto;
	width: 29px;

}
@mixin svg-pos-image-ya-order {
	background-position: 79.09738717339667% 31.67082294264339%;
}
@mixin svg-image-ya-order-hover {
	.svg-image-ya-order {
		@include svg-pos-image-ya-order-hover
	}
}

.svg-image-ya-order:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (28/29)*100%;
}

.svg-image-ya-order-dims {
	width: 29px;
	height: 28px;
}

.svg-image-ya-pay {
	@extend %svg-common;
	background-position: 58.94206549118388% 21.951219512195124%;
	background-size: (450/53)*100% auto;
	width: 53px;

}
@mixin svg-pos-image-ya-pay {
	background-position: 58.94206549118388% 21.951219512195124%;
}
@mixin svg-image-ya-pay-hover {
	.svg-image-ya-pay {
		@include svg-pos-image-ya-pay-hover
	}
}

.svg-image-ya-pay:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/53)*100%;
}

.svg-image-ya-pay-dims {
	width: 53px;
	height: 19px;
}

.svg-image-ya-pay-nfc {
	@extend %svg-common;
	background-position: 70.97560975609755% 56.1712846347607%;
	background-size: (450/40)*100% auto;
	width: 40px;

}
@mixin svg-pos-image-ya-pay-nfc {
	background-position: 70.97560975609755% 56.1712846347607%;
}
@mixin svg-image-ya-pay-nfc-hover {
	.svg-image-ya-pay-nfc {
		@include svg-pos-image-ya-pay-nfc-hover
	}
}

.svg-image-ya-pay-nfc:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (32/40)*100%;
}

.svg-image-ya-pay-nfc-dims {
	width: 40px;
	height: 32px;
}

.svg-image-ya-plus {
	@extend %svg-common;
	background-position: 8.67579908675799% 99.52038369304556%;
	background-size: (450/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-ya-plus {
	background-position: 8.67579908675799% 99.52038369304556%;
}
@mixin svg-image-ya-plus-hover {
	.svg-image-ya-plus {
		@include svg-pos-image-ya-plus-hover
	}
}

.svg-image-ya-plus:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (12/12)*100%;
}

.svg-image-ya-plus-dims {
	width: 12px;
	height: 12px;
}

.svg-image-yt-b {
	@extend %svg-common;
	background-position: 90.63231850117096% 27.272727272727273%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-yt-b {
	background-position: 90.63231850117096% 27.272727272727273%;
}
@mixin svg-image-yt-b-hover {
	.svg-image-yt-b {
		@include svg-pos-image-yt-b-hover
	}
}

.svg-image-yt-b:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-yt-b-dims {
	width: 23px;
	height: 22px;
}

.svg-image-yt-b-hover {
	@extend %svg-common;
	background-position: 90.63231850117096% 32.67813267813268%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-yt-b-hover {
	background-position: 90.63231850117096% 32.67813267813268%;
}
@mixin svg-image-yt-b-hover-hover {
	.svg-image-yt-b-hover {
		@include svg-pos-image-yt-b-hover-hover
	}
}

.svg-image-yt-b-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-yt-b-hover-dims {
	width: 23px;
	height: 22px;
}

.svg-image-yt-g {
	@extend %svg-common;
	background-position: 90.63231850117096% 38.08353808353808%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-yt-g {
	background-position: 90.63231850117096% 38.08353808353808%;
}
@mixin svg-image-yt-g-hover {
	.svg-image-yt-g {
		@include svg-pos-image-yt-g-hover
	}
}

.svg-image-yt-g:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-yt-g-dims {
	width: 23px;
	height: 22px;
}

.svg-image-yt-g-hover {
	@extend %svg-common;
	background-position: 90.63231850117096% 43.48894348894349%;
	background-size: (450/23)*100% auto;
	width: 23px;

}
@mixin svg-pos-image-yt-g-hover {
	background-position: 90.63231850117096% 43.48894348894349%;
}
@mixin svg-image-yt-g-hover-hover {
	.svg-image-yt-g-hover {
		@include svg-pos-image-yt-g-hover-hover
	}
}

.svg-image-yt-g-hover:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/23)*100%;
}

.svg-image-yt-g-hover-dims {
	width: 23px;
	height: 22px;
}

.svg-image-yt-rg {
	@extend %svg-common;
	background-position: 50% 54.35356200527705%;
	background-size: (450/50)*100% auto;
	width: 50px;

}
@mixin svg-pos-image-yt-rg {
	background-position: 50% 54.35356200527705%;
}
@mixin svg-image-yt-rg-hover {
	.svg-image-yt-rg {
		@include svg-pos-image-yt-rg-hover
	}
}

.svg-image-yt-rg:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (50/50)*100%;
}

.svg-image-yt-rg-dims {
	width: 50px;
	height: 50px;
}


.image-by-height { width:auto; }
.image-by-height:before { content:none; }
.image-by-height > svg { height: inherit; }
.image-by-height > img { height: inherit !important; vertical-align: top !important; margin: 0 !important; padding: 0 !important; max-width: none !important; max-height: none !important; border: 0 !important; opacity: 0 !important; }