.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $orange;
    border: 2px solid $orange;
    border-radius: 36px;
    font-family: $crpb;
    font-size: 13px;
    line-height: 1;
    color: #fff;
    white-space: nowrap;
    height: 36px;
    padding: 0 18px 1px;
    transition: all .2s ease;
    cursor: pointer;
    position: relative;
    &:hover {
        background-color: $dorange;
        border-color: $dorange;
        color: #fff; }
    &-big {
        font-family: $crpm;
        font-size: 16px;
        height: 48px; }
    &-outline {
        background: none;
        color: $text; }
    &-outline-white {
        background: none;
        border-color: #fff;
        color: #fff;
        .icon {
            margin-left: 8px;
            margin-bottom: -1px; }
        &:hover {
            border-color: $dorange; } }
    &-outline-gray {
        background: none;
        border-color: $gray;
        font-family: $crp;
        color: $blue;
        &:hover {
            @media (min-width: 1024px) {
                background: $bbg;
                border-color: $bbg;
                color: $dblue; }
            @media (max-width: 1023px) {
                background: #fff;
                border-color: $gray;
                color: $dblue; } } }
    &-catalog {
        height: 44px;
        font-size: 14px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        padding: 0 18px;
        .btn-icon {
            .icon {
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:last-child {
                    display: none; } } }
        span:not(.icon) {
            display: block;
            margin-left: 10px;
            transition: color .2s ease; }
        &.is-active {
            background-color: #fff;
            .btn-icon {
                .icon {
                    display: none;
                    &:last-child {
                        display: flex; } } }
            span:not(.icon) {
                color: $text; }
            &:hover {
                border-color: $dorange;
                @include svg-image-close-sm-hover; } } }
    &-filter {
        position: relative;
        display: none;
        height: 52px;
        border-radius: 12px;
        padding-top: 0;
        @media (max-width: 1023px) {
            display: flex; }
        .icon {
            width: 24px;
            margin-right: 10px;
            @media (max-width: 374px) {
                width: 20px; } }
        span {
            font-size: 17px;
            line-height: 20px;
            color: #fff;
            @media (max-width: 374px) {
                font-size: 15px; } }
        .counter {
            display: none;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: #fff;
            font-size: 13px;
            line-height: 14px;
            color: $orange;
            position: absolute;
            top: 13px;
            right: 20px; }
        &.with-counter {
            justify-content: flex-start;
            padding-left: 24px;
            padding-right: 60px;
            .counter {
                display: flex; } } }
    &-buy {
        border-color: $blue;
        background-color: $blue;
        .icon {
            margin-right: 6px; }
        &:hover {
            border-color: $dblue;
            background-color: $dblue; }
        &:disabled {
            border-color: #C0DEFF;
            background-color: #C0DEFF; }
        &--basket {
            border-color: $orange;
            background-color: $orange;
            width: auto;
            &:hover {
                border-color: $dorange;
                background-color: $dorange; } } }
    &-round {
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background-color: #fff;
        box-shadow: 0px 7px 18px rgba(115,139,153, .11);
        border-color: #fff;
        padding: 0;
        &:hover {
            border-color: #fff;
            background-color: #fff;
            box-shadow: 0px 7px 18px rgba(115,139,153, .15);
            @include m(1024px) {
                &.btn-compare {
                    @include svg-image-compare-g-hover; }
                &.btn-fav {
                    @include svg-image-heart-g-hover; }
                &.btn-remove {
                    @include svg-image-remove-g-hover; } } }

        &.is-active {
            border-color: #fff;
            background-color: #fff;
            box-shadow: 0px 7px 18px rgba(115,139,153, .15);
            &.btn-compare {
                @include svg-image-compare-g-hover; }
            &.btn-fav {
                @include svg-image-heart-g-hover; }
            &.btn-remove {
                @include svg-image-remove-g-hover; } } }

    &-read {
        height: 44px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        .icon {
            margin-left: 8px; } }
    &-more-text {
        a, .btn-more {
            font-family: CeraRound-Medium-Web;
            display: inline-flex;
            display: -webkit-inline-flex;
            display: -ms-inline-flexbox;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
            color: $orange;
            background-color: #faf4f1;
            border-radius: 6px;
            padding: 6px 10px;
            .icon {
                margin-left: 5px;
                margin-bottom: -1px; }
            &:hover {
                color: $dorange; } } }
    &-3d {
        background-color: $lgray;
        border-color: $lgray;
        font-size: 14px;
        color: $text;
        padding: 0 22px;
        margin: 0 0 30px;
        .icon {
            flex-shrink: 0;
            margin-right: 8px;
            transition: all .2s ease; }
        &:hover {
            background-color: $blue;
            border-color: $blue;
            color: #fff;
            .icon {
                filter: saturate(0%) brightness(6) contrast(2); } } }
    &-del {
        background-color: #fff;
        border-color: #fff;
        color: $text2;
        .icon {
            margin-right: 10px;
            transition: filter .1s ease; }
        &:hover {
            border-color: $orange;
            .icon {
                filter: contrast(0) brightness(2); } } }
    &-added {
        background-color: $green;
        border-color: $green;
        .icon {
            margin-right: 10px; }
        &:hover {
            background-color: $green;
            border-color: $green;
            cursor: default; } } }

.dropdown {
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 20px 50px rgba(42, 61, 91, 0.15), 0px 15px 40px rgba(42, 61, 91, 0.06), 0px 10px 30px rgba(42, 61, 91, 0.04), 0px 5px 10px rgba(42, 61, 91, 0.03);
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 16px);
    left: 0;
    transition: opacity .2s ease, max-height .4s ease;
    z-index: 998;
    &__outer {
        display: none;
        @include r(767px) {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(3,18,54,.65); } }
    &__wrap {
        @include r(767px) {
            z-index: 1;
            position: relative;
            overflow-y: auto;
            padding: 30px 0;
            border-radius: 20px;
            background-color: #fff; } }

    &__close {
        width: 64px;
        height: 64px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;
        &::before {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background-color: $orange;
            border-radius: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            transition: background .2s ease; }
        &::after {
            content: '';
            display: block;
            width: 25px;
            height: 2px;
            background-color: $orange;
            border-radius: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            transition: background .2s ease; }
        &:hover {
            cursor: pointer;
            &::before, &::after {
                background-color: $dorange; } } }
    &.is-visible {
        padding: 24px 32px;
        overflow: visible;
        visibility: visible;
        max-height: none;
        opacity: 1;
        @media (min-width: 768px) {
            height: auto !important; } }
    &--arrow-left {
        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #fff transparent;
            position: absolute;
            left: 22px;
            bottom: 100%; } }
    &--arrow-right {
        left: auto;
        right: 0;
        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #fff transparent;
            position: absolute;
            right: 22px;
            bottom: 100%; } }
    &--arrow-center {
        left: 50%;
        transform: translateX(-50%);
        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #fff transparent;
            position: absolute;
            left: calc(50% - 10px);
            bottom: 100%; } } }

.tooltipster {
    @media (max-width: 1023px) {
        &-base {
            pointer-events: auto; } } }

.ckeckboxes {
    display: flex;
    align-items: center;
    .checkbox, .tooltip {
        & + .checkbox {
            margin-left: 24px; } } }
.checkbox {
    position: relative;
    display: block;
    cursor: pointer;
    &__default {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;
        white-space: nowrap;
        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        overflow: hidden; }
    &__custom {
        display: block;
        width: 20px;
        height: 20px;
        border: 2px solid transparent;
        border-radius: 6px;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transition: all .2s ease;
        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 8px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.73877 3.55681C1.36655 3.1488 0.734047 3.11978 0.326038 3.492C-0.0819712 3.86422 -0.110985 4.49672 0.261234 4.90473L1.73877 3.55681ZM3.52632 7L2.78755 7.67396C2.97703 7.88166 3.24518 8 3.52632 8C3.80745 8 4.07561 7.88166 4.26508 7.67396L3.52632 7ZM9.73877 1.67396C10.111 1.26595 10.082 0.633453 9.67396 0.261234C9.26595 -0.110985 8.63345 -0.0819712 8.26123 0.326038L9.73877 1.67396ZM0.261234 4.90473L2.78755 7.67396L4.26508 6.32604L1.73877 3.55681L0.261234 4.90473ZM4.26508 7.67396L9.73877 1.67396L8.26123 0.326038L2.78755 6.32604L4.26508 7.67396Z' fill='white'/%3E%3C/svg%3E");
            background-size: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity .2s ease;
            opacity: 0; } }
    &__label {
        // display: flex
        // align-items: center
        display: block;
        font-size: 14px;
        line-height: 20px;
        user-select: none;
        padding-left: 30px; }
    &__default:checked + .checkbox__custom {
        background-color: $orange;
        border-color: $orange;
        &::before {
            opacity: 1; } }
    &:hover {
        .checkbox__custom {
            border-color: $orange; } }
    &--on-white {
        .checkbox__custom {
            border-color: $gray; } } }

.radio-item {
    display: block;
    cursor: pointer;
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid $gray;
        border-radius: 8px;
        padding: 16px 20px;
        transition: all .2s ease;
        .icon-payt {
            min-width: 38px;
            margin-right: 14px;
            text-align: center; }
        .text {
            flex-grow: 1;
            .lead {
                font-size: 16px;
                line-height: 24px;
                color: $text;
                margin-bottom: 2px; }
            span {
                display: block;
                font-size: 13px;
                line-height: 18px;
                color: $text2;
                a {
                    display: inline-block;
                    vertical-align: middle;
                    border-bottom: 1px dotted $text2;
                    color: $text2; } }
            .has-tooltip {
                display: inline-block;
                position: relative;
                &::after {
                    content: '..............................................................................................................................................................................................................................................................................';
                    position: absolute;
                    left: 0;
                    right: 23px;
                    bottom: -2px;
                    overflow: hidden;
                    font-size: 11px;
                    line-height: 11px;
                    opacity: 0.5; } }
            .tooltip {
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px; } }
        .check {
            position: relative;
            min-width: 40px;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            border: 2px solid $gray;
            border-radius: 100%;
            transition: all .2s ease;
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 13px;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.04022 6.73723C1.6497 6.34671 1.01654 6.3467 0.626011 6.73723C0.235486 7.12775 0.235484 7.76092 0.626007 8.15144L2.04022 6.73723ZM5.33309 11.4443L4.62599 12.1514C4.81775 12.3432 5.07917 12.4489 5.35033 12.4442C5.62148 12.4395 5.87911 12.3249 6.06415 12.1267L5.33309 11.4443ZM15.3974 2.12665C15.7742 1.7229 15.7524 1.09011 15.3487 0.713278C14.9449 0.336445 14.3121 0.358269 13.9353 0.762021L15.3974 2.12665ZM0.626007 8.15144L4.62599 12.1514L6.0402 10.7372L2.04022 6.73723L0.626007 8.15144ZM6.06415 12.1267L15.3974 2.12665L13.9353 0.762021L4.60204 10.762L6.06415 12.1267Z' fill='white'/%3E%3C/svg%3E");
                background-size: cover;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity .2s ease; } } }
    .input-hidden:checked + .radio-item__inner {
        background-color: #F9FFF3;
        border-color: $green;
        .check {
            background-color: $green;
            border-color: $green;
            &::before {
                opacity: 1; } } } }

.input {
    position: relative;
    &__reset {
        padding: 15px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        z-index: 3;
        .icon {
            display: block; } } }

.breadcrumbs {
    //старые стили
    //margin-bottom: 12px
    //.container
    //    @media (max-width: 1023px)
    //        position: relative
    //        overflow-x: auto
    //        -ms-overflow-style: none
    //        scrollbar-width: none
    //        &::-webkit-scrollbar
    //            display: none
    //
    //&__list
    //    display: inline-flex
    //    flex-wrap: wrap
    //    align-items: center
    //    @media (max-width: 1023px)
    //        flex-wrap: nowrap
    //
    //&__link
    //    position: relative
    //    font-size: 12px
    //    line-height: 20px
    //    color: $text2
    //    padding-right: 22px
    //    margin-right: 15px
    //    flex-shrink: 0
    //    white-space: nowrap
    //    &:hover
    //        color: $orange
    //    &:not(&--last)::after
    //        content: ''
    //        display: block
    //        width: 7px
    //        height: 7px
    //        background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.52391 3.51366L1.8413 1.33246L1.8413 5.69487L5.52391 3.51366ZM6.68463 3.92955C6.9951 3.74567 6.9951 3.28166 6.68463 3.09777L1.60845 0.0911631C1.30037 -0.0913112 0.917046 0.139265 0.917046 0.507053L0.917046 6.52027C0.917046 6.88806 1.30037 7.11864 1.60845 6.93616L6.68463 3.92955Z' fill='%23FC6D05'/%3E%3C/svg%3E")
    //        background-size: cover
    //        position: absolute
    //        top: 50%
    //        right: 0
    //        transform: translateY(-45%)
    //&__current
    //    font-size: 12px
    //    line-height: 20px
    //    color: $text2
    //    flex-shrink: 0
    //    white-space: nowrap
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin: 0 auto 20px;
        @media (max-width: 1023px) {
            overflow: auto;
            background-color: $white;
            padding: 8.5px 16px;
            gap: unset;
            margin: 0;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none; } } }

    &__list {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        @media (max-width: 1023px) {
            flex-wrap: nowrap; } }
    &__link {
        display: flex;
        align-items: center;
        padding: 4px 6px 4px 12px;
        margin-right: 4px;
        position: relative;
        flex-shrink: 0;
        white-space: nowrap;

        @media (max-width: 1023px) {
            padding: 2px 1px 2px 4px; }

        &::after {
            content: '';
            display: block;
            position: relative;
            top: 0;
            width: 12px;
            height: 12px;
            background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%20fill%3D%22none%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M4.39174%209.40524C4.13602%209.14558%204.13602%208.72457%204.39174%208.46491L6.81913%205.99999L4.39174%203.53508C4.13602%203.27541%204.13602%202.85441%204.39174%202.59474C4.64745%202.33508%205.06204%202.33508%205.31776%202.59474L8.20817%205.52983C8.46388%205.78949%208.46388%206.21049%208.20817%206.47016L5.31776%209.40524C5.06204%209.66491%204.64745%209.66491%204.39174%209.40524Z%22%20fill%3D%22%23A7AEB6%22%2F%3E%0A%3C%2Fsvg%3E');
            background-size: cover;
            margin: 0 0 0 2px;
            right: 0;

            @media (max-width: 1023px) {
                width: 10px;
                height: 10px;
                margin: 0; } }
        &__current {
            padding: 4px 12px;
            @media (max-width: 1023px) {
                padding: 2px 4px; } } }

    &__link,
    &__current {
        background-color: $gray2;
        border-radius: 4px;
        font-size: 13px;
        line-height: 120%;
        letter-spacing: -0.13px;
        color: $text;
        transition: background-color .3s ease;

        &:hover,
        &:focus,
        &:active {
            color: $text; }
        &:hover,
        &:active {
            background-color: $bbg; }

        @media (max-width: 1023px) {
            font-family: $crpm;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: -0.12px;
            &:hover,
            &:focus,
            &:active {
                background-color: $gray2; } } }

    &__item:last-child {
        .breadcrumbs__link {
            padding: 4px 12px;
            @media (max-width: 1023px) {
                padding: 2px 4px; }
            &::after {
                display: none;
                content: none; } } }
    &-actions {
        display: flex;
        align-items: center; } }

.quick-links {
    position: relative;
    .container {
        @media (max-width: 1023px) {
            padding: 0; } }
    &__items {
        display: flex;
        @media (max-width: 1023px) {
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            padding: 0 8px 0 32px;
            margin: 0 -16px; } }
    .quick-link {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        border: 1px solid $gray;
        border-radius: 32px;
        height: 32px;
        font-family: $crpm;
        font-size: 14px;
        line-height: 1;
        padding-right: 14px;
        margin: 0 8px 8px 0;
        transition: all .2s ease;
        &:last-child {
            margin-right: 0; }

        &-icon {
            svg {
                fill: $blue; } }

        a {
            display: inline-block;
            color: $blue;
            padding: 8px 0 8px 14px; }
        p, span {
            flex-shrink: 0;
            font-size: 13px;
            line-height: 1;
            color: $text;
            padding-top: 2px;
            margin-left: 5px;
            transition: color .2s ease; }
        &:hover, &.is-active {
            border-color: $blue;
            background-color: $blue;
            color: #fff;
            a,p,span {
                color: #fff; }
            .quick-link-icon {
                svg {
                    fill: #fff; } } }
        &--all {
            padding: 0;
            span {
                display: inline-block;
                color: $blue;
                padding: 8px 14px;
                transition: color .2s ease; }
            &:hover, &.is-active {
                span {
                    color: #fff; } } } }
    &__more {
        display: none;
        align-items: center;
        font-family: $crpm;
        line-height: 1.6;
        height: 32px;
        color: $orange;
        white-space: nowrap;
        padding-right: 18px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        cursor: pointer;
        &::after {
            content: '';
            display: block;
            width: 11px;
            height: 7px;
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.2793 0.281184C10.6517 0.656024 10.6517 1.26376 10.2793 1.6386L5.95357 5.99818C5.58123 6.37302 4.97753 6.37302 4.60518 5.99818L0.279345 1.63858C-0.0930033 1.26374 -0.0930033 0.656006 0.279346 0.281166C0.651693 -0.0936743 1.25539 -0.0936743 1.62774 0.281166L5.27938 3.96206L8.93094 0.281184C9.30329 -0.0936563 9.90698 -0.0936562 10.2793 0.281184Z' fill='%23FC6D05'/%3E%3C/svg%3E");
            background-size: cover;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-30%); }
        @media (min-width: 1024px) {
            display: inline-flex;
            padding-right: 0;
            top: 50%;
            left: auto;
            right: 16px;
            bottom: auto;
            transform: translateY(-65%);
            &::after {
                display: none; } }
        @media (min-width: 1280px) {
            right: 60px; }
        @media (min-width: 1600px) {
            right: calc((100vw - 1480px) / 2); } }

    &.show-more {
        .container {
            padding-right: 190px;
            @media (max-width: 1279px) {
                padding-right: 150px; }
            @media (max-width: 1023px) {
                padding-right: 16px; } }
        .quick-links__items {
            overflow-x: auto;
            overflow-y: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none; } }
        @media (max-width: 1023px) {
            padding-bottom: 0;
            .quick-links__more {
                display: inline-flex;
                visibility: hidden; } } }
    &.is-shown {
        .container {
            padding-right: 60px; }
        .quick-links__items {
            flex-wrap: wrap;
            overflow: visible; }
        .quick-links__more {
            &::after {
                transform: translateY(-40%) rotate(180deg); }
            @media (min-width: 1024px) {
                position: static;
                transform: none;
                padding: 0 14px; } } } }

.range-slider {
    &__inputs {
        display: flex;
        margin-bottom: 8px;
        .input {
            width: calc(50% - 10px);
            &::before {
                content: 'до';
                font-size: 11px;
                line-height: 20px;
                color: $text2;
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-40%); }
            input {
                background-color: #fff;
                box-shadow: 0px 2px 10px rgba(109, 120, 133, 0);
                border: 2px solid transparent;
                border-radius: 6px;
                height: 36px;
                padding: 2px 12px 0 28px;
                transition: all .2s ease;
                @media (max-width: 1023px) {
                    border-color: $gray;
                    height: 40px; }
                &.is-active {
                    box-shadow: 0px 2px 10px rgba(109,120,133, .3);
                    border-color: $orange; } }
            &:first-child {
                margin-right: 20px;
                &::before {
                    content: 'от'; }
                &::after {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 2px;
                    border-radius: 2px;
                    background-color: $orange;
                    position: absolute;
                    top: calc(50% - 1px);
                    left: calc(100% + 6px); } } } } }

.c-select {
    position: relative;
    width: 100%;
    height: 48px;
    background-color: #fff;
    border-radius: 6px;
    border: 2px solid $gray;
    &__in {
        width: 100%;
        height: 100%;
        padding: 0 40px 0 18px;
        line-height: 44px;
        border-radius: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;
        user-select: none;
        cursor: pointer;
        span {
            color: rgba($text, .4); }
        &::after {
            content: '';
            display: block;
            width: 13px;
            height: 8px;
            background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' version='1.1' viewBox='0 0 13 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0.78709 0.7879c0.38278-0.38387 1.0034-0.38387 1.3862 0l4.3267 4.339 4.3267-4.339c0.38286-0.38387 1.0034-0.38387 1.3862 0 0.38286 0.38387 0.38286 1.0063 0 1.3901l-5.0198 5.0341c-0.38279 0.38387-1.0034 0.38387-1.3862 0l-5.0198-5.0341c-0.38278-0.38387-0.38278-1.0063 0-1.3901z' clip-rule='evenodd' fill='%23FC6D05' fill-rule='evenodd' stroke-width='1'/%3E%3C/svg%3E");
            background-size: cover;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-45%);
            pointer-events: none;
            filter: brightness(0);
            transition: filter .2s ease; } }
    &__list {
        display: block;
        background-color: #fff;
        box-shadow: 0px 11px 60px rgba(87,108,120, 0);
        border-radius: 6px;
        padding-top: 48px;
        overflow: hidden;
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        transition: opacity .2s ease .1s;
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        &::before {
            content: '';
            display: block;
            height: 1px;
            background-color: $lgray;
            position: absolute;
            top: 48px;
            left: 20px;
            right: 20px; } }
    &__item {
        max-height: 0;
        line-height: 20px;
        padding: 14px 20px;
        transition: all .3s ease;
        cursor: pointer;
        &:hover {
            background-color: $bbg;
            color: $blue; } }
    &:hover {
        .c-select__in {
            z-index: 99;
            &::after {
                filter: brightness(100%); } } }
    &.is-opened {
        border-color: #fff;
        border-radius: 6px 6px 0 0;
        z-index: 99;
        .c-select__in {
            z-index: 99;
            &::after {
                transform: translateY(-45%) rotate(180deg);
                filter: brightness(100%); } }
        .c-select__list {
            box-shadow: 0px 11px 60px rgba(87,108,120, .2);
            visibility: visible;
            max-height: none;
            opacity: 1;
            z-index: 98; }
        .c-select__item {
            max-height: none; } }
    &--sm {
        height: 40px;
        .c-select__in {
            line-height: 36px;
            padding-right: 36px; }
        .c-select__icon {
            transform: translateY(-50%);
            .icon {
                &:nth-child(2) {
                    display: none; } } }
        .c-select__list {
            padding-top: 40px;
            &::before {
                top: 40px; } }
        .c-select__item {
            padding: 10px 20px; }
        &:hover {
            .select__icon {
                @include svg-image-select-arr-hover; } } }
    &--xs {
        height: 36px;
        .c-select__in {
            line-height: 32px;
            padding-right: 34px; }
        .c-select__icon {
            transform: translateY(-50%);
            .icon {
                &:nth-child(2) {
                    display: none; } } }
        .c-select__list {
            padding-top: 36px;
            &::before {
                top: 36px; } }
        .c-select__item {
            padding: 8px 20px; }
        &:hover {
            .c-select__icon {
                @include svg-image-select-arr-sm-hover; } }
        &.is-opened {
            .c-select__icon {
                transform: translateY(-35%) rotate(180deg);
                @include svg-image-select-arr-sm-hover; } } }
    &--sorting {
        .c-select__in, .c-select__item {
            .icon {
                vertical-align: middle;
                margin-top: -4px;
                margin-left: 6px; } } }
    &--colors {
        .color {
            flex-shrink: 0;
            display: inline-block;
            vertical-align: text-bottom;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            overflow: hidden;
            margin-right: 12px; }
        .c-select__in {
            .color {
                margin-right: 7px;
                margin-bottom: -1px; } }
        .c-select__item {
            display: flex;
            align-items: center;
            line-height: 20px;
            color: $text2;
            &:hover {
                color: $text; } } } }

.banner {
    position: relative;
    border-radius: 12px;
    background-size: cover !important;
    height: 424px;
    max-height: 100%;
    &__bg {
        border-radius: 12px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        img,
        .picture {
            width: 100%;
            height: 100%;
            max-width: initial; } }
    &__inner {
        position: absolute;
        top: 30px;
        left: 10%;
        right: 10%;
        bottom: 40px; }
    &__hashtag {
        font-size: 13px;
        line-height: 24px;
        color: rgba(#fff, .6);
        margin-bottom: 10px; }
    &__title {
        display: block;
        font-size: 22px;
        line-height: 28px;
        color: #fff;
        margin-bottom: 12px; }
    &__price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 86px;
        height: 86px;
        background-color: $blue;
        border-radius: 100%;
        color: #fff;
        position: absolute;
        right: 20px;
        bottom: 100px;
        transform: translate3d(0,0,0) rotate(7deg);
        span {
            display: block;
            font-family: $crpm;
            font-size: 19px;
            line-height: 1;
            letter-spacing: -0.03em;
            padding-top: 1px; } }
    &-slider {
        position: relative; } }

.row-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: cover !important;
    width: 100%;
    height: 160px;
    border-radius: 12px;
    padding: 0;
    margin-bottom: 60px;
    overflow: hidden;
    @media (max-width: 1279px) {
        background-size: 100% 100% !important;
        padding: 32px 46px 16px; }
    @media (max-width: 1023px) {
        display: none; }
    &__text {
        color: #fff;
        .h3 {
            line-height: 1.2;
            margin-bottom: 16px;
            @include fluidFontSize(26px, 30px, 1024px, 1600px, 26px); }
        .descr {
            font-size: 12px;
            color: #fff;
            opacity: 0.4;
            @include fluidFontSize(10px, 12px, 1024px, 1600px, 11px); }
        p {
            font-family: $crpm;
            line-height: 24px;
            @include fluidFontSize(15px, 17px, 1024px, 1600px, 16px);
            span {
                display: block;
                font-family: $crp;
                font-size: 14px;
                @include fluidFontSize(12px, 14px, 1024px, 1600px, 13px); } }
        .btn {
            width: 155px;
            height: 44px;
            font-family: $crpm;
            font-size: 16px;
            span {
                width: 18px; } } } }

.p-rating {
    display: inline-flex;
    align-items: center;
    .icon {
        margin-right: 7px; }
    &__value {
        font-family: $crpb;
        font-size: 13px;
        line-height: 24px;
        margin-right: 15px; }
    &__link {
        font-size: 13px;
        line-height: 24px;
        color: $blue;
        &:hover {
            color: $dblue; } } }

.pop-card {
    padding-right: 32px;
    @media (max-width: 767px) {
        padding: 0 16px; }
    &__img {
        position: relative;
        display: block;
        padding-bottom: 56.25%;
        margin-bottom: 16px;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: contain; } }
    &__name {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 48px;
        line-height: 24px;
        margin-bottom: 4px; }
    .p-rating {
        margin-bottom: 16px; }
    &__price {
        display: flex;
        align-items: center;
        span:not(.icon) {
            font-family: $crpb;
            font-size: 21px;
            line-height: 28px; }
        .icon {
            margin-left: 4px;
            margin-bottom: -1px; } } }

.tooltip, .tooltip-with-close {
    display: inline-block;
    vertical-align: text-bottom;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.6' cx='8' cy='8' r='7.5' stroke='%236D7885'/%3E%3Cpath d='M7.908 9.664C8.232 9.664 8.484 9.436 8.496 9.124L8.508 9.004C8.508 8.656 8.7 8.38 9.348 7.804C10.104 7.132 10.404 6.628 10.404 5.992C10.404 4.852 9.444 4 8.184 4C7.224 4 6.48 4.468 6.108 5.284C6.036 5.416 6 5.524 6 5.668C6 5.98 6.252 6.232 6.564 6.232C6.792 6.232 6.96 6.124 7.104 5.872C7.332 5.404 7.692 5.164 8.136 5.164C8.676 5.164 9.096 5.572 9.096 6.124C9.096 6.544 8.94 6.784 8.232 7.42C7.524 8.08 7.308 8.44 7.308 8.944V9.088C7.308 9.412 7.584 9.664 7.908 9.664ZM7.944 12.184C8.448 12.184 8.844 11.788 8.844 11.284C8.844 10.78 8.448 10.372 7.944 10.372C7.428 10.372 7.02 10.78 7.02 11.284C7.02 11.788 7.428 12.184 7.944 12.184Z' fill='%23FC6D05'/%3E%3C/svg%3E");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .2s ease;
    cursor: pointer;
    margin-left: 4px;
    .icon {
        display: block; }
    &:hover {
        filter: grayscale(100%); } }
.tooltip__close {
    display: block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 20px;
    right: 20px;
    &::before {
        content: '';
        display: block;
        width: 26px;
        height: 2px;
        background-color: $orange;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg); }
    &::after {
        content: '';
        display: block;
        width: 26px;
        height: 2px;
        background-color: $orange;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg); } }

.video {
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
    &__player {
        position: relative;
        width: 100%;
        flex-shrink: 0;
        padding-bottom: 56.25%;
        // @media (-ms-high-contrast: none), (-ms-high-contrast: active)
 }        //     margin-left: -10vw
    &__poster {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0; }
    &__overlay {
        display: block;
        cursor: pointer;
        background: linear-gradient(351.2deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 26.41%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2; }
    &__play-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        transition: background .2s ease;
        position: absolute;
        left: 16px;
        bottom: 16px;
        z-index: 2;
        opacity: .96;
        cursor: pointer;
        .icon {
            margin-left: 3px; }
        &:hover {
            background-color: $ddblue; } }
    &__duration {
        font-size: 13px;
        color: #fff;
        position: absolute;
        right: 20px;
        bottom: 15px;
        z-index: 2;
        cursor: default;
        user-select: none; }
    &__frame {
        display: none;
        border: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; } }

.sec-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        .o-link-arrow {
            margin: 10px 0 -10px; } }
    &.text-center {
        justify-content: center;
        @media (max-width: 767px) {
            flex-direction: row; }
        h2 {
            font-size: 20px; } }
    &__title {
        position: relative;
        padding-right: 24px;
        @include fluidFontSize(26px, 30px, 375px, 768px, 28px); }
    h2 {
        position: relative;
        display: inline-block; }
    p {
        font-family: $crpm;
        font-size: .5em;
        line-height: 1;
        position: absolute;
        top: auto;
        left: calc(100% - 20px);
        bottom: 60%; } }

.sec-descr {
    margin: -14px 0 16px;
    font-size: 15px;
    line-height: 24px;
    color: $text2; }

.p-carousel {
    position: relative;
    padding-bottom: 15px;
    &>.swiper-container {
        overflow: visible;
        z-index: 2;
        .swiper-wrapper {
            align-items: stretch;
            .swiper-slide {} }
        .swiper-slide:not(.swiper-slide-visible) {
            pointer-events: none; } }
    .swiper-slide {
        height: auto;
        width: 300px;
        transition: opacity .3s ease;
        @media (min-width: 1024px) {
            opacity: 0;
            &.swiper-slide-visible {
                opacity: 1; } }
        @media (max-width: 599px) {
            width: 290px !important; } }
    .p-card {
        @media (max-width: 1279px) {
            height: 100%;
            &__descr {
                display: flex;
                flex-direction: column; }
            .pc-price {
                margin-top: auto; } }
        &__btns {
            .btn-buy {
                @media (max-width: 599px) {
                    font-size: 0;
                    line-height: 0;
                    .icon {
                        margin-right: 0; } } } } }
    .swiper-button {
        &--prev {
            top: 30%;
            transform: translate(-50%, 0); }
        &--next {
            top: 30%;
            transform: translate(50%, 0); }
        @media (max-width: 1279px) {
            display: none; } }
    .swiper-pagination--lines {
        bottom: 0;
        z-index: 1; } }

.a-card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    padding-bottom: 133.33%;
    max-width: 310px;
    max-height: 420px;
    cursor: pointer;
    &::before {
        content: '';
        display: block;
        border-radius: 12px;
        background: linear-gradient(180deg, rgba(25, 26, 27, 0) 25.48%, rgba(25, 26, 27, 0.65) 62.35%, rgba(25, 26, 27, 0.85) 93.79%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2; }
    @media (max-width: 767px) {
        max-width: 100%;
        border-radius: 0px;
        &::before {
            border-radius: 0px; } }
    &__img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: 310px;
            // max-height: 420px
            @media (max-width: 767px) {
                max-width: 100%; } } }
    &__label {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $blue;
        border-radius: 6px;
        font-family: $crpm;
        font-size: 12px;
        line-height: 2;
        color: #fff;
        padding: 0 10px;
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 3; }
    &__text {
        color: #fff;
        position: absolute;
        left: 32px;
        right: 32px;
        bottom: 32px;
        z-index: 3;
        .btn-read {
            visibility: hidden;
            height: 0;
            opacity: 0; } }
    &__date {
        font-size: 12px;
        line-height: 2;
        margin-bottom: 8px;
        span {
            display: inline-block;
            color: $orange;
            margin: 0 8px; } }
    &__title {
        display: block;
        font-family: $crpm;
        font-size: 20px;
        line-height: 1.4;
        color: #fff;
        letter-spacing: -0.003em; }
    &:hover {
        .a-card__text {
            .btn-read {
                visibility: visible;
                height: 44px;
                opacity: 1;
                margin-top: 24px; } } }
    &--inset {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        padding: 32px;
        &::before {
            background: rgba($text, .6); }
        .a-card__title {
            position: relative;
            font-family: $crpm;
            font-size: 22px;
            line-height: 30px;
            color: #fff;
            z-index: 3; }
        .btn {
            position: relative;
            z-index: 3; } } }

.tags {
    display: flex;
    flex-wrap: wrap; }
.tag {
    display: inline-flex;
    background-color: $bbg;
    border-radius: 6px;
    font-family: $crpm;
    font-size: 12px;
    line-height: 1;
    color: $blue;
    white-space: nowrap;
    padding: 6px 10px;
    margin: 0 6px 6px 0;
    &:hover, &.active {
        background-color: $orange;
        color: #fff; } }

.attention {
    display: inline-flex;
    align-items: flex-start;
    background-color: #F7FFF0;
    border-radius: 8px;
    padding: 22px;
    margin-bottom: 30px;
    .icon {
        margin-right: 12px; }
    &__text {
        p {
            font-size: 19px;
            line-height: 25px;
            color: $text3;
            margin: 0;
            span {
                color: $orange; }
            @media (max-width: 767px) {
                font-size: 15px;
                line-height: 20px; } } }
    &__title {
        font-family: $crpm;
        font-size: 15px;
        line-height: 24px;
        color: $text;
        margin-bottom: 4px; }
    &--w100 {
        display: flex; }
    &--red {
        background-color: #FFF6F5; }
    &--yellow {
        background-color: #FFFCF1; } }

.status {
    position: relative;
    font-family: $crpm;
    font-size: 13px;
    white-space: nowrap;
    line-height: 24px;
    padding-left: 14px;
    height: 24px; //чтобы точка в before не съезжала
    &::before {
        content: '';
        display: block;
        flex-shrink: 0;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%); }
    &--available {
        color: $green;
        &::before {
            background-color: $green; } }
    &--not-available {
        color: $red;
        &::before {
            background-color: $red; } } }

.p-card__gift:hover {
    @include svg-image-gift-circle-hover; }

.art-item__share .dropdown .link {
    &--fb {
        &:hover {
            .link__icon {
                @include svg-image-fb-b-hover; } } }
    &--vk {
        &:hover {
            .link__icon {
                @include svg-image-vk-b-hover; } } }
    &--tg {
        .link__icon {
            .icon {
                width: 17px; } }
        &:hover {
            .link__icon {
                @include svg-image-tg-b-hover; } } }
    &--ok {
        .link__icon {
            .icon {
                width: 18px; } }
        &:hover {
            .link__icon {
                @include svg-image-ok-b-hover; } } }
    &--link {
        &:hover {
            .link__icon {
                @include svg-image-link-b-hover; } } } }

.cart-success .cs-order__num .copy:hover {
    @include svg-image-copy-hover; }

.p-top-bar__item--share .dropdown .link {
    &--fb {
        &:hover {
            .link__icon {
                @include svg-image-fb-g-hover; } } }
    &--vk {
        &:hover {
            .link__icon {
                @include svg-image-vk-g-hover; } } }
    &--tg {
        .link__icon {
            .icon {
                width: 17px; } }
        &:hover {
            .link__icon {
                @include svg-image-tg-g-hover; } } }
    &--ok {
        .link__icon {
            .icon {
                width: 18px; } }
        &:hover {
            .link__icon {
                @include svg-image-ok-g-hover; } } }
    &--link {
        &:hover {
            .link__icon {
                @include svg-image-link-hover; } } } }

.product__actions .p-btns-mob .btn {
    &.is-active {
        &.btn-compare {
            @include svg-image-compare-g-hover; }
        &.btn-fav {
            @include svg-image-heart-g-hover; } } }
.sp-lists .list, .sp-lists .num-list {
    margin-bottom: 16px; }
.hidden {
    display: none !important; }
body .articles-list__pagination {
    max-width: 540px;
    margin: 16px auto 0; }

.article {
    &__block {
        .accent {
            display: inline-block;
            background-color: #48c071;
            color: #fff;
            padding: 0 8px;
            font-style: normal; }
        a {
            color: #247ce0;
            .btn-buy--basket, a:not(.chosen-single) span {
                color: #fff; } }
        .num-list-html li::before {
            content: attr(data-number)".";
            position: absolute;
            top: -1px;
            left: 0;
            font-size: 16px;
            line-height: 1.75;
            @media (max-width: 767px) {
                top: 1.5px;
                line-height: 100%; } }
        .a-table tbody td {
            white-space: normal; } } }

.contact-form__submit button[disabled] {
    border-color: #ffbf92;
    background-color: #ffbf92;
    color: #ffffff; }
.view-preloader--op {
    opacity: .7; }

.view-preloader[id^="comp_"] {
    pointer-events: auto;
    position: relative; }
.view-preloader-event-not {
    pointer-events: none; }
.preload__svg {
    display: none; }
.preload {
    display: none;
    background: rgba(241,246,250,.84); }
.view-preloader:not(body) .preload__svg, .view-preloader:not(body) .preload {
    display: block; }
body.view-preloader .preload {
    position: fixed; }
body.view-preloader>.preload, body.view-preloader>.preload .preload__svg {
    display: block!important; }
.exchange-return .blockquote p, .guaranty .g-return__item p span {
    color: #313339; }

.fancybox-thumbs {
    top: auto;
    width: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95px;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3); }
.fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 95px; }
.fancybox-thumbs-x .fancybox-thumbs__list {
    margin: 0 auto; }
.fancybox-thumbs__list a::before {
    border: 3px solid #62a8f7; }
.cat-links__list div a {
    display: block; }
.contact-form__field.error {
    border-color: #f43 !important; }
.contact-form__form-group {
    position: relative; }
.contact-form__form-group .error-text {
    display: none;
    font-size: 13px;
    line-height: 16px;
    color: #f43;
    position: absolute;
    top: calc(100% + 6px);
    left: 0; }
.contact-form__form-group.invalid p {
    color: #f43; }
.contact-form__form-group.invalid p span {
    color: #313339; }
.contact-form__form-group.invalid input {
    border-color: #f43; }
.contact-form__form-group.valid .contact-form__field {
    border-color: #71b732;
    padding-right: 30px; }
.contact-form__form-group.valid::after {
    content: '';
    display: block;
    width: 21px;
    height: 16px;
    background-image:url("data:image/svg+xml,%3Csvg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21 1.40357L6.57737 16L0 9.34337L1.38686 7.9398L6.57737 13.1929L19.6131 0L21 1.40357Z' fill='%2371B732'/%3E%3C/svg%3E") {}
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 14px;
    @media (max-width: 1023px) {
        right: 20px; } }
.call-banner__form .input {
    justify-content: space-between; }
.popup {
    min-height: 160px; }
.popup-overlay {
    display: none; }
.popup--gift .gift-item:last-child {
    padding-bottom: 0; }
.popup.popup--additional-services {
    overflow: visible; }
.user-form [class*="js-step"], .user-form .popup__title .back {
    display: none; }
.user-form .js-step-1 {
    display: block; }
.f-subscribe [type="submit"] .preload__loader, .subscribe-form [type="submit"] .preload__loader {
    display: none; }
.f-subscribe [type="submit"].btn-loading .preload__loader, .subscribe-form [type="submit"].btn-loading .preload__loader {
    display: inline-block;
    height: auto;
    margin: 0; }
.f-subscribe [type="submit"].btn-loading .preload__svg, .subscribe-form [type="submit"].btn-loading .preload__svg {
    display: flex;
    margin: 0; }
.subscribe-form [type="submit"].btn-loading .preload__svg {
    margin: 0; }
@media (max-width: 1023px) {
    .call-banner {
        overflow-x: hidden; } }
.flex-justify {
    display: flex;
    justify-content: space-between; }
.rev-photos__item {
    margin: 0 3px 8px; }
.p-card__img-carousel>.picture {
    visibility: hidden; }
.p-card__img-carousel>.picture:first-child {
    visibility: visible; }
.quick-links .quick-link p, .quick-links .quick-link span {
    line-height: 0.9; }
.swiper-slide.img-video video {
    width: 100%;
    height: 100%;
    object-fit: contain; }
#callback_form .checkbox__custom {
    border-color: #fc6d05; }
.chosen-container-single .chosen-single.chosen-default {
    color: #313339; }
.cat-item__title .o-link {
    color: #fc6d05; }
.article__block.sitemap {
    margin-bottom: 30px; }
ul.list li.black-lvl::before {
    background-color: #000000; }
ul.list li.yellow-lvl::before {
    background-color: #fcc84d; }
.cat-links__list a.h6 {
    color: #313339; }
.descr i {
    font-style: normal; }
.pagination__placeholder {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 16px;
    height: 44px;
    border-radius: 100%;
    margin-right: 8px;
    @media (max-width: 767px) {
        margin-right: 4px; } }
i.js-basket-mini-total-price {
    font-style: normal; }
.load-block {
    height: 300px; }
.mobile-offers {
    height: 400px;
    width: 100%; }
.location-popup-link {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding-bottom: 4px;
    margin-bottom: -4px;
    color: #fc6d05;
    transition: color .3s ease;
    &::after {
        content: '';
        position: absolute;
        left: 1px;
        right: 0;
        bottom: 0;
        height: 1px;
        background: transparent !important;
        border: 1px dashed #fc6d05;
        transition: background .3s ease; }
    &.location-popup-link__small {
        padding-bottom: 0px;
        margin-bottom: 0px;
        &::after {
            height: 1px; } } }

@media (max-width: 767px) {
    .p-card {
        padding-left: 0px;
        padding-right: 0px; }
    .p-card__img {
        padding-bottom: 75%; }
    .p-card__descr, .p-card__hover {
        padding-left: 16px;
        padding-right: 16px; }
    .p-card .pc-parameters__item {
        max-width: 50%;
        width: auto; }
    .place__cities {
        display: flex;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-gap: 2px;
        -moz-column-gap: 2px;
        column-gap: 2px; }
    .place__cities-column {
        width: 100%; }
    .pop-card__price span:not(.icon) {
        font-size: 18px; }
    .swiper-slide .pop-card__price {
        white-space: nowrap; }
    .popup-overlay {
        z-index: 9999999999; }
    .cart-item__descr {
        min-height: 130px; }
    .is-gift .cart-item__descr {
        min-height: 30px; }
    .cart-item__delivery .cart-item__descr {
        min-height: auto;
        padding-bottom: 20px; }
    .cart-item__delivery .cart-item__descr .descr {
        line-height: 20px;
        margin-top: 5px; }
    .cart-total:not(.cart-total--bottom) .cart-total__delivery {
        display: none; }
    .popup--search .popular-goods__slider .swiper-pagination--lines.many-bullets .swiper-pagination-bullet {
        width: 20px; }
    .comparison__body-block .comp-row.comp-row--size {
        padding-bottom: 50px; } }

.text-bold {
    font-family: $crpb; }

.pin-caption {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($text, .8);
	height: 22px;
	border-radius: 22px;
	font-family: $crpb;
	font-size: 12px;
	line-height: 1;
	color: #fff;
	white-space: nowrap;
	text-align: center;
	padding: 0 10px;
	position: absolute;
	left: calc(50% + 10px);
	bottom: calc(100% + 7px);
	transform: translateX(-50%);
	&::before {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: rgba($text, .8) transparent transparent transparent;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%); } }

.required-form-name {
    display: none; }
.map-baloon {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	width: 540px;
	background-color: #fff;
	box-shadow: 0px 20px 60px rgba(87,108,120, .55);
	padding: 35px 45px 110px;
	@media (max-width: 767px) {
		width: 360px;
		padding: 32px 45px 90px 32px; }
	@media (max-width: 413px) {
		width: 300px;
		padding: 32px 45px 90px 16px; }
	@media (max-width: 374px) {
		width: 280px; }
	&__inner {
		&>ymaps {
			width: 100% !important;
			height: auto !important; } }
	&__close {
		width: 20px;
		height: 20px;
		background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' version='1.1' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.6718 19.713c-0.38245 0.3824-1.0025 0.3824-1.385 0-0.38245-0.3825-0.38245-1.0025 0-1.385l8.3281-8.3281-8.3281-8.3282c-0.38245-0.38245-0.38245-1.0025 0-1.385 0.38245-0.38245 1.0025-0.38245 1.385 0l8.3281 8.3281 8.3281-8.3282c0.3825-0.38245 1.0026-0.38245 1.385 0 0.3825 0.38245 0.3825 1.0025 0 1.385l-8.3282 8.3282 8.3282 8.3282c0.3825 0.3824 0.3825 1.0025 0 1.3849-0.3824 0.3825-1.0025 0.3825-1.385 0l-8.3281-8.3281z' fill='%23fc6d05'/%3E%3C/svg%3E");
		background-size: cover;
		position: absolute;
		top: 24px;
		right: 24px;
		@media (max-width: 767px) {
			top: 16px;
			right: 16px; } }
	&__title {
		font-family: $crpb;
		line-height: 24px;
		margin-bottom: 8px;
		a {
			color: $orange; }
		.icon {
			margin-bottom: -1px; } }
	&__address {
		line-height: 24px;
		margin-bottom: 16px; }
	&__row {
		display: flex;
		margin-bottom: 10px;
		@media (max-width: 767px) {
			display: block; } }
	&__col {
		display: inline-block;
		line-height: 20px;
		white-space: nowrap;
		border-right: 1px solid #f04638;
		padding-right: 20px;
		margin-right: 20px;
		@media (max-width: 767px) {
			display: block;
			border-right: none;
			padding-right: 0;
			margin: 0 0 8px; }
		&:last-child {
			border: none;
			padding-right: 0;
			margin: 0; } }
	&__footer {
		display: flex;
		justify-content: center;
		background-color: $bbg;
		padding: 18px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0; } }
.cursor-default {
    cursor: default; }
.cursor-pointer {
    cursor: pointer; }
.cursor-grab {
    cursor: grab; }
.cursor-text {
    cursor: text; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    // -webkit-text-fill-color: $text !important
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s; }

.new-b {
    display: none;
    @media (max-width: 1023px) {
        display: block; }
    a {
        display: block;
        height: 50px;
        background-color: $white;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; } }

.promo-timer {
    position: relative;
    max-width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 233px;
    gap: 8px 48px;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    padding: 32px 90px;
    margin: 0 0 32px;
    @media (max-width: 1023px) {
        grid-template-columns: 1fr;
        gap: 24px;
        padding: 16px 16px 20px; }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__title {
        z-index: 1;
        grid-column: 1/2;
        grid-row: 1/2;
        font-family: $crpb;
        font-size: 26px;
        line-height: 110%;
        letter-spacing: -0.52px;
        text-wrap: balance;
        @media (max-width: 1023px) {
            font-size: 24px;
            letter-spacing: -0.48px;
            text-align: center; } }
    &__text {
        z-index: 1;
        grid-column: 1/2;
        grid-row: 2/3;
        font-family: $crpm;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.16px;
        text-wrap: balance;
        @media (max-width: 1023px) {
            grid-row: 3/4;
            text-align: center; } }
    .timer {
        min-height: 59px;
        grid-column: 2/3;
        grid-row: 1/3;
        display: flex;
        @media (max-width: 1023px) {
            grid-column: 1/2;
            grid-row: 2/3;
            margin: 0 auto; }
        &__block {
            position: relative;
            width: 44px;
            height: 42px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &::after {
                content: attr(data-block-name);
                position: absolute;
                bottom: -17px;
                left: 0;
                width: 100%;
                font-size: 12px;
                line-height: 110%;
                letter-spacing: -0.24px;
                text-align: center; }
            span {
                position: relative;
                width: 100%;
                height: 20px;
                background-color: $white;
                box-shadow: 0px 20px 5px 0px rgba(0, 0, 0, 0.00), 0px 13px 5px 0px rgba(0, 0, 0, 0.01), 0px 7px 4px 0px rgba(0, 0, 0, 0.05), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
                overflow: hidden;
                &::before {
                    content: attr(data-value);
                    width: 100%;
                    position: absolute;
                    left: 0;
                    font-family: $crpb;
                    font-size: 28px;
                    line-height: 120%;
                    letter-spacing: -0.56px;
                    text-align: center; }
                &:first-child {
                    border-radius: 8px 8px 2px 2px;
                    &::before {
                        z-index: 2;
                        bottom: -90%;
                        color: #626468; } }
                &:last-child {
                    margin: 2px 0 0;
                    border-radius: 2px 2px 8px 8px;
                    &::before {
                        z-index: 1;
                        top: -90%;
                        color: $text; } } } }
        &__separation {
            color: $white;
            margin: 0 8px; } } }

.clone-contacts {
    position: fixed;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0; }
.clone-clue-contacts {
    position: fixed;
    z-index: 99999;
    display: flex;
    align-items: center;
    width: calc(195px + 20px);
    height: auto;
    background-color: $white;
    border-radius: 8px;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.14px;
    color: $text;
    padding: 6px 10px;
    opacity: 0;
    margin: 0 0 0 -20px;
    transition: opacity .3s ease;
    &::after {
        content: '';
        position: absolute;
        top: -8px;
        left: 62%;
        width: 20px;
        height: 8px;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%228%22%20viewBox%3D%220%200%2020%208%22%20fill%3D%22none%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M10%208C13%208%2015.9999%200%2020%200H0C3.9749%200%207%208%2010%208Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        transform: rotate(180deg); } }
