@import "./normalize";
@import "./vars";
@import "./mixins";
@import "./fonts";
@import "./sprite_generated";

// From libs:
@import "../../libs/bootstrap-4-grid/scss/grid.scss";
@import "../../libs/swiper/css/swiper";
@import "../../libs/chosen-js/chosen";
//@import "../../libs/ion-rangeslider/css/ion.rangeSlider"
@import "../../libs/tooltipster/dist/css/tooltipster.bundle";
@import "../../libs/tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-shadow.min";
@import "../../libs/@fancyapps/fancybox/dist/jquery.fancybox";

*, *::before, *::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    min-height: 0.001vw; } // — Fixes Safari bug with viewport units in calc()

html, body {
    font: normal normal 15px/1.2 $crp, sans-serif, Arial;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    color: $text;
    min-width: 320px;
    width: 100%; }

h1, .h1 {
    font-family: $crpb;
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -0.003em;
    font-weight: normal;
    margin: 0;
    @include fluidFontSize(32px, 40px, 375px, 768px, 36px); }
h2, .h2 {
    font-family: $crpb;
    font-size: 30px;
    line-height: 1.26;
    letter-spacing: -0.003em;
    font-weight: normal;
    margin: 0;
    @include fluidFontSize(26px, 30px, 375px, 768px, 28px); }
h3, .h3 {
    font-family: $crpb;
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: -0.003em;
    font-weight: normal;
    margin: 0; }
h4, .h4 {
    font-family: $crpb;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.003em;
    font-weight: normal;
    margin: 0; }
h5, .h5 {
    font-family: $crpb;
    font-size: 18px;
    line-height: 1.55;
    letter-spacing: -0.003em;
    font-weight: normal;
    margin: 0; }
h6, .h6 {
    font-family: $crpb;
    font-size: 15px;
    line-height: 1.5;
    letter-spacing: -0.003em;
    font-weight: normal;
    margin: 0; }

p {
    line-height: 1.6; }

a {
    color: $text;
    text-decoration: none;
    transition: all .2s ease;
    cursor: pointer;
    &:hover, &:visited, &:active, &:focus {
        text-decoration: none; }
    &.o-link {
        color: $orange;
        &:hover {
            color: $dorange; } }
    &.o-link-arrow {
        display: inline-flex;
        align-items: center;
        font-family: $crpm;
        line-height: 24px;
        white-space: nowrap;
        .icon {
            margin-left: 6px;
            //margin-bottom: -2px
            will-change: transform;
            transition: transform .2s ease; }
        &:hover {
            .icon {
                @include svg-image-next-sm-hover;
                transform: translateX(2px); } }
        &--rev {
            .icon {
                margin-right: 8px;
                margin-left: 0;
                transform: rotate(180deg); }
            &:hover {
                .icon {
                    transform: rotate(180deg) translateX(2px); } } } }


    &.b-link {
        color: $blue;
        &:hover {
            color: $dblue; } } }

img {
    display: block;
    max-width: 100%;
    height: auto; }
@supports not (-ms-high-contrast: none) {
    svg {
        display: block;
        max-width: 100%;
        height: auto; } }

path, circle {
    transition: all .2s ease; }

ul {
    display: block;
    padding: 0;
    margin: 0;
    &.list {
        li {
            position: relative;
            line-height: 1.6;
            color: $text2;
            padding-left: 17px;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0; }
            &::before {
                content: '';
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background-color: $orange;
                position: absolute;
                top: 9.5px;
                left: 0; }
            span {
                font-family: $crpb;
                color: $text; }
            a {
                color: $blue;
                &:hover {
                    color: $dblue; } } } } }

ol {
    display: block;
    padding: 0;
    margin: 0;
    &.num-list {
        counter-reset: item;
        margin-bottom: 12px;
        li {
            position: relative;
            font-size: 15px;
            line-height: 24px;
            color: $secondary;
            padding-left: 17px;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0; }
            &::before {
                content: counter(item) ".";
                counter-increment: item;
                position: absolute;
                left: 0; } } } }

li {
    list-style: none; }

form {
    display: block; }

button, input, textarea, select {
    font-family: $crp;
    line-height: normal;
    appearance: none;
    border: none;
    background: none;
    border-radius: 0;
    outline: none; }

button {
    cursor: pointer;
    &:disabled {
        cursor: default; } }

input[type=text], input[type=search], input[type=email], input[type=password], input[type=number], textarea, input[type=tel] {
    width: 100%;
    height: 44px;
    border-radius: 44px;
    background-color: $lgray;
    font-family: $crpm;
    font-size: 16px;
    padding: 0 20px;
    &::-webkit-input-placeholder {
        font-family: $crp;
        color: $text2;
        transition: opacity .2s ease; }
    &::-moz-placeholder, &:-moz-placeholder {
        font-family: $crp;
        color: $text2;
        transition: opacity .2s ease; }
    &:-ms-input-placeholder {
        font-family: $crp;
        color: $text2;
        transition: opacity .2s ease; }
    &:focus {
        &::-webkit-input-placeholder {
            opacity: 0; }
        &::-moz-placeholder, &:-moz-placeholder {
            opacity: 0; }
        &:-ms-input-placeholder {
            opacity: 0; } } }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
    -webkit-appearance: none; }

input[type=number] {
    background-color: #fff;
    border-radius: 6px;
    height: 36px;
    padding: 0 12px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; } }

input[type=password] {
    letter-spacing: 6px;
    &::-webkit-input-placeholder {
        letter-spacing: 0; }
    &::-moz-placeholder, &:-moz-placeholder {
        letter-spacing: 0; }
    &:-ms-input-placeholder {
        letter-spacing: 0; } }

textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 40px;
    height: auto; }

.input-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden; }

.rub {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
    opacity: 0; }

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }

section {
    position: relative; }

.layout {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    &--white::before {
        content: '';
        position: absolute;
        z-index: 1001;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $white; }
    &-product,
    &-cart {
        overflow: visible;
        .header {
            box-shadow: none;
            // overflow: hidden
            @media (max-width: 1023px) {
                .h-location,
                .header__action--compare,
                .header__logo p {
                    display: none; } } }
        @media (max-width: 1023px) {
            .header-filler {
                height: 78px; }
            .header__main {
                border-bottom: 1px solid #EAEFF6;
                padding: 8.5px 0 11.5px !important; } } }
    &.layout-bg-dark {
        &::before {
            content: '';
            position: absolute;
            z-index: 9999;
            width: 100%;
            height: 100%;
            opacity: 0.4;
            background-color: #031236; } } }
.container {}
.container {
    padding: 0 60px;
    margin: 0 auto;
    @media (max-width: 1279px) {
        padding: 0 16px; } }

.mob-hidden {
    @media (max-width: 1023px) {
        display: none !important; }
    &.xs-visible {
        @media (max-width: 479px) {
            display: block !important; } } }
.desk-hidden {
    @media (min-width: 1024px) {
        display: none !important; } }
.sm-hidden {
    @media (max-width: 767px) {
        display: none !important; } }
.sm-visible {
    @media (min-width: 768px) {
        display: none !important; } }
.xs-hidden {
    @media (max-width: 479px) {
        display: none !important; } }
.xs-visible {
    @media (min-width: 480px) {
        display: none !important; } }

.object-fit-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;'; }

.icon {
    display: inline-block;
    flex-shrink: 0; }

.lh-0 {
    line-height: 0; }

.tar {
    text-align: right; }

.tab:not(.is-active) {
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    padding: 0; }

.bg-white {
    background-color: #fff; }

.bg-gray {
    background-color: $bbg; }

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg); }
    100% {
        transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes scrollsticky {
    0% {
        transform: translateY(-100%); }
    100% {
        transform: translateY(0); } }

.preload .preload__svg circle {
    animation: animcircles 1s ease infinite;
    &:nth-child(3) {
        animation-delay: 0.1s; }
    &:nth-child(5) {
        animation-delay: 0.2s; }
    &:nth-child(6) {
        animation-delay: 0.3s; }
    &:nth-child(9) {
        animation-delay: 0.4s; }
    &:nth-child(8) {
        animation-delay: 0.5s; }
    &:nth-child(7) {
        animation-delay: 0.6s; }
    &:nth-child(2) {
        animation-delay: 0.7s; }
    &:nth-child(4) {
        animation-delay: 0.8s; }
    // &:nth-child(1), &:nth-child(5), &:nth-child(8)
    //     animation-delay: 0.3s
    // &:nth-child(4), &:nth-child(7), &:nth-child(9)
 }    //     animation-delay: 0.6s

.preload-promocode .preload__svg circle {
    animation: animcircles 1s ease infinite;
    &:nth-child(2) {
        animation-delay: 0.1s; }
    &:nth-child(3) {
        animation-delay: 0.2s; } }

.btn .preload__svg circle {
    animation: animcircles 1s ease infinite;
    &:nth-child(2) {
        animation-delay: 0.1s; }
    &:nth-child(3) {
        animation-delay: 0.2s; } }
@keyframes animcircles {
    0% {
        fill-opacity: 1; }
    100% {
        fill-opacity: 0; } }

.page {
    padding-top: 42px;
    @media (max-width: 767px) {
        padding-top: 8px; }
    &__title {
        margin-bottom: 30px;
        @media (max-width: 767px) {
            margin-bottom: 20px; }
        .title-text {
            position: relative;
            display: inline-flex;
            padding-right: 30px;
            @include fluidFontSize(24px, 40px, 320px, 768px, 30px); }
        h1 {
            position: relative;
            display: inline-block;
            text-wrap: balance;
            @include fluidFontSize(24px, 40px, 320px, 768px, 30px);
            margin: 0; }
        p:not(.descr) {
            display: inline-block;
            font-family: $crp;
            font-size: 0.45em;
            color: $text2;
            line-height: 1;
            vertical-align: super; }
        .descr {
            font-size: 14px;
            line-height: 24px;
            margin-top: 10px; } } }

.is-shrink {
    span {
        display: none; }
    .js-expand {
        span {
            display: inline; }
        svg {
            transform: rotate(0deg); } } }

.descr-preview {
    $dp: &;
    input, a {
        opacity: 0;
        position: absolute;
        pointer-events: none; }
    @media (max-width: 767px) {
        a {
            color: $blue;
            i {
                color: $text2; }
            svg {
                fill: currentColor;
                transform: rotate(180deg); } }

        .descr-expand {
            display: inline; }

        .js-descr-expand {
            opacity: 1;
            position: static;
            pointer-events: all; }

        &--short {
            a {
                opacity: 1;
                position: static;
                pointer-events: all;
                svg {
                    transform: rotate(0deg); } }

            .descr-expand {
                span {
                    display: none; } }

            .js-descr-more {
                display: block;
                text-align: right;
                margin-bottom: -10px; }

            .descr-more {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden; } } } }

.scroll-wrap {
    @include scroll-wrap;
    &::-webkit-scrollbar {
        width: 4px; }
    &::-webkit-scrollbar-track {
        border: 0; }
    &::-webkit-scrollbar-thumb {
        background: $orange;
        border-radius: 5px; } }
.scroll-wrap-gray {
    @include scroll-wrap-gray;
    &::-webkit-scrollbar {
        width: 4px; }
    &::-webkit-scrollbar-track {
        border: 0; }
    &::-webkit-scrollbar-thumb {
        background: $gray;
        border-radius: 5px; } }

.popup .contact-form .swiper-wrapper {
    height: fit-content !important; }

.sec-reviews-link {
    padding: 32px 0;
    &__text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $orange;
        span {
            margin-bottom: -2px;
            margin-left: 7px; } } }
.dimens + .sec-reviews-link {
    margin-top: 60px; }
.page--product-list .sec-carousel--shadow + .sec-reviews-link {
    margin-top: -68px;
    @media (max-width: 767px) {
        margin-top: -44px; } }

.page--product-list .page-container > .container + .sec-reviews-link {
    margin-top: -44px; }
.is-place {
    display: none;
    &.js-active .is-place__wrap {
        transform: translateY(-60px) translateX(-50%); } }

.image-wrap {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; }
    img {
        width: 100%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    img, .picture {
        display: none; }
    .picture.active,
    .picture.active img {
        display: block; }
    @media (max-width: 1024px) {
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding-bottom: 0;

        .picture {
            flex: 0 0 100%; }
        img {
            display: block;
            position: static;
            top: 0;
            left: 0;
            transform: none; } } }

.select-like {
    position: relative;
    cursor: pointer;
    width: 100%;
    &::after {
        content: '';
        width: 10px;
        height: 6px;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.00053 0.889876C1.35995 0.47055 1.99125 0.421989 2.41058 0.781411L5.25978 3.22359L8.10899 0.781411C8.52832 0.421989 9.15962 0.47055 9.51904 0.889876C9.87846 1.3092 9.8299 1.9405 9.41057 2.29992L5.91057 5.29992C5.53609 5.62092 4.98348 5.62092 4.60899 5.29992L1.10899 2.29992C0.689667 1.9405 0.641105 1.3092 1.00053 0.889876Z' fill='%23FC6D05'/%3E%3C/svg%3E");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translate(0,-50%);
        filter: brightness(0);
 }        //transition: filter .2s ease

    &:hover {
        &::after {
            filter: brightness(100%);
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.00053 0.889876C1.35995 0.47055 1.99125 0.421989 2.41058 0.781411L5.25978 3.22359L8.10899 0.781411C8.52832 0.421989 9.15962 0.47055 9.51904 0.889876C9.87846 1.3092 9.8299 1.9405 9.41057 2.29992L5.91057 5.29992C5.53609 5.62092 4.98348 5.62092 4.60899 5.29992L1.10899 2.29992C0.689667 1.9405 0.641105 1.3092 1.00053 0.889876Z' fill='%23FC6D05'/%3E%3C/svg%3E"); } }

    span {
        display: block;
        padding: 0 34px 0 12px;
        line-height: 32px;
        font-family: $crpm;
        background: #fff;
        color: #313339;
        border: 2px solid #dce4ef;
        border-radius: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.empty {
            font-family: $crp; } }

    &--lg {
        &::after {
            width: 13px;
            height: 8px;
            background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' version='1.1' viewBox='0 0 13 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0.78709 0.7879c0.38278-0.38387 1.0034-0.38387 1.3862 0l4.3267 4.339 4.3267-4.339c0.38286-0.38387 1.0034-0.38387 1.3862 0 0.38286 0.38387 0.38286 1.0063 0 1.3901l-5.0198 5.0341c-0.38279 0.38387-1.0034 0.38387-1.3862 0l-5.0198-5.0341c-0.38278-0.38387-0.38278-1.0063 0-1.3901z' clip-rule='evenodd' fill='%23FC6D05' fill-rule='evenodd' stroke-width='1'/%3E%3C/svg%3E"); }

        span {
            padding: 0 20px;
            font-family: $crpm;
            line-height: 36px; } } }

.filter__item {
    .select-like {
        span {
            border: 2px solid #ffffff; } } }

.select {
    position: relative;
    select, .pseudo-select {
        width: 100%;
        background: #fff;
        box-shadow: none;
        border: 2px solid $gray;
        border-radius: 6px;
        color: $text;
        padding: 0 44px 0 18px;
        height: 48px;
        line-height: 44px;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' fill='none' version='1.1' viewBox='0 0 13 8' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m0.78709 0.7879c0.38278-0.38387 1.0034-0.38387 1.3862 0l4.3267 4.339 4.3267-4.339c0.38286-0.38387 1.0034-0.38387 1.3862 0 0.38286 0.38387 0.38286 1.0063 0 1.3901l-5.0198 5.0341c-0.38279 0.38387-1.0034 0.38387-1.3862 0l-5.0198-5.0341c-0.38278-0.38387-0.38278-1.0063 0-1.3901z' clip-rule='evenodd' fill='%23313339' fill-rule='evenodd' stroke-width='1'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 13px 8px;
        background-position: right 16px center; }
    &--sm {
        select, .pseudo-select {
            padding: 0 38px 0 12px;
            height: 40px;
            font-family: $crpm;
            line-height: 36px;
            background-position: right 12px center; } }
    &--xs {
        select, .pseudo-select {
            padding: 0 34px 0 12px;
            height: 36px;
            font-family: $crpm;
            line-height: 32px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.00053 0.889876C1.35995 0.47055 1.99125 0.421989 2.41058 0.781411L5.25978 3.22359L8.10899 0.781411C8.52832 0.421989 9.15962 0.47055 9.51904 0.889876C9.87846 1.3092 9.8299 1.9405 9.41057 2.29992L5.91057 5.29992C5.53609 5.62092 4.98348 5.62092 4.60899 5.29992L1.10899 2.29992C0.689667 1.9405 0.641105 1.3092 1.00053 0.889876Z' fill='%23313339'/%3E%3C/svg%3E");
            background-size: 10px 6px;
            background-position: right 12px center; } }
    &--input {
        select, .pseudo-select {
            padding: 0 34px 0 12px;
            height: 44px;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid $gray;
            line-height: 44px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.00053 0.889876C1.35995 0.47055 1.99125 0.421989 2.41058 0.781411L5.25978 3.22359L8.10899 0.781411C8.52832 0.421989 9.15962 0.47055 9.51904 0.889876C9.87846 1.3092 9.8299 1.9405 9.41057 2.29992L5.91057 5.29992C5.53609 5.62092 4.98348 5.62092 4.60899 5.29992L1.10899 2.29992C0.689667 1.9405 0.641105 1.3092 1.00053 0.889876Z' fill='%23FC6D05'/%3E%3C/svg%3E");
            background-size: 10px 6px;
            background-position: right 12px center; } }
    &--color {
        .chosen-single span {
            padding-left: 30px; }
        .chosen-single span, .chosen-container .chosen-results li.active-result , .chosen-container .chosen-results li.highlighted {
            background-repeat: no-repeat;
            background-position: 0 50%; }
        .chosen-container .chosen-results li.active-result , .chosen-container .chosen-results li.highlighted {
            background-position: 14px 50%;
            padding-left: 44px; } }
    .pseudo-select {
        background: none; } }
