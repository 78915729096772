.tabs {
	.counter {
		flex-shrink: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 18px;
		width: fit-content;
		height: 18px;
		border-radius: 100%;
		background-color: $blue;
		font-family: $crpb;
		font-size: 10px;
		line-height: 1;
		color: #fff;
		margin-left: 12px;
		padding: 0 6px; } }

.p-tabs {
	width: 100%;
	margin-top: 70px;
	@media (max-width: 1023px) {
		width: calc(100% + 24px);
		margin: 0 -12px; }
	.row {
		@media (min-width: 1400px) {
			margin-left: 0;
			margin-right: -24px; } }
	&__links {
		position: relative;
		display: flex;
		@media (max-width: 1023px) {
			display: none; }
		&::after {
			content: '';
			display: block;
			height: 1px;
			background-color: $lgray;
			position: absolute;
			left: -50vw;
			right: 0;
			bottom: 0; }
		@media (min-width: 1400px) {
			padding: 0; }
		@media (max-width: 1399px) {
			justify-content: space-between;
			&::after {
				right: 12px; } }

		&--five {
			.p-tabs__link--hid {
				display: flex; } } }
	&__link {
		position: relative;
		display: flex;
		align-items: center;
		overflow: hidden;
		margin-right: 47px;
		z-index: 1;
		@media (max-width: 1399px) {
			margin-right: 5px; }
		@media (max-width: 1279px) {
			font-size: 16px; }
		&:last-child {
			margin-right: 0; }
		a,
		button {
			position: relative;
			padding: 20px 0;
			font-size: 18px;
			line-height: 20px;
			color: $text2;
			overflow: hidden;
			&:hover {
				color: $text; }
			&::after {
				content: '';
				display: block;
				height: 2px;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				transition: background .2s ease; }
			span {
				display: block;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden; } }

		&.is-active {
			a,
			button {
				color: $text;
				&::after {
					background-color: $orange; } } }
		&--hid {
			display: none;
			@media (min-width: 1530px) {
				display: flex; } } }

	&__tab {
		padding-top: 44px;
		opacity: 1;
		@media (min-width: 1400px) {
			padding-right: 0;
			padding-left: 0; }
		@media (max-width: 1023px) {
			visibility: visible !important;
			overflow: visible !important;
			opacity: 1 !important;
			max-height: none !important;
			padding: 0 12px !important;
			&.is-open {
				.p-tabs__tab-head {
					h5 {
						font-family: $crpb; }
					.icon:not(.svg-image-star-uslugi) {
						transform: rotate(180deg);
						filter: brightness(0); } } } }
		&-head {
			display: none;
			@media (max-width: 1023px) {
				position: relative;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-top: 1px solid $gray;
				background-color: $bbg;
				font-size: 18px;
				line-height: 20px;
				padding: 20px 16px;
				margin: 0 -16px;
				cursor: pointer;
				h5, .h5 {
					font-family: $crp;
					line-height: 20px;
					display: flex;
					align-items: center; }
				.icon {
					filter: brightness(100%); } } }
		&-body {
			@media (min-width: 1024px) {
				display: block !important; }
			@media (max-width: 1023px) {
				display: none;
				padding: 32px 0 40px; }
			.tab-flex {
				display: flex;
				align-items: flex-start;
				@media (max-width: 1023px) {
					display: block; } } } } }
