.product {
    .stockout {
        display: none; }

    &--stockout {
        .p-btns, .p-bottom-btn, .p-additional {
            display: none; }

        .stockout {
            display: block;
            &--subscribed {
                display: none; } }

        &.subscribed {
            .stockout {
                &--subscribe {
                    display: none; }
                &--subscribed {
                    display: block; } } } } }

.p-card {
    .status--not-available {
        display: none; }
    .status--available {
        display: block; }

    .pc-rating {
        .p-card__stock {
            margin-left: auto; } }

    .stockout {
        display: none; }
    &--stockout {
        &:hover .p-card__hover .pc-descr {
            display: none; }

        .pc-descr, .pc-delivery, .p-card__btns, .pc-quick-order {
            display: none; }

        .stockout {
            display: block;
            order: 4; }

        .status--not-available {
            display: block; }
        .status--available {
            display: none; } } }

.page--product-list {
    .p-list__grid[data-temp="list"] {
        @media (min-width: 1280px) {
            .pc-rating {
                .stockout, .stockin {
                    display: none; } } } }
    .p-list__grid[data-temp="grid"] .p-card__size {
        .stockout, .stockin {
            display: none; } }

    .p-list__grid[data-temp="list"] .p-card--stockout .p-card__hover {
        .pc-quick-order, .pc-delivery {
            display: none; } } }
