$skl_main: #F7F7F7;
$skl_dark: #EEEEEE;
$skl_bg: #F6F6F6;
$skl_radius: 12px;

@mixin sklLayout($width, $height) {
    display: block;
    width: 100%;
    @if ($width) {
        max-width: $width; }
    @if ($height) {
        height: $height; }
    background: $skl_main;
    border-radius: $skl_radius; }

@mixin sklLayoutDark($width, $height) {
    display: block;
    width: 100%;
    @if ($width) {
        max-width: $width; }
    @if ($height) {
        height: $height; }
    background: $skl_dark;
    border-radius: $skl_radius; }

@keyframes shine {
    to {
        background-position-x: -200%; } }

.skeleton {
    $skl: &;
    & + div, & + section {
        display: none; }

    span, strong, .filters-main {
        position: relative;
        overflow: hidden;
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(110deg, #ececec 8%, #f5f5f5 20%, #ececec 32%);
            border-radius: 5px;
            background-size: 200% 100%;
            animation: 1.5s shine linear infinite; } }

    .filters-main {
        @media (max-width: 1023px) {
            display: none;
            &::after {
                content: none; } } }

    .filters-sort {
        display: none;
        @media (max-width: 1023px) {
            display: block;
            padding: 0 0 16px; } }

    .filters-main__list--mob {
        @media (max-width: 1023px) {
            padding: 0; } }

    span {
        @include sklLayout(false, 15px); }

    strong {
        @include sklLayout(210px, 26px);
        @media (min-width: 1024px) {
            max-width: 260px; } }

    // sliders like Лидеры продаж
    .sec-title {
        span {
            margin-top: 15px;
            max-width: 120px;
            @media (min-width: 768px) {
                margin-top: 0; } } }

    .sec-descr {
        span {
            margin-bottom: 13px;

            &:nth-child(2) {
                max-width: 60%;
                margin-bottom: 0; } } }

    .p-carousel {
        display: flex;
        justify-content: space-between;
        div {
            max-width: 330px;
            min-width: 290px;
            width: 25%;
            min-height: 380px;
            padding: 30px 32px;
            @media (min-width: 768px) {
                padding: 32px; } }

        span {
            &:nth-child(1) {
                height: 156px;
                margin-bottom: 44px; }

            &:nth-child(2) {
                margin-bottom: 13px; }

            &:nth-child(3) {
                max-width: 67.67%;
                margin-bottom: 49px; }

            &:nth-child(4) {
                max-width: 45.113%; } }

        em {
            @include sklLayout(228px, 48px);
            margin-top: 26px;
            border-radius: $skl_radius*2;
            @media (min-width: 768px) {
                display: none;
                margin-top: 0; } } }

    .p-card {
        div {
            margin-bottom: 16px;
            &:nth-child(1) {
                span {
                    background: $skl_main;
                    position: relative;
                    display: block;
                    width: 100%;
                    padding-bottom: 75%;
                    margin-bottom: 0;
                    transition: none; } }
            &:nth-child(2) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-left: 16px;
                padding-right: 16px;
                @include m(768px) {
                    padding-left: 0;
                    padding-right: 0; }
                strong {
                    margin-bottom: 16px; }
                span {
                    &:nth-child(2), &:nth-child(3) {}
                    margin-bottom: 32px;
                    &:nth-child(2) {
                        max-width: 50%; }
                    &:nth-child(3) {
                        max-width: 25%;
                        margin-left: auto; }
                    &:nth-child(4) {
                        width: 100%;
                        height: 36px;
                        margin-bottom: 16px; }
                    &:nth-child(5) {
                        height: 28px;
                        max-width: 30%; }
                    &:nth-child(6) {
                        height: 28px;
                        margin-left: 10px;
                        max-width: 25%; } } }

            &:nth-child(3) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-left: 16px;
                padding-right: 16px;
                @include m(768px) {
                    display: none; }

                span {
                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        width: 48px;
                        height: 48px;
                        border-radius: 48px;
                        margin-left: 10px; }
                    &:nth-child(1) {
                        flex-grow: 1;
                        margin-left: 0; }

                    &:nth-child(4) {
                        margin-top: 20px;
                        margin-bottom: 10px; }
                    &:nth-child(5) {
                        margin-bottom: 10px; }

                    &:nth-child(6) {
                        max-width: 75%; } } } } }

    .bullets {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            width: 40px;
            height: 2px;
            margin: 0 7px;
            &:first-child {
                height: 4px;
                background: $skl_dark; }
            @media (min-width: 768px) {
                &:last-child {
                    display: none; } } } }


    // banner between sliders
    &__row-banner {
        display: none;
        @include m(1024px) {
            @include sklLayout(100%, 160px);
            margin-bottom: 60px; } }

    &.m-benefits {
        span {
            margin-bottom: 13px;

            &:last-child {
                max-width: 60%;
                margin-bottom: 0; }

            @media (min-width: 768px) {
                display: none;
                &:nth-child(n+3) {
                    display: block; } } } }

    .similar-cats {

        &__title {
            span {
                max-width: 150px; } }

        &__items {
            span {
                max-width: 105px;
                margin: 8px 32px 8px 0; } } }

    &.pop-sizes {
        background: $skl_bg;
        @media (max-width: 1023px) {
            border-radius: 40px 40px 0 0; } }

    .pop-sizes {
        &__title {
            margin-bottom: 20px;
            strong {
                background: $skl_dark;
                @media (max-width: 1023px) {
                    margin: 0 auto; } }

            span {
                max-width: 150px;
                background: $skl_dark;
                @media (max-width: 1023px) {
                    display: none; } } }

        &__list {
            span {
                @include sklLayoutDark(105px, false);
                margin-bottom: 20px;
                @media (max-width: 1023px) {
                    margin-left: auto;
                    margin-right: auto; } } }

        &__contacts {
            padding-top: 12px;
            @media (max-width: 1023px) {
                padding-top: 29px; }
            span {
                @include sklLayoutDark(220px, false);
                margin-bottom: 10px; } }
        &__contact {
            @media (max-width: 1023px) {
                width: 100%; } } }

    &.sec-reviews {
        .sec-reviews__title {
            strong {
                margin-bottom: 12px;
                &:last-of-type {
                    max-width: 140px;
                    margin-bottom: 21px; } }
            span {
                margin-bottom: 10px;
                &:last-of-type {
                    max-width: 60%; } } }

        .rev-item {
            border: 0;

            &__text {
                @media (max-width: 1023px) {
                    width: 100%; }
                span {
                    margin-bottom: 10px;

                    &:nth-child(1) {
                        max-width: 160px;
                        margin-bottom: 17px; }

                    &:nth-child(2) {
                        max-width: 230px;
                        margin-bottom: 21px; } } }

            &__img {
                @media (max-width: 767px) {
                    width: 100%; }
                span {
                    height: 128px;
                    @media (max-width: 1023px) {
                        height: 196px; } } } }

        .sec-reviews__btns {
            span {
                height: 44px;
                margin-right: 12px;
                border-radius: 22px;
                &:last-child {
                    display: none; }
                @media (min-width: 768px) {
                    max-width: 185px;
                    &:last-child {
                        display: block; } } } } }

    &.useful-articles {
        @media (max-width: 1023px) {
            border-radius: 40px 40px 0 0; } }

    .useful-articles {
        &__title {
            span {
                max-width: 120px;
                margin-top: 17px; }

            @media (max-width: 1023px) {
                strong {
                    max-width: 260px; }
                strong, span {
                    margin-left: auto;
                    margin-right: auto; } } }

        &__list {
            display: flex; }

        &__item {
            display: flex;
            flex-direction: column;
            min-width: 312px;
            height: 416px;
            margin-right: 24px;
            padding: 16px;
            background: $skl_bg;
            border-radius: $skl_radius;
            span {
                background: $skl_dark;
                margin-bottom: 13px;

                &:nth-child(1) {
                    max-width: 120px;
                    height: 24px;
                    margin-bottom: 267px; }

                &:last-child {
                    max-width: 124px; } } }

        &__btns {
            position: absolute;
            top: -86px;
            right: 0;
            display: flex;
            @media (max-width: 1023px) {
                display: none; }
            span {
                width: 52px;
                height: 52px;
                border-radius: 52px;
                margin-left: 8px; } } }

    .cat-links {
        &__list {
            span {
                margin-bottom: 21px;
                &:nth-child(1) {
                    max-width: 120px;
                    margin-bottom: 24px; } } } }

    &.call-banner {
        @media (max-width: 1023px) {
            padding: 0 0 160px; } }
    .call-banner {
        &__bg {
            @media (max-width: 1023px) {
                height: 160px; }
            &::before {
                border-radius: $skl_radius;
                background: $skl_bg;
                width: 100%;
                max-width: 280px;
                height: 140px;
                transform: translateX(-50%); }
            @media (min-width: 1024px) {
                &::before {
                    max-width: 600px;
                    height: 400px;
                    border-radius: $skl_radius;
                    background: $skl_bg;
                    transform: translateX(0); } } }

        &__content {
            span {
                max-width: 348px;
                margin-top: 28px;
                &:nth-child(3) {
                    margin-top: 9px; } } }

        &__form {
            display: flex;
            margin-top: 78px;
            max-width: 600px;
            flex-wrap: wrap;

            @media (max-width: 1023px) {
                flex-direction: column; }
            span {
                margin-top: 0;
                height: 48px;
                border-radius: $skl_radius*2;
                max-width: 240px;
                &:nth-child(2) {
                    margin-left: 18px;
                    max-width: 204px; }
                &:last-child {
                    height: 15px;
                    margin-top: 27px;
                    max-width: 348px; }

                @media (max-width: 1023px) {
                    min-width: 100%;
                    width: 100%;
                    &:nth-child(2) {
                        height: 15px;
                        max-width: 70%;
                        min-width: 70%;
                        margin-top: 17px;
                        margin-left: 0; }
                    &:last-child {
                        height: 48px;
                        margin-top: 50px; } } } } }


    &.footer__menu {
        .container {
            @media (max-width: 1023px) {
                overflow: hidden; } } }
    .footer__menu {
        &-list {
            display: flex;
            justify-content: space-between; }

        &-item {
            width: 16.8%;
            max-width: 158px;
            min-width: 128px;
            padding: 0;

            @media (max-width: 1023px) {
                margin-right: 36px; }

            span {
                margin-left: 0; } }

        &-thumb {
            span {
                width: 52px;
                height: 52px; } }

        &-link {
            flex-grow: 1;
            padding-left: 16px;
            span {
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0px; } } } }

    &.footer__main {
        background: $skl_bg;

        strong, span {
            background: $skl_dark; } }

    .footer__main {
        &-title {
            span {
                &:nth-child(1) {
                    max-width: calc(100% - 72px);
                    height: 18px;
                    margin-bottom: 10px; }
                &:nth-child(2) {
                    max-width: calc(100% - 42px); }

                @media (max-width: 1023px) {
                    margin-left: auto;
                    margin-right: auto; } } }

        &-subscribe {
            span {
                max-width: calc(100% - 164px);
                height: 44px;
                border-radius: $skl_radius*2;

                @media (max-width: 1023px) {
                    min-width: 100%; } } }

        &-social {
            display: flex;
            justify-content: space-between;
            span {
                width: 20px;
                height: 20px;
                border-radius: $skl_radius/2;
                @media (max-width: 1279px) {
                    width: 48px;
                    height: 48px;
                    border-radius: $skl_radius*4; } } }

        &-list {
            span {
                max-width: 200px;
                margin-top: 25px;
                &:first-child {
                    max-width: 120px;
                    height: 18px;
                    margin-top: 0;
                    margin-bottom: -1px; }
                @media (max-width: 767px) {
                    max-width: 140px;
                    margin-right: calc(50% - 152px);
                    &:first-child {
                        margin-right: calc(100% - 120px); } } }
            @media (max-width: 1023px) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                & + .footer__main-list {
                    display: block;
                    span {
                        &:first-child {
                            margin-top: 9px; } } } } }

        &-location {
            span {
                margin-bottom: 8px;
                &:nth-child(1) {
                    max-width: 42.8%;
                    height: 18px; }
                &:nth-child(2) {
                    margin-bottom: 32px; }
                @media (max-width: 767px) {
                    &:nth-child(2) {
                        max-width: 80%; } } }

            .fm-location__item {
                @media (max-width: 767px) {
                    display: block; } } }

        &-contacts {
            span {
                width: 50%;
                max-width: 146px;
                margin-right: 8px;
                height: 44px; }

            .fm-contacts {
                border: 0; }

            .fm-contacts__item {
                @media (max-width: 1279px) {
                    &:nth-child(3) {
                        strong {
                            display: none; } } } }

            .apps {
                @media (max-width: 767px) {
                    display: none; }
                span {
                    width: 146px;
                    height: 44px; } } }

        &-apps {
            margin-top: 20px;

            @media (min-width: 768px) {
                margin-top: 0; }

            span {
                height: 48px;
                margin: 10px 0;
                border-radius: $skl_radius*2; } } }

    &.footer__bottom {
        background: $skl_bg;

        strong, span {
            background: $skl_dark; } }

    .footer__copyright {
        span {
            max-width: 200px;
            margin-bottom: 5px; } }

    .footer__bottom-text {
        span {
            max-width: 510px;
            margin-bottom: 5px;
            &:last-child {
                max-width: 70%; }
            @media (min-width: 480px) {
                &:last-child {
                    display: none; } } } }



    .yam-rating-wrap {
        width: 100%;

        @media (max-width: 767px) {
            padding-top: 36px;
            margin-bottom: 64px; }

        span {
            max-width: 240px;
            @media (max-width: 767px) {
                margin-left: auto;
                margin-right: auto; } } }

    .payment-ways {
        display: flex;

        span {
            width: 56px;
            height: 35px;
            margin-right: 4px;
            border-radius: $skl_radius/2;
            @media (max-width: 767px) {
                width: 83px; } } }

    &.skeleton__new {
        display: block;
        padding-bottom: 0;
        @media (max-width: 1023px) {
            margin: 0 -4px; }
        .p-carousel {
            padding-bottom: 0;
            @media (max-width: 1023px) {
                flex-wrap: wrap; }
            div {
                max-width: 100%;
                min-width: auto;
                width: 100%;
                padding: 16px;
                @media (max-width: 1023px) {
                    max-width: calc(50% - 6.5px);
                    padding: 0 0 32px; } }
            span {
                &:nth-child(1) {
                    height: 197px;
                    margin-bottom: 21px;
                    @media (max-width: 1023px) {
                        height: 209.13px;
                        margin-bottom: 0; } }
                &:nth-child(2) {
                    max-width: 170px;
                    width: 100%;
                    height: 22px;
                    margin-bottom: 4px;
                    @media (max-width: 1023px) {
                        height: 21.59px;
                        margin-top: 14px; } }
                &:nth-child(3) {
                    max-width: 125px;
                    width: 100%;
                    height: 15px;
                    margin-bottom: 10px;
                    @media (max-width: 1023px) {
                        margin-bottom: 8px; } }
                &:nth-child(4) {
                    max-width: 100%;
                    width: 100%;
                    height: 40.5px;
                    margin-bottom: 12px;
                    @media (max-width: 1023px) {
                        height: 27.38px; } }
                &:nth-child(5) {
                    max-width: 150px;
                    width: 100%;
                    height: 17px;
                    margin-bottom: 12px;
                    @media (max-width: 1023px) {
                        width: 100%;
                        height: 32px; } }
                &:nth-child(6) {
                    max-width: 130px;
                    width: 100%;
                    height: 20px;
                    margin-bottom: 0;
                    @media (max-width: 1023px) {
                        height: 16px;
                        margin-top: 8px; } } } } }

    &.skeleton__rec {
        display: block;
        background-color: $bbg;
        border-radius: 20px;
        padding: 82px 24px 24px !important;
        margin: 48px 0 0;
        overflow: hidden;
        @media (max-width: 733px) {
            padding: 52px 16px 16px !important;
            margin: 16px 0 48px; }
        &::before,
        &::after {
            content: none; }
        .p-carousel {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            gap: 24px 16px;
            padding-bottom: 0;
            @media (max-width: 1180px) {
                grid-template-columns: repeat(5, 1fr); }
            @media (max-width: 733px) {
                grid-template-columns: repeat(3, 1fr);
                gap: 8px; }
            div {
                max-width: 100%;
                min-width: auto;
                width: 100%;
                min-height: 258px;
                background-color: $white;
                border-radius: 12px;
                padding: 8px 8px 19px;
                @media (max-width: 733px) {
                    min-height: 183px;
                    padding: 4px 4px 6px; } }
            span {
                &:nth-child(1) {
                    height: 135px;
                    border-radius: 8px 8px 0px 0px;
                    margin-bottom: 4px;
                    @media (max-width: 733px) {
                        height: 75px;
                        margin-bottom: 2px; }
                    &::after {
                        border-radius: 0; } }
                &:nth-child(2) {
                    max-width: 100%;
                    width: 100%;
                    height: 20px;
                    display: flex;
                    border-radius: 0;
                    align-items: center;
                    background-color: transparent;
                    margin-bottom: 6px;
                    @media (max-width: 733px) {
                        flex-direction: column;
                        align-items: baseline;
                        height: auto;
                        padding: 0.5px 0;
                        margin-bottom: 4px; }
                    &::after {
                        content: none; }
                    span {
                        border-radius: 12px;
                        &:nth-child(1) {
                            width: 80px;
                            height: 20px;
                            margin-bottom: 0;
                            @media (max-width: 733px) {
                                height: 16px;
                                margin-bottom: 2px; } }
                        &:nth-child(2) {
                            width: 40px;
                            height: 14px;
                            margin-left: 6px;
                            margin-bottom: 0;
                            @media (max-width: 733px) {
                                margin-left: 0;
                                height: 12px; }
                            &::after {
                                content: ''; } } } }
                &:nth-child(3) {
                    max-width: 100%;
                    width: 100%;
                    height: 18px;
                    border-radius: 4px;
                    margin-bottom: 6px;
                    @media (max-width: 733px) {
                        height: 12px;
                        margin-bottom: 7px; } }
                &:nth-child(4) {
                    max-width: 141px;
                    width: 100%;
                    height: 12px;
                    margin-bottom: 4px;
                    @media (max-width: 733px) {
                        max-width: 57px;
                        height: 10px;
                        margin-bottom: 2px; } }
                &:nth-child(5) {
                    max-width: 100%;
                    width: 100%;
                    height: 12px;
                    margin-bottom: 6px;
                    @media (max-width: 733px) {
                        width: 100%;
                        height: 10px;
                        margin-bottom: 7px; } }
                &:nth-child(6) {
                    max-width: 100%;
                    width: 100%;
                    height: 14px;
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    margin-bottom: 0;
                    &::after {
                        content: none; }
                    @media (max-width: 733px) {
                        height: 13px; }
                    span {
                        margin-bottom: 0;
                        &:nth-child(1) {
                            width: 14px;
                            height: 14px;
                            border-radius: 50%;
                            @media (max-width: 733px) {
                                width: 12px;
                                height: 12px; } }
                        &:nth-child(2) {
                            width: 20px;
                            height: 12px;
                            border-radius: 12px;
                            margin-left: 2px;
                            @media (max-width: 733px) {
                                width: 16px;
                                height: 10px; }
                            &::after {
                                content: ''; } }
                        &:nth-child(3) {
                            width: 72px;
                            height: 12px;
                            border-radius: 12px;
                            margin-left: 8px;
                            @media (max-width: 733px) {
                                margin-left: 6px;
                                width: 26px;
                                display: flex;
                                align-items: center;
                                background-color: transparent;
                                &::before,
                                &::after {
                                    position: relative; }
                                &::before {
                                    content: '';
                                    width: 12px;
                                    height: 12px;
                                    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 20%, #ececec 32%);
                                    border-radius: 50%;
                                    background-size: 200% 100%;
                                    animation: 1.5s shine linear infinite; }
                                &::after {
                                    width: 12px;
                                    height: 10px;
                                    margin-left: 2px; } } } } } } } } }

.skeleton-photo {
    background: $skl_main;
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: 0;
    transition: none;
    border-radius: 12px;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 20%, #ececec 32%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite; }
    background: $skl_main;
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 75%;
    margin-bottom: 0;
    transition: none;
    border-radius: 12px;
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 20%, #ececec 32%);
        border-radius: 5px;
        background-size: 200% 100%;
        animation: 1.5s shine linear infinite; } }
