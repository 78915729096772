.tabbar {
    z-index: 999;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    &__list {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 60px;
        background: #FFFFFF;
        box-shadow: 0px 20px 60px rgba(87, 108, 120, 0.24); }

    &__item {
        flex: 0 0 20%;

        svg {
            fill: $text2; }

        .bar-item {
            &__ttl {
                color: $text2;
                font-family: $crpm;
                font-size: 11px;
                line-height: 14px; } } }

    @media (max-width: 1023px) {
        #footer-compare-cnt {
            display: none; }
        #footer-favorites-cnt[data-cnt="0"] {
            display: none; }
        #footer-favorites-cnt[data-cnt="0"][data-cmp="0"] {
            display: block;
            .bar-item__counter {
                display: none; } }
        #footer-favorites-cnt[data-cnt="0"] + #footer-compare-cnt:not([data-cnt="0"]) {
            display: block; }
        #header-compare-cnt[data-fav="0"] {
            display: none; } } }

.bar-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.js-active {
        .bar-item__ttl {
            color: $blue; }
        svg {
            fill: $blue; } }

    &__icon {
        width: 25px;
        height: 24px;

        svg {
            width: 100%; } }

    &__counter {
        position: absolute;
        top: -6px;
        left: 50%;
        margin-left: 3px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $orange;
        border: 2px solid #ffffff;
        border-radius: 20px;

        span {
            display: block;
            padding-left: 1px;
            font-family: $crpb;
            font-size: 10px;
            line-height: 10px;
            color: #ffffff; } } }

.__jivoMobileButton {
    @include r(1023px) {
        z-index: auto !important;
        display: none !important; } }
