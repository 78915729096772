@mixin r($width) {
    @media only screen and (max-width: $width) {
        @content; } }
@mixin m($width) {
    @media only screen and (min-width: $width) {
        @content; } }

@mixin font-face($font-name,$font-file) {
    @font-face {
        font-family: '#{$font-name}';
        font-display: swap;
        src: url('../fonts/#{$font-file}.woff2') format('woff2'),
        url('../fonts/#{$font-file}.woff') format('woff') {} } }

@mixin scroll-wrap {
    width: 100%;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: $orange transparent;
    scrollbar-width: thin;
    scrollbar-gutter: auto;
    padding-bottom: 1px; }

@mixin scroll-wrap-gray {
    width: 100%;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: $gray transparent;
    scrollbar-width: thin;
    scrollbar-gutter: auto;
    padding-bottom: 1px; }

// Fluid fonts
// See https://habr.com/post/310186/
// Also check _layout.sass, fix for Safari is required

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1); }
    @return $number; }
@function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
    $f-min: strip-unit($f-min);
    $f-max: strip-unit($f-max);
    $w-min: strip-unit($w-min);
    $w-max: strip-unit($w-max);

    $k: ($f-max - $f-min)/($w-max - $w-min);
    $b: $f-min - $k * $w-min;

    $b: $b + $units;

    @return calc( #{$k} * 100vw + #{$b} ); }

@mixin fluidFontSize($f-min, $f-max, $w-min, $w-max, $fallback: false) {
    font-size: $f-min;
    @media (min-width: $w-min) {
        @if ($fallback) {
            font-size: $fallback; }
        font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px); }
    @media (min-width: $w-max) {
        font-size: $f-max; } }
