.sec-carousel {
    background-color: $bbg;
    padding: 68px 0;
    &::before {
        content: '';
        display: block;
        background-color: #fff;
        border-radius: 60px 60px 0 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0px -16px 30px rgba(159, 173, 181, 0.1); }
    @media (max-width: 1279px) {
        background-color: #fff;
        &::before {
            display: none; } }
    @media (max-width: 767px) {
        padding: 44px 0; }
    &--shadow {
        background-color: #fff;
        &::before {
            box-shadow: 0px -20px 30px rgba(87,108,120, .06);
            height: 60px;
            @media (max-width: 1279px) and (min-width: 1024px) {
                display: block; } } }
    .container {
        position: relative;
        z-index: 2;
        @media (max-width: 1279px) {
            padding: 0; } }
    &__item {
        position: relative;
        padding-bottom: 60px;
        @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
                z-index: #{10 - $i}; } }
        @media (max-width: 767px) {
            padding-bottom: 44px; }
        &:last-child {
            padding-bottom: 0; }
        &::before {
            content: '';
            display: block;
            //width: calc((100vw - 1530px) / 2)
            width: calc((100vw - 100%)/ 2);
            background-color: #fff;
            position: absolute;
            top: 0;
            right: calc(100% + 30px);
            bottom: 0;
            z-index: 3;
            // @media (max-width: 1599px)
            //     width: 30px
            // @media (max-width: 1279px)
 }            //     display: none
        &::after {
            content: '';
            display: block;
            // width: calc((100vw - 1530px) / 2)
            width: calc((100vw - 50%)/ 2);
            background-color: #fff;
            position: absolute;
            top: 0;
            left: calc(100% + 30px);
            bottom: 0;
            z-index: 3;
            // @media (max-width: 1599px)
            //     width: 30px
            // @media (max-width: 1279px)
 } }            //     display: none
    .sec-title {
        @media (max-width: 1279px) {
            padding: 0 16px; } }
    .sec-descr {
        @media (max-width: 1279px) {
            margin-top: 0;
            padding: 0 16px; } } }

.cat-links {
    padding: 10px 0 65px;
    @media (max-width: 767px) {
        display: none; }
    &--main {
        padding-bottom: 40px;
        &.js-open {
            .row {
                max-height: 1200px;
                @include m(1200px) {
                    max-height: 600px; }
                &::after {
                    height: 0; } }
            .see-more {
                svg {
                    transform: rotate(180deg); } } }

        .row {
            position: relative;
            margin-right: -4px;
            margin-left: -4px;
            overflow: hidden;
            max-height: 178px;
            transition: max-height 0.3s ease;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 40px;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.83) 36.65%, rgba(255, 255, 255, 0.97) 70.72%, #FFFFFF 100%); } }

        .col {
            padding-right: 4px;
            padding-left: 4px;
            flex: 0 0 25%;
            max-width: 25%;
            @include m(1200px) {
                flex: 0 0 12.5%;
                max-width: 12.5%;
                &:nth-child(1) {
                    flex: 0 0 13.6%;
                    max-width: 13.6%; }
                &:nth-child(8) {
                    flex: 0 0 12.9%;
                    max-width: 12.9%; }
                &:nth-child(7) {
                    flex: 0 0 11%;
                    max-width: 11%; } } }

        .cat-links__list {
            margin-bottom: 24px;
            .h6 {
                white-space: nowrap;
                overflow: hidden;
                margin-bottom: 12px; }

            a {
                line-height: 1.2;
                margin-bottom: 10px; } }

        .see-more {
            cursor: pointer;
            color: $orange;
            svg {
                fill: currentColor; } } }


    &--border {
        padding-top: 75px;
        .container {
            position: relative;
            &::before {
                content: '';
                display: block;
                height: 1px;
                background-color: $lgray;
                position: absolute;
                top: -75px;
                left: 60px;
                right: 60px;
                @media (max-width: 1279px) {
                    left: 16px;
                    right: 16px; } } } }
    &--pt {
        padding-top: 75px; }
    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .h6 {
            font-size: 16px;
            line-height: 1.5;
            margin-bottom: 16px; }
        a {
            line-height: 1.6;
            color: $text2;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0; }
            &:hover {
                color: $orange; } } } }

.you-seen {
    padding: 40px 0;
    @include m(768px) {
        padding: 62px 0 58px; } }

.contact-form {
    $cf: &;
    &__title {
        margin-bottom: 40px;
        @media (max-width: 599px) {
            margin-bottom: 32px; }
        h2 {
            margin-bottom: 8px; } }
    &__rating {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 30px;
        @media (max-width: 767.8px) {
            margin: 0 0 20px; }
        p {
            line-height: 1;
            margin-bottom: 5px; }
        &-row {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            @media (max-width: 767.8px) {
                display: block;
                .star-rating {
                    margin-left: -4px; } } }
        &-total {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 18px;
            @media (max-width: 767.8px) {
                align-items: flex-start;
                margin-left: 0; }
            p {
                font-family: $crpb;
                font-size: 16px;
                line-height: 1.5; }
            .value-wrap {
                display: flex;
                align-items: center;
                @media (max-width: 767.8px) {
                    margin-left: 15px; }
                .icon {
                    width: 38px;
                    height: 38px;
                    margin-right: 10px; }
                .value {
                    font-family: $crpm;
                    font-size: 24px;
                    line-height: 1; } } } }
    &__inputs {
        display: flex;
        margin-bottom: 32px;
        @media (max-width: 599px) {
            display: block;
            margin-bottom: 24px; }
        &--radio {
            @media (max-width: 767px) {
                display: block; } } }
    &__input {
        position: relative;
        width: calc(50% - 32px);
        margin-right: 64px;
        @media (max-width: 599px) {
            width: 100%;
            margin: 0 0 24px; }
        &:last-child {
            margin: 0; }
        p {
            line-height: 20px;
            span {
                color: $orange; } }
        input {
            background: none;
            border-radius: 0;
            border-bottom: 1px solid $gray;
            padding: 0;
            transition: border .2s ease;
            caret-color: $orange;
            height: 44px;
            &:hover, &:focus {
                border-color: $orange; } }
        .error-text {
            display: none;
            font-size: 13px;
            line-height: 16px;
            color: $red;
            position: absolute;
            top: calc(100% + 6px);
            left: 0; }
        &--password {
            .hide-show-pass {
                position: absolute;
                right: 0;
                bottom: 6px;
                z-index: 2;
                cursor: pointer;
                &:hover {
                    @include svg-image-invisible-hover; } } }
        &.valid {
            p {
                color: $text2; }
            input {
                border-color: $green;
                border-width: 2px;
                padding-right: 30px; }
            &::after {
                content: '';
                display: block;
                width: 21px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21 1.40357L6.57737 16L0 9.34337L1.38686 7.9398L6.57737 13.1929L19.6131 0L21 1.40357Z' fill='%2371B732'/%3E%3C/svg%3E");
                background-size: cover;
                position: absolute;
                right: 0;
                bottom: 14px; } }
        &.invalid {
            p {
                color: $red;
                span {
                    color: $text; } }
            input {
                border-color: $red;
                border-width: 2px; }
            .error-text {
                display: block; } } }
    &__textarea {
        position: relative;
        margin-bottom: 24px;
        p {
            line-height: 20px;
            span {
                color: $orange; } }
        textarea {
            border-radius: 0;
            background: none;
            border-bottom: 1px solid $gray;
            padding: 8px 0;
            transition: border .2s ease;
            &:hover, &:focus {
                border-color: $orange; } }
        .error-text {
            display: none;
            font-size: 13px;
            line-height: 16px;
            color: $red;
            position: absolute;
            top: calc(100% + 6px);
            left: 0; }
        &.valid {
            p {
                color: $text2; }
            textarea {
                border-color: $green;
                padding-right: 30px; }
            &::after {
                content: '';
                display: block;
                width: 21px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21 1.40357L6.57737 16L0 9.34337L1.38686 7.9398L6.57737 13.1929L19.6131 0L21 1.40357Z' fill='%2371B732'/%3E%3C/svg%3E");
                background-size: cover;
                position: absolute;
                right: 0;
                bottom: 14px; } }
        &.invalid {
            p {
                color: $red;
                span {
                    color: $text; } }
            textarea {
                border-color: $red; }
            .error-text {
                display: block; } } }
    &__attach.attach {
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start; } }
    .attach, .multi-attach {
        margin-bottom: 28px; }
    &__recommend {
        margin-bottom: 40px;
        p {
            margin-bottom: 24px; }
        &-row {
            display: flex;
            align-items: center;
            .checkbox {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0; }
                &__custom {
                    border-color: $gray; } } } }
    &__agree {
        position: relative;
        margin-bottom: 28px;
        .error-text {
            display: none;
            font-size: 13px;
            line-height: 16px;
            color: $red;
            position: absolute;
            top: calc(100% + 6px);
            left: 0; }
        .checkbox__custom {
            border-color: $gray; }
        .checkbox__label {
            a {
                color: $orange;
                &:hover {
                    color: $dorange; } } }
        &.invalid {
            .error-text {
                display: block; } } }
    &__checkbox {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0; } }
    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        .icon {
            margin-right: 10px; }
        span {
            font-size: 13px;
            line-height: 20px;
            color: $text2; } }
    &__submit {
        display: flex;
        align-items: center;
        @media (max-width: 599px) {
            display: block; }
        .btn {
            width: 272px;
            height: 52px;
            border-radius: 52px;
            @media (max-width: 479px) {
                width: 100%; } }
        button[disabled] {
            border-color: #ffbf92;
            background-color: #ffbf92; }
        p {
            font-size: 13px;
            color: $text2;
            margin-left: 26px;
            margin-bottom: 0 !important;
            @media (max-width: 599px) {
                margin: 16px 0 0; }
            span {
                color: $orange; } } }
    &__btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 599px) {
            display: block; }
        .btn-outline {
            width: 132px;
            @media (max-width: 479px) {
                width: 100%; } }
        p {
            margin-bottom: 0 !important; }
        .step {
            font-size: 13px;
            line-height: 24px;
            color: rgba($text2, .6);
            @media (max-width: 599px) {
                margin-top: 16px; } } } }
